import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Select, { OnChangeValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { TrashIcon } from '@heroicons/react/outline';
import ReactDOM from 'react-dom';

import { useAuth } from '../../hooks/use-auth';
import CommonAnimatedLoader from '../../components/common/animated/loader';
import CommonAnimatedSpinner from '../../components/common/animated/spinner';
import { createTreatment, treatmentView, updateTreatment } from '../../services/api/treatment';
import TreatmentFormProps from '../../entities/form/treatment-form';
import { strings } from '../../localization/strings';
import { Symptom } from '../../entities/symptom';
import * as symptomsService from '../../services/api/symptoms';
import * as sourcesService from '../../services/api/sources';
import * as drugsService from '../../services/api/drugs';
import { Source } from '../../entities/source';
import DrugProps from '../../entities/drug-prop';
import DrugForm from '../../entities/form/drug-form';
import CommonButtonDefault from '../../components/common/buttons/default';
import { TreatmentStatus } from '../../entities/treatment-status';
import StyledError from '../../components/common/form/styled-error';
import { getPatient } from '../../services/api/patients';
import TreatmentViewProps from '../../entities/treatment-view';
import AlertPrimary from '../../components/common/alerts/alert-primary';

interface ComponentProps {
  isEdit?: boolean;
}

function TreatmentFormPageComponent({ isEdit }: ComponentProps) {
  const { token } = useAuth();
  const { patientId, patientHash } = useParams();
  const navigate = useNavigate();
  const {
    setValue,
    getValues,
    handleSubmit,
    control,
    setError,
    clearErrors,
    register,
    formState: { errors },
  } = useForm<TreatmentFormProps>();

  const treatmentStatusOptions = [
    { value: TreatmentStatus.No, label: 'No treatment' },
    { value: TreatmentStatus.Created, label: 'Created' },
    { value: TreatmentStatus.Paid, label: 'Paid' },
    { value: TreatmentStatus.Shipped, label: 'Shipped' },
    { value: TreatmentStatus.Active, label: 'Active' },
    { value: TreatmentStatus.Completed, label: 'Completed' },
  ];

  const hoursOptions = [
    { value: '06', label: '6' },
    { value: '07', label: '7' },
    { value: '08', label: '8' },
    { value: '09', label: '9' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' },
    { value: '19', label: '19' },
    { value: '20', label: '20' },
    { value: '21', label: '21' },
    { value: '22', label: '22' },
    { value: '23', label: '23' },
  ];
  const minutesOptions = [
    { value: '00', label: '00' },
    { value: '05', label: '05' },
    { value: '10', label: '10' },
    { value: '15', label: '15' },
    { value: '20', label: '20' },
    { value: '25', label: '25' },
    { value: '30', label: '30' },
    { value: '35', label: '35' },
    { value: '40', label: '40' },
    { value: '45', label: '45' },
    { value: '50', label: '50' },
    { value: '55', label: '55' },
  ];

  const remindersOptions = [
    { value: '1', label: 'On' },
    { value: '0', label: 'Off' },
  ];

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorBackendMessage, setErrorBackendMessage] = useState<string>();

  const [symptoms, setSymptoms] = useState<Symptom[]>([]);
  const [sources, setSources] = useState<Source[]>([]);
  const [drugs, setDrugs] = useState<DrugForm[]>([]);
  const [selectedDrugs, setSelectedDrugs] = useState<DrugProps[]>([]);
  const [hours, setHours] = useState<string>('09');
  const [minutes, setMinutes] = useState<string>('00');
  const [reminderValue, setReminderValue] = useState<string>('1');
  // const [patient, setPatient] = useState<PatientProps>();
  const [treatment, setTreatment] = useState<TreatmentViewProps>();

  useEffect(() => {
    const fetchSources = async () => {
      const allSources = await sourcesService.list(token);

      const fetchedSymptoms = await symptomsService.list(token);

      const allDrugs = await drugsService.drugsList(token);

      ReactDOM.unstable_batchedUpdates(() => {
        setSources(allSources);
        setSymptoms(fetchedSymptoms);
        setDrugs(allDrugs);
        setIsLoading(false);
      });
    };

    fetchSources();
  }, [token]);

  const onCancel = async () => {
    navigate(`/patients/preview/${patientHash}`);
  };

  useEffect(() => {
    if (isEdit) {
      const fetchPatientData = async () => {
        try {
          if (patientHash) {
            setIsLoading(true);
            const res = await getPatient(token, patientHash);

            if (res) {
              const fetchTreatment = async () => {
                if (res.patient.treatmentHash) {
                  const resTreatment = await treatmentView(token, res.patient.treatmentHash);

                  ReactDOM.unstable_batchedUpdates(() => {
                    if (resTreatment) {
                      setValue('time', resTreatment.treatment.time);
                      const [hoursDB, minutesDB] = resTreatment.treatment.time.split(':');

                      setHours(hoursDB);
                      setMinutes(minutesDB);

                      setValue('status', resTreatment.treatment.status);
                      setValue('benchmarkScore', Number(resTreatment.treatment.benchmarkScore));
                      setValue('reminders', resTreatment.treatment.reminders);

                      if (resTreatment.treatment.reminders) {
                        setReminderValue('1');
                      } else {
                        setReminderValue('0');
                      }

                      if (resTreatment.drugs.length > 0) {
                        setSelectedDrugs(resTreatment.drugs);
                      } else {
                        setSelectedDrugs([]);
                      }

                      setValue(
                        'sources',
                        resTreatment.sources.map((s) => s.sourceId),
                      );
                      setValue(
                        'symptoms',
                        resTreatment.symptoms.map((s) => s.symptomId),
                      );
                    }

                    setTreatment(resTreatment);
                    setIsLoading(false);
                  });
                }
              };

              fetchTreatment();
            }
          }
        } catch (e) {
          setErrorBackendMessage((e as Error).message);
          setIsLoading(false);
        }
      };

      fetchPatientData();
    } else {
      setValue('time', '09:00:00');
      setValue('sources', []);
      setValue('symptoms', []);
      setValue('status', TreatmentStatus.Created);
      setValue('benchmarkScore', null);
      setValue('reminders', true);
    }
  }, [token, patientHash]);

  const onChangeSymptoms = async (event: OnChangeValue<Symptom, true>) => {
    const ids = event.map((s) => s.id);

    setValue('symptoms', ids);
  };

  const onChangeSources = async (event: OnChangeValue<Source, true>) => {
    if (event) {
      const ids = event.map((s) => s.id);

      setValue('sources', ids);
    }
  };

  const onSelectDrug = async (index: number, event: OnChangeValue<any, false>) => {
    let drug;

    if (event) {
      if (!event.id) {
        drug = await drugsService.createDrug(token, { title: event.label });
      }
      const rowsInput = [...selectedDrugs];

      rowsInput[index].drugId = drug ? drug.id : event.id;
      setSelectedDrugs(rowsInput);
    }
  };

  const onChangeDrugQuantity = async (index: number, event: OnChangeValue<any, false>) => {
    if (event) {
      const rowsInput = [...selectedDrugs];

      rowsInput[index].quantity = event.target.value;
      setSelectedDrugs(rowsInput);
    }
  };

  const onAddDrugsRow = async () => {
    const rowsInput = {
      drugId: 0,
      quantity: '1',
    };

    setSelectedDrugs([...selectedDrugs, rowsInput]);
  };

  const onDeleteDrugsRow = async (index: number) => {
    const rows = [...selectedDrugs];

    rows.splice(index, 1);
    setSelectedDrugs(rows);
  };

  const onTimeChange = (h?: string, min?: string) => {
    if (h) {
      setHours(h);
    }

    if (min) {
      setMinutes(min);
    }

    setValue('time', `${h}:${min}:00`);
  };

  const onReminderChange = (v: string | undefined) => {
    setValue('reminders', v === '1');

    if (v) {
      setReminderValue(v);
    }
  };

  const onFormSubmit = async (data: TreatmentFormProps) => {
    setIsLoading(true);

    const emptyDrugs = selectedDrugs.filter((s) => s.drugId === 0);

    emptyDrugs.forEach(function (item) {
      selectedDrugs.splice(selectedDrugs.indexOf(item), 1);
    });

    if (isEdit) {
      try {
        if (treatment) {
          setIsLoading(true);
          const formData = {
            ...data,
            drugs: selectedDrugs,
            patientId: Number(patientId),
            id: Number(treatment.treatment.id),
          };

          const res = await updateTreatment(token, formData);

          if (res && patientHash) {
            navigate(`/patients/preview/${patientHash}`);
          }
        }
      } catch (e) {
        setIsLoading(false);
        setErrorBackendMessage((e as Error).message);
      }
    } else {
      try {
        setIsLoading(true);

        if (patientId) {
          const formData = {
            ...data,
            drugs: selectedDrugs,
            patientId: Number(patientId),
          };
          const res = await createTreatment(token, formData);

          setIsLoading(false);

          if (res && patientHash) {
            navigate(`/patients/preview/${patientHash}`);
          }
        }
      } catch (e) {
        setIsLoading(false);
        setErrorBackendMessage((e as Error).message);
      }
    }
  };

  return (
    <div>
      {isLoading && <CommonAnimatedLoader />}
      <header className="mb-3 mt-3">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold leading-tight text-gray-900">
            {isEdit ? 'Edit treatment' : 'Add treatment'}
          </h1>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          {errorBackendMessage && (
            <div className="mb-4">
              <AlertPrimary type="failed" text={errorBackendMessage} />
            </div>
          )}
          {errors.common && <StyledError>{errors.common.message}</StyledError>}
          <form
            onSubmit={handleSubmit(onFormSubmit)}
            className="shadow border-b border-gray-200 sm:rounded-lg bg-white"
          >
            <div className="grid grid-cols-1 gap-y-3 gap-x-4 px-4">
              <div className="mt-5">
                <div className="mb-3">
                  <label htmlFor="symptoms" className="block text-sm font-medium text-gray-700">
                    Select symptoms
                  </label>
                  <div className="mt-1">
                    {!symptoms && <CommonAnimatedSpinner sm />}
                    {symptoms && symptoms.length && getValues('symptoms') && (
                      <Controller
                        control={control}
                        name="symptoms"
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            isLoading={symptoms.length < 1}
                            options={symptoms}
                            isClearable
                            isMulti
                            value={[...symptoms.filter((s) => getValues('symptoms').indexOf(s.id) !== -1)]}
                            // menuPlacement={index > items.length / 2 ? 'top' : 'bottom'}
                            placeholder={strings.sources.selectSymptom}
                            getOptionValue={(option: Symptom) => `${option.id}`}
                            getOptionLabel={(option: Symptom) => `${option.title}`}
                            onChange={(event) => onChangeSymptoms(event)}
                          />
                        )}
                      />
                    )}
                    {errors.symptoms && <StyledError>{strings.error.fieldIsRequired}</StyledError>}
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="sources" className="block text-sm font-medium text-gray-700">
                  Select allergens
                </label>
                <div className="mt-1">
                  {sources && sources.length && getValues('sources') && (
                    <Controller
                      control={control}
                      name="sources"
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Select
                          isLoading={sources.length < 1}
                          options={sources}
                          isClearable
                          isMulti
                          // menuPlacement={sourceGroupIndex > (order?.sourceGroups.length || 0) / 2 ? 'top' : 'bottom'}
                          value={[...sources.filter((s) => getValues('sources').indexOf(s.id) !== -1)]}
                          placeholder={strings.orderResultsView.selectSource}
                          getOptionValue={(option: Source) => `${option.id}`}
                          getOptionLabel={(option: Source) => `${option.title}`}
                          onChange={(event) => onChangeSources(event)}
                          // formatOptionLabel={formatSourceOptionLabel}
                        />
                      )}
                    />
                  )}
                  {errors.symptoms && <StyledError>{strings.error.fieldIsRequired}</StyledError>}
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="drugs" className="block text-sm font-medium text-gray-700">
                  Select anti allergen drugs
                </label>
                {selectedDrugs &&
                  selectedDrugs.map((selectedDrug, index) => (
                    <div key={`selectedDrugs-${index + 1}`} className="mt-1 flex space-x-4 mb-4">
                      <div className="w-1/4">
                        <CreatableSelect
                          options={drugs}
                          isClearable
                          placeholder={strings.treatment.selectDrugs}
                          onChange={(event) => onSelectDrug(index, event)}
                          value={drugs.filter(
                            (option) => selectedDrug.drugId !== 0 && option.value === selectedDrug.drugId,
                          )}
                          // isOptionSelected={(option: DrugForm) => selectedDrug.drugId === option.id}
                          className="w-full"
                        />
                      </div>
                      <input
                        name="quantity"
                        type="number"
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
                        onChange={(event) => onChangeDrugQuantity(index, event)}
                        value={selectedDrug.quantity}
                      />
                      <CommonButtonDefault link onClick={() => onDeleteDrugsRow(index)}>
                        <TrashIcon className="h-5 w-5 text-gray-500 hover:text-gray-900" />
                      </CommonButtonDefault>
                    </div>
                  ))}
                {selectedDrugs.length < 1 && (
                  <div className="mt-2 text-sm text-gray-500">You have not selected any drugs.</div>
                )}
                <div className="mt-2">
                  <button
                    type="button"
                    onClick={onAddDrugsRow}
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Add Drug
                  </button>
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="time" className="block text-sm font-medium text-gray-700">
                  Time for immunotherapy drugs
                </label>
                <div className="mt-1 flex w-1/4 space-x-1 items-center">
                  <div>
                    {hours && (
                      <Select
                        className="w-full text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        options={hoursOptions}
                        value={hoursOptions.filter((option) => option.value === hours)}
                        onChange={(e) => {
                          onTimeChange(e?.value, minutes);
                        }}
                      />
                    )}
                  </div>
                  <div className="items-center">:</div>
                  <div>
                    {minutes && (
                      <Select
                        className="w-full text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        options={minutesOptions}
                        // value={selectedMinutesOption}
                        value={minutesOptions.filter((option) => option.value === minutes)}
                        onChange={(e) => {
                          onTimeChange(hours, e?.value);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                  Treatment Status
                </label>
                <div className="mt-1 flex w-1/4 space-x-4">
                  {getValues('status') && (
                    <Controller
                      control={control}
                      name="status"
                      render={({ field }) => (
                        <Select
                          className="w-full text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                          options={treatmentStatusOptions}
                          defaultValue={treatmentStatusOptions.filter(function (option) {
                            return option.value === getValues('status');
                          })}
                          onChange={(e) => {
                            field.onChange(e?.value);
                          }}
                        />
                      )}
                    />
                  )}
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="reminders" className="block text-sm font-medium text-gray-700">
                  Reminders
                </label>
                <div className="mt-1 flex w-1/4 space-x-4">
                  {reminderValue && (
                    <Controller
                      control={control}
                      name="reminders"
                      render={({ field }) => (
                        <Select
                          className="w-full text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                          options={remindersOptions}
                          value={remindersOptions.filter((option) => option.value === reminderValue)}
                          onChange={(e) => {
                            onReminderChange(e?.value);
                          }}
                        />
                      )}
                    />
                  )}
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="benchmarkScore" className="block text-sm font-medium text-gray-700">
                  Benchmark score
                </label>
                <div className="mt-1 flex w-1/4 space-x-4">
                  <input
                    type="number"
                    min="0"
                    {...register('benchmarkScore', { required: true })}
                    defaultValue={getValues('benchmarkScore') || undefined}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                {errors.benchmarkScore && (
                  <StyledError>
                    {errors.benchmarkScore.type === 'required' && strings.error.fieldIsRequired}
                  </StyledError>
                )}
              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 rounded-b-lg flex">
              <div className="pt-5">
                <div className="flex">
                  <button
                    onClick={() => {
                      setErrorBackendMessage('');
                    }}
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Save
                  </button>
                  <button
                    onClick={() => onCancel()}
                    type="button"
                    className="ml-3 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Cancel
                  </button>
                  {isLoading && (
                    <div className="mt-2 pl-3">
                      <CommonAnimatedSpinner sm />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </main>
    </div>
  );
}

TreatmentFormPageComponent.defaultProps = {
  isEdit: false,
};

export default TreatmentFormPageComponent;
