export const parameters = {
  isDevelopment: process.env.NODE_ENV === 'development',
  api: {
    adminUrl: process.env.REACT_APP_ADMIN_URL,
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    // messagesUrl: process.env.REACT_APP_API_MESSAGES_URL,
    // usersUrl: process.env.REACT_APP_API_USERS_URL,
    websocketUrl: process.env.REACT_APP_WEBSOCKET_URL,
  },
};

// export type ApiEndpoints = keyof typeof parameters.api.endpoints;
