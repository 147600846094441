import { useContext } from 'react';

import { OrderContext } from '../context/order';

export const useOrder = () => {
  const context = useContext(OrderContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useOrder was used outside of its Provider');
  }

  return context;
};
