import React from 'react';
import classNames from 'classnames';

import { TreatmentStatus } from '../../entities/treatment-status';

export default function TreatmentStatusBadge({ status }: { status: string | undefined }) {
  return (
    <span
      className={classNames('inline-flex items-center px-2 py-0.5 rounded text-xs font-medium', {
        'bg-gray-100 text-gray-800': status === TreatmentStatus.Created,
        'bg-yellow-100 text-yellow-800': status === TreatmentStatus.Shipped,
        'bg-red-100 text-red-800': status === TreatmentStatus.Paid,
        'bg-green-100 text-green-800': status === TreatmentStatus.Active,
        'bg-blue-100 text-blue-800': status === TreatmentStatus.Completed,
      })}
    >
      {status}
    </span>
  );
}
