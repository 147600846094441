import axios, { AxiosError } from 'axios';

import { parameters } from '../../constants/parameters';
import TreatmentFormProps from '../../entities/form/treatment-form';
import TreatmentViewProps from '../../entities/treatment-view';
import { StatusResponse } from '../../entities/status-response';

export const treatmentView = async (token: string, hash: string): Promise<TreatmentViewProps> => {
  const url = `${parameters.api.baseUrl}/treatments/view/${hash}`;

  const res = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });

  return res.data;
};

export const updateTreatment = async (token: string, data: TreatmentFormProps): Promise<StatusResponse> => {
  const url = `${parameters.api.baseUrl}/treatments/update`;

  try {
    const res = await axios.post(
      url,
      {
        ...data,
      },
      { headers: { Authorization: `Bearer ${token}` } },
    );

    return res.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.data.error) {
        throw new Error(e.response?.data.error);
      }
    }

    throw e;
  }
};

export const createTreatment = async (token: string, data: TreatmentFormProps): Promise<StatusResponse> => {
  const url = `${parameters.api.baseUrl}/treatments/create`;

  try {
    const res = await axios.post(
      url,
      {
        ...data,
      },
      { headers: { Authorization: `Bearer ${token}` } },
    );

    return res.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.data.error) {
        throw new Error(e.response?.data.error);
      }
    }

    throw e;
  }
};
