import ALLERGOMEDICA_LOGO from './images/allergomedica_logo.svg';
import EMPTYLIST from './images/clinic/empty.svg';
import SUCCESS from './images/clinic/icon_chat_delivered.svg';

const IMAGES = {
  LOGOTYPES: {
    PRIMARY: ALLERGOMEDICA_LOGO,
  },
  CLINIC: {
    EMPTYLIST,
    SUCCESS,
  },
};

export default IMAGES;
