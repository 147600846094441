import axios from 'axios';

import { parameters } from '../../constants/parameters';
import { Site } from '../../entities/site';

export const list = async (token: string): Promise<Site[]> => {
  const url = `${parameters.api.baseUrl}/sites/list`;

  const res = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });

  return res.data;
};
