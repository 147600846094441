import { useContext } from 'react';

import { CustomersContext } from '../context/customers';

export const useCustomers = () => {
  const context = useContext(CustomersContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useMessages was used outside of its Provider');
  }

  return context;
};
