import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import * as uuid from 'uuid';

import { useAuth } from '../hooks/use-auth';
import * as messagesService from '../services/api/messages';
import { Message } from '../entities/message';
import { MessageGroup } from '../entities/message-group';
import { MessageDirection } from '../entities/message-direction';
import { useCustomers } from '../hooks/use-customers';

interface ProviderProps {
  children: React.ReactNode;
  customerId: number;
}

interface ContextValue {
  messageGroups: MessageGroup[];
  addMessage: (id: string, text: string) => void;
  updateMessage: (id: string, text: string) => void;
  deleteMessage: (id: string) => void;
}

const MessagesContext = createContext<ContextValue>({
  messageGroups: [],
  addMessage: () => null,
  updateMessage: () => null,
  deleteMessage: () => null,
});

const groupMessages = (messages: Message[]): MessageGroup[] => {
  const groups: MessageGroup[] = [];
  let collectedMessages: Message[] = [];
  let prevMessage: Message | null = null;

  for (const message of messages) {
    if (prevMessage && prevMessage.isCreatedByCustomer !== message.isCreatedByCustomer) {
      groups.push({
        id: uuid.v4(),
        direction: prevMessage.isCreatedByCustomer ? MessageDirection.Outgoing : MessageDirection.Incoming,
        messages: collectedMessages,
      });
      collectedMessages = [];
    }

    collectedMessages.push(message);
    prevMessage = message;
  }

  if (collectedMessages.length) {
    groups.push({
      id: uuid.v4(),
      direction: collectedMessages[0].isCreatedByCustomer ? MessageDirection.Outgoing : MessageDirection.Incoming,
      messages: collectedMessages,
    });
  }

  return groups;
};

function MessagesContextProvider({ children, customerId }: ProviderProps) {
  const [messageGroups, setMessageGroups] = useState<MessageGroup[]>([]);

  const { token } = useAuth();
  const { lastMessageReceived, setRead, updateLastMessage } = useCustomers();

  const getExistingMessage = useCallback(
    (messageId: string): Message | null => {
      const messageGroupsLength = messageGroups.length;

      for (let i = messageGroupsLength - 1; i >= 0; i -= 1) {
        const message = messageGroups[i].messages.find((m) => m.messageId === messageId);

        if (message) {
          return message;
        }
      }

      return null;
    },
    [messageGroups],
  );

  const appendMessage = useCallback(
    (message: Message): void => {
      const existing = getExistingMessage(message.messageId);

      if (!existing) {
        const lastMessageGroup = messageGroups.length ? messageGroups[messageGroups.length - 1] : null;
        const direction = message.isCreatedByCustomer ? MessageDirection.Outgoing : MessageDirection.Incoming;

        if (lastMessageGroup && lastMessageGroup.direction === direction) {
          // i esama grupe idedam zinute
          messageGroups[messageGroups.length - 1].messages.push(message);
        } else {
          // sukuriam nauja grupe
          messageGroups.push({
            id: uuid.v4(),
            direction,
            messages: [message],
          });
        }

        setMessageGroups([...messageGroups]);
      }
    },
    [messageGroups, setMessageGroups, getExistingMessage],
  );

  const addMessage = useCallback(
    (id: string, text: string): void => {
      const message = {
        isCreatedByCustomer: false,
        messageId: id,
        message: text,
        customerId: -1,
        administratorId: -1,
        createdAt: new Date().getTime(),
      };

      appendMessage(message);
    },
    [appendMessage],
  );

  const updateMessage = useCallback(
    (id: string, text: string): void => {
      for (const messageGroup of messageGroups) {
        for (const message of messageGroup.messages) {
          if (message.messageId === id) {
            message.message = text;
          }
        }
      }

      setMessageGroups([...messageGroups]);
    },
    [setMessageGroups, messageGroups],
  );

  const deleteMessage = useCallback(
    (id: string): void => {
      for (const messageGroup of messageGroups) {
        for (const message of messageGroup.messages) {
          if (message.messageId === id) {
            const index = messageGroup.messages.indexOf(message);

            messageGroup.messages.splice(index, 1);

            if (messageGroup.messages.length === 0) {
              const mgIndex = messageGroups.indexOf(messageGroup);

              messageGroups.splice(mgIndex, 1);
            }
          }
        }
      }

      setMessageGroups([...messageGroups]);
    },
    [setMessageGroups, messageGroups],
  );

  useEffect(() => {
    const loadData = async (id: number) => {
      const latestMessages = await messagesService.getByCustomer(token, id);

      if (latestMessages.length) {
        setRead(id, latestMessages[latestMessages.length - 1].message);
      }

      setMessageGroups(groupMessages(latestMessages));
    };

    if (customerId) {
      loadData(customerId);
    }
  }, [setMessageGroups, token, customerId]);

  useEffect(() => {
    const loadMessage = async () => {
      if (lastMessageReceived?.customerId === customerId) {
        const message = await messagesService.getMessage(token, lastMessageReceived.messageId);

        appendMessage(message);
        updateLastMessage(message.customerId, message.messageId, message.message, false);
      }
    };

    loadMessage();
  }, [lastMessageReceived]);

  const contextValue = useMemo(
    () => ({
      messageGroups,
      addMessage,
      updateMessage,
      deleteMessage,
    }),
    [messageGroups, addMessage, updateMessage],
  );

  return <MessagesContext.Provider value={contextValue}>{children}</MessagesContext.Provider>;
}

MessagesContextProvider.defaultProps = {};

export { MessagesContext, MessagesContextProvider };
