import React from 'react';
import { Link } from 'react-router-dom';

import { Recommendation } from '../../entities/recommendation';
import RecommendationsCreateModal from './create-modal';

interface ComponentProps {
  items: Recommendation[];
  onUpdateRecommendation: (prevValue: Recommendation, newValue: Recommendation) => void;
}

export default function RecommendationsTableComponent({ items, onUpdateRecommendation }: ComponentProps) {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    ID
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Title
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {items.map((item, index) => (
                  <tr key={item.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{item.id}</td>
                    <td className="px-6 py-4 text-sm text-gray-900">
                      {item.titleAdmin}
                      <div className="text-gray-500">{item.title}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <div className="flex justify-end">
                        <Link to={`/recommendations/edit/${item.id}`} className="text-indigo-600 hover:text-indigo-900">
                          Edit
                        </Link>
                        {/* <RecommendationsCreateModal */}
                        {/*  isEdit */}
                        {/*  defaultValue={item} */}
                        {/*  onCreate={(recommendation) => onUpdateRecommendation(item, recommendation)} */}
                        {/* /> */}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
