import React, { ChangeEvent, useEffect, useState } from 'react';
import classNames from 'classnames';
import Select, { OnChangeValue } from 'react-select';
import { CheckIcon, PencilIcon, TrashIcon, XIcon } from '@heroicons/react/outline';
import { useForm } from 'react-hook-form';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { XCircleIcon } from '@heroicons/react/solid';

import { useAuth } from '../../hooks/use-auth';
import { OrderViewSource, OrderViewSourceGroup } from '../../entities/order-view';
import CommonAnimatedLoader from '../../components/common/animated/loader';
import * as symptomsService from '../../services/api/symptoms';
import * as sourcesService from '../../services/api/sources';
import * as orderSourceGroupService from '../../services/api/orders-source-groups';
import * as orderSourceGroupSourcesService from '../../services/api/orders-source-groups-sources';
import * as ordersService from '../../services/api/orders';
import { strings } from '../../localization/strings';
import { useOrder } from '../../hooks/use-order';
import CommonButtonDefault from '../../components/common/buttons/default';
import { SourceGroup } from '../../entities/source-group';
import { Source } from '../../entities/source';
import { Symptom } from '../../entities/symptom';
import { SortableItem } from '../../components/common/utilities/sortable-item';
import { updatePositions } from '../../services/api/orders-source-groups';
import Badge from '../../components/common/badge';

function OrdersResultsAnswerPageComponent() {
  const { token } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [symptoms, setSymptoms] = useState<Symptom[]>([]);
  const [sourceGroups, setSourceGroups] = useState<SourceGroup[]>([]);
  const [sources, setSources] = useState<Source[]>([]);
  const [selectedSourceGroup, setSelectedSourceGroup] = useState<SourceGroup>();
  const [selectedSource, setSelectedSource] = useState<Source>();
  const {
    order,
    updateSourceGroups,
    updateSourceGroup,
    updateSource,
    removeSourceGroup,
    removeSource,
    addSourceGroup,
    addSource,
  } = useOrder();
  const [editableSourceGroups, setEditableSourcesGroups] = useState<number[]>([]);
  const { register, handleSubmit } = useForm<{ customTitle: string }>();

  useEffect(() => {
    const fetchSymptoms = async () => {
      const items = await symptomsService.list(token);

      setSymptoms(items.filter((s) => order?.symptomIds.indexOf(s.id) !== -1));
    };

    fetchSymptoms();
  }, [token, order]);

  useEffect(() => {
    const fetchSourceGroups = async () => {
      const items = await sourcesService.groups(token);

      setSourceGroups(items);
    };

    fetchSourceGroups();
  }, [token]);

  useEffect(() => {
    const fetchSources = async () => {
      if (order?.order.hash) {
        const allSources = await sourcesService.list(token);

        const items = await ordersService.getAllergens(token, order?.order.hash);
        const activeSources: number[] = [];

        for (const sourceGroup of items.groups) {
          activeSources.push(
            ...sourceGroup.sources
              .filter((source) => {
                return source.allergens.filter((a) => a.value > 0).length > 0;
              })
              .map((s) => s.id),
          );
        }

        setSources(allSources.filter((s) => activeSources.indexOf(s.id) !== -1));
      }
    };

    fetchSources();
  }, [token, order]);

  const onSelectSymptom = async (sourceGroup: OrderViewSourceGroup, selectedSymptoms: OnChangeValue<Symptom, true>) => {
    const ids = selectedSymptoms.map((s) => s.id);

    updateSourceGroup(sourceGroup, { symptomIds: ids });
    await orderSourceGroupService.update(token, sourceGroup.id, { symptomIds: ids });
  };

  const onChangeImportant = async (sourceGroup: OrderViewSourceGroup, event: ChangeEvent<HTMLInputElement>) => {
    updateSourceGroup(sourceGroup, { isImportant: event.target.checked });
    await orderSourceGroupService.update(token, sourceGroup.id, { isImportant: event.target.checked });
  };

  const onChangePrimary = async (
    sourceGroup: OrderViewSourceGroup,
    source: OrderViewSource,
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    updateSource(sourceGroup, source, { isPrimary: event.target.checked });
    await orderSourceGroupSourcesService.update(token, source.id, { isPrimary: event.target.checked });
  };

  const onRemoveSourceGroup = async (sourceGroup: OrderViewSourceGroup) => {
    removeSourceGroup(sourceGroup);
    await orderSourceGroupService.remove(token, sourceGroup.id);
  };

  const onRemoveSource = async (sourceGroup: OrderViewSourceGroup, source: OrderViewSource) => {
    removeSource(sourceGroup, source);
    await orderSourceGroupSourcesService.remove(token, source.id);
  };

  const onToggleEditing = (sourceGroup: OrderViewSourceGroup) => {
    const index = editableSourceGroups.indexOf(sourceGroup.id);

    if (index === -1) {
      editableSourceGroups.push(sourceGroup.id);
    } else {
      editableSourceGroups.splice(index, 1);
    }

    setEditableSourcesGroups([...editableSourceGroups]);
  };

  const isEditable = (sourceGroup: OrderViewSourceGroup) => {
    return editableSourceGroups.indexOf(sourceGroup.id) !== -1;
  };

  const onSelectSource = async (event: OnChangeValue<Source, false>) => {
    if (event) {
      setSelectedSource(event);
    }
  };

  const onSelectSourceGroup = async (event: OnChangeValue<SourceGroup, false>) => {
    if (event) {
      setSelectedSourceGroup(event);
    }
  };

  const onAddSource = async (sourceGroup: OrderViewSourceGroup) => {
    if (selectedSource && order) {
      setIsLoading(true);
      const orderSource = await orderSourceGroupSourcesService.create(token, sourceGroup.id, selectedSource.id);

      addSource(sourceGroup, orderSource);
      setIsLoading(false);
    }
  };

  const onAddSourceGroup = async () => {
    if (selectedSourceGroup && order) {
      setIsLoading(true);
      const orderSourceGroup = await orderSourceGroupService.create(token, order.order.id, selectedSourceGroup.id);

      addSourceGroup(orderSourceGroup);
      setIsLoading(false);
    }
  };

  const onSubmit = async (data: { customTitle: string }, sourceGroup: OrderViewSourceGroup) => {
    let title: string | undefined = data.customTitle;

    if (title === sourceGroup.sourceGroupTitle) {
      title = undefined;
    }

    updateSourceGroup(sourceGroup, { customTitle: title });
    onToggleEditing(sourceGroup);

    await orderSourceGroupService.update(token, sourceGroup.id, { customTitle: title });
  };

  const formatSourceOptionLabel = (data: Source) => (
    <div>
      <div>{data.title}</div>
      <div className="text-xs text-gray-400">{data.scientificTitle}</div>
    </div>
  );

  const findSourceGroupByStringId = (list: OrderViewSourceGroup[], id: string) => {
    const item = list.find((r) => r.id === parseInt(id, 10));

    if (!item) {
      return -1;
    }

    return list.indexOf(item);
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      if (order?.sourceGroups) {
        const oldIndex = findSourceGroupByStringId(order?.sourceGroups, active.id);
        const newIndex = findSourceGroupByStringId(order?.sourceGroups, over.id);

        const sortedItems = arrayMove(order?.sourceGroups, oldIndex, newIndex);

        updateSourceGroups(sortedItems);

        await updatePositions(
          token,
          order.order.id,
          sortedItems.map((s) => s.id),
        );
      }
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  if (order?.order.productKey === 'CONSULTATION') {
    return (
      <div>
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">Results are unavailable for consultation orders</h3>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      {(isLoading || !order) && <CommonAnimatedLoader />}
      {order && (
        <div>
          <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext
              items={order.sourceGroups.map((sg) => {
                return {
                  ...sg,
                  id: `${sg.id}`,
                };
              })}
              strategy={verticalListSortingStrategy}
            >
              {order.sourceGroups.map((sourceGroup, sourceGroupIndex) => (
                <SortableItem
                  id={`${sourceGroup.id}`}
                  key={`orderSourceGroups-${sourceGroup.id}-${sourceGroupIndex + 1}`}
                >
                  <div className="md:flex md:space-x-3 mb-5 pb-5 border-b border-gray-200 full-width">
                    <div className="w-full md:w-2/5 xl:w-1/5">
                      {isEditable(sourceGroup) && (
                        <form
                          onSubmit={handleSubmit((data) => onSubmit(data, sourceGroup))}
                          className=" leading-6 mb-5 flex items-center"
                        >
                          <div className="grow mr-2">
                            <input
                              type="text"
                              {...register(`customTitle`)}
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              defaultValue={sourceGroup.customTitle || sourceGroup.sourceGroupTitle}
                            />
                          </div>
                          <div>
                            <CommonButtonDefault link type="submit">
                              <CheckIcon className="h-5 w-5 mr-2 text-gray-500 hover:text-gray-900" />
                            </CommonButtonDefault>
                            <CommonButtonDefault link onClick={() => onToggleEditing(sourceGroup)}>
                              <XIcon className="h-5 w-5 text-gray-500 hover:text-gray-900" />
                            </CommonButtonDefault>
                          </div>
                        </form>
                      )}
                      {!isEditable(sourceGroup) && (
                        <h3 className=" leading-6 mb-5 flex items-center">
                          <div className="text-lg font-medium grow text-gray-900">
                            <div>{sourceGroup.customTitle || sourceGroup.sourceGroupTitle}</div>
                          </div>
                          <div className="">
                            <CommonButtonDefault link onClick={() => onToggleEditing(sourceGroup)}>
                              <PencilIcon className="h-5 w-5 text-gray-500 hover:text-gray-900" />
                            </CommonButtonDefault>
                          </div>
                        </h3>
                      )}
                      <div className="mt-5 mb-5">
                        <span className="mr-1">Is clinically significant:</span>{' '}
                        {sourceGroup.decision === -1 && <Badge status="danger" text="No" />}
                        {sourceGroup.decision === 1 && <Badge status="success" text="Yes" />}
                        {sourceGroup.decision === 0 && <Badge status="warning" text="Maybe" />}
                      </div>

                      <div className="mt-5 mb-5">
                        <div className="flex items-center">
                          <input
                            id={`isImportant${sourceGroup.id}`}
                            aria-describedby="comments-description"
                            name="comments"
                            type="checkbox"
                            defaultChecked={sourceGroup.isImportant}
                            onChange={(event) => onChangeImportant(sourceGroup, event)}
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                          />
                          <label htmlFor={`isImportant${sourceGroup.id}`} className="ml-3">
                            <span className="text-sm font-medium text-gray-900">Clinically important </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="w-full md:w-3/5 xl:w-4/5">
                      <div className="">
                        <div className=" align-middle  min-w-full">
                          {sourceGroup.isImportant && (
                            <div className="mb-2">
                              <Select
                                options={symptoms}
                                isClearable
                                isMulti
                                isLoading={!symptoms.length}
                                placeholder={strings.orderResultsView.selectSymptoms}
                                value={symptoms.filter((s) => sourceGroup.symptomIds.indexOf(s.id) !== -1)}
                                getOptionValue={(option: Symptom) => `${option.id}`}
                                getOptionLabel={(option: Symptom) => `${option.title}`}
                                onChange={(event) => onSelectSymptom(sourceGroup, event)}
                              />
                            </div>
                          )}
                          <div className="shadow border-b border-gray-200 sm:rounded-lg">
                            {!!sourceGroup.sources.length && (
                              <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                  <tr>
                                    <th
                                      scope="col"
                                      style={{ width: '400px' }}
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Allergen
                                    </th>
                                    <th
                                      scope="col"
                                      style={{ width: '100px' }}
                                      className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Primary
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-xs text-left font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Components
                                    </th>
                                    <th
                                      scope="col"
                                      style={{ width: '100px' }}
                                      className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      &nbsp;
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {sourceGroup.sources.map((source, sourceIndex) => (
                                    <tr key={source.id} className={sourceIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                        {source.sourceTitle}
                                        <div className="text-gray-400">{source.sourceScientificTitle}</div>
                                      </td>
                                      <td className="px-6 py-4 w-3 whitespace-nowrap text-sm text-center text-gray-500">
                                        <input
                                          id="comments"
                                          aria-describedby="comments-description"
                                          name="comments"
                                          type="checkbox"
                                          defaultChecked={source.isPrimary}
                                          onChange={(event) => onChangePrimary(sourceGroup, source, event)}
                                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                        />
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        <table className="w-full">
                                          <tbody>
                                            {source.allergens.map((allergen) => (
                                              <tr
                                                key={`${sourceGroup.id}${source.id}${allergen.id}-${allergen.analyzerId}`}
                                              >
                                                <td className="text-right" style={{ width: '100px' }}>
                                                  <span
                                                    className={classNames(
                                                      'inline-flex items-center px-2.5 mr-3 py-0.5 rounded-full font-medium text-xs',
                                                      {
                                                        'bg-red-100 text-red-800': allergen.strength >= 3,
                                                        'bg-amber-100 text-amber-800': allergen.strength === 2,
                                                        'bg-green-100 text-green-800': allergen.strength === 1,
                                                        'bg-gray-100 text-gray-800': allergen.strength < 1,
                                                      },
                                                    )}
                                                  >
                                                    {allergen.componentCode}
                                                  </span>
                                                </td>
                                                <td className="w-10">{allergen.valueText}</td>
                                                <td className="pl-3">{allergen.biochemicalDesignation}</td>
                                                <td className="pl-3 text-right">{allergen.analyzerTitle}</td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <CommonButtonDefault link onClick={() => onRemoveSource(sourceGroup, source)}>
                                          <TrashIcon className="h-5 w-5 text-gray-500 hover:text-gray-900" />
                                        </CommonButtonDefault>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            )}

                            <div className="p-4 flex">
                              <div className="grow flex">
                                <div className="w-96">
                                  <Select
                                    options={sources}
                                    menuPlacement={
                                      sourceGroupIndex > (order?.sourceGroups.length || 0) / 2 ? 'top' : 'bottom'
                                    }
                                    isLoading={!sources.length}
                                    placeholder={strings.orderResultsView.selectSource}
                                    getOptionValue={(option: Source) => `${option.id}`}
                                    getOptionLabel={(option: Source) => `${option.title}`}
                                    onChange={onSelectSource}
                                    formatOptionLabel={formatSourceOptionLabel}
                                  />
                                </div>
                                <div className="ml-4">
                                  <CommonButtonDefault onClick={() => onAddSource(sourceGroup)}>
                                    Add source
                                  </CommonButtonDefault>
                                </div>
                              </div>
                              <div>
                                {!sourceGroup.sources.length && (
                                  <CommonButtonDefault onClick={() => onRemoveSourceGroup(sourceGroup)}>
                                    Remove source group
                                  </CommonButtonDefault>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SortableItem>
              ))}
            </SortableContext>
          </DndContext>

          <div className="mb-4 flex">
            <div className="w-96">
              <Select
                options={sourceGroups}
                menuPlacement="top"
                isLoading={!sourceGroups.length}
                placeholder={strings.orderResultsView.selectSourceGroup}
                getOptionValue={(option: SourceGroup) => `${option.id}`}
                getOptionLabel={(option: SourceGroup) => `${option.title}`}
                onChange={onSelectSourceGroup}
              />
            </div>
            <div className="ml-4">
              <CommonButtonDefault disabled={!selectedSourceGroup} onClick={onAddSourceGroup}>
                Add source group
              </CommonButtonDefault>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default OrdersResultsAnswerPageComponent;
