import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';

import IMAGES from '../../assets/images';
import { strings } from '../../localization/strings';
import { useAuth } from '../../hooks/use-auth';
import { current } from '../../services/api/users';
import { parameters } from '../../constants/parameters';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function LayoutHeadingComponent() {
  const { user, signout } = useAuth();
  const navigate = useNavigate();

  const onSignoutClick = () => {
    signout();
    navigate('/');
  };

  const items = [
    { title: strings.navigation.orders, href: '/orders' },
    { title: strings.navigation.patients, href: '/patients' },
    { title: strings.navigation.messages, href: '/messages' },
    { title: strings.navigation.sources, href: '/sources' },
    { title: strings.navigation.sourceGroups, href: '/source-groups' },
    { title: strings.navigation.recommendations, href: '/recommendations' },
    { title: strings.navigation.coupons, href: '/coupons' },
    { title: strings.navigation.feedback, href: '/feedback' },
  ];

  const itemsClinic = [{ title: strings.navigation.patients, href: '/clinic-patients' }];

  return (
    <Disclosure as="nav" className="shadow z-50 relative bg-white">
      {({ open }) => (
        <>
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex justify-between w-full">
                <div className="flex-shrink-0 flex items-center">
                  <a className="py-2" href={parameters.api.adminUrl}>
                    <span className="sr-only">AllergoMedica</span>
                    <img className="h-8 w-auto sm:h-8" src={IMAGES.LOGOTYPES.PRIMARY} alt="AllergoMedica" />
                  </a>
                </div>

                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                  {user?.isClinicUser
                    ? itemsClinic.map((item) => (
                        <NavLink
                          key={item.href}
                          to={item.href}
                          className="nav-link border-transparent no-underline text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                        >
                          {item.title}
                        </NavLink>
                      ))
                    : items.map((item) => (
                        <NavLink
                          key={item.href}
                          to={item.href}
                          className="nav-link border-transparent no-underline text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                        >
                          {item.title}
                        </NavLink>
                      ))}
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:items-center">
                  {/* Profile dropdown */}
                  <Menu as="div" className="ml-3 relative">
                    <div>
                      <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <span className="sr-only">Open user menu</span>
                        <span className="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-500">
                          <span className="text-xs font-medium leading-none text-white">
                            {user?.firstName && user?.firstName.substring(0, 1).toUpperCase()}
                          </span>
                        </span>
                      </Menu.Button>
                    </div>
                    <Transition
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {/* <Menu.Item> */}
                        {/*  {({ active }) => ( */}
                        {/*    <a */}
                        {/*      href="#" */}
                        {/*      className={classNames( */}
                        {/*        active ? 'bg-gray-100' : '', */}
                        {/*        'block px-4 py-2 text-sm text-gray-700', */}
                        {/*      )} */}
                        {/*    > */}
                        {/*      Your Profile */}
                        {/*    </a> */}
                        {/*  )} */}
                        {/* </Menu.Item> */}
                        <Menu.Item onClick={onSignoutClick}>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700 no-underline',
                              )}
                            >
                              {strings.navigation.signOut}
                            </a>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
                <div className="-mr-2 flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="pt-2 pb-3 space-y-1">
              {/*
              Current: "bg-indigo-50 border-indigo-500 text-indigo-700",
              Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700"
              */}
              {items.map((item) => (
                <Disclosure.Button
                  as="a"
                  key={item.href}
                  href={item.href}
                  className="block pl-3 pr-4 py-2 border-l-4 text-base no-underline font-medium border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700"
                >
                  {item.title}
                </Disclosure.Button>
              ))}
            </div>
            <div className="pt-4 pb-3 border-t border-gray-200">
              <div className="flex items-center px-4">
                <div className="flex-shrink-0">
                  <span className="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-500">
                    <span className="text-xs font-medium leading-none text-white">
                      {user?.firstName && user?.firstName.substring(0, 1).toUpperCase()}
                    </span>
                  </span>
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-gray-800">{user?.firstName}</div>
                  <div className="text-sm font-medium text-gray-500">{user?.email}</div>
                </div>
                {/* <button */}
                {/*   type="button" */}
                {/*   className="ml-auto flex-shrink-0 bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" */}
                {/* > */}
                {/*   <span className="sr-only">View notifications</span> */}
                {/*   <BellIcon className="h-6 w-6" aria-hidden="true" /> */}
                {/* </button> */}
              </div>
              <div className="mt-3 space-y-1">
                {/* <Disclosure.Button */}
                {/*  as="a" */}
                {/*  href="#" */}
                {/*  className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100" */}
                {/* > */}
                {/*  {strings.navigation.yourProfile} */}
                {/* </Disclosure.Button> */}
                <Disclosure.Button
                  as="a"
                  href="#"
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                >
                  {strings.navigation.signOut}
                </Disclosure.Button>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
