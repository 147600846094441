import React, { useEffect, useState } from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';
import classNames from 'classnames';

import CommonAnimatedLoader from '../../components/common/animated/loader';
import { getDurationFromDateString } from '../../services/formatter/date';
import { useCustomers } from '../../hooks/use-customers';
import ScreenHeightContainer from '../../components/common/container/screen-height';
import { Order } from '../../entities/order';
import * as customersService from '../../services/api/customers';
import { useAuth } from '../../hooks/use-auth';
import CommonButtonDefault from '../../components/common/buttons/default';
import { resolveMessage } from '../../services/api/customers';

function MessagesIndexPageComponent() {
  const { token } = useAuth();
  const { id } = useParams();
  const { customers, isLoading } = useCustomers();
  const currentCustomerId = parseInt(id || '', 10) || -1;

  const [orders, setOrders] = useState<Order[]>([]);

  const { resolve } = useCustomers();

  useEffect(() => {
    const loadData = async () => {
      const result = await customersService.orders(token, currentCustomerId);

      setOrders(result.orders);
    };

    if (currentCustomerId) {
      loadData();
    }
  }, [currentCustomerId]);

  const customerCurr = customers.find((c) => c.id === currentCustomerId);

  const resolveUserMessage = async () => {
    let status: number;

    if (customerCurr?.resolved) {
      status = 0;
      resolve(currentCustomerId, false);
    } else {
      status = 1;
      resolve(currentCustomerId, true);
    }
    await resolveMessage(token, currentCustomerId, status);
  };

  return (
    <div className="flex w-full">
      <div className="w-96 border-r border-gray-200">
        {isLoading && <CommonAnimatedLoader />}
        {!isLoading && (
          <ScreenHeightContainer>
            <div className="overflow-scroll h-full">
              {customers.map((customer) => (
                <Link
                  to={`/messages/${customer.id}`}
                  key={customer.id}
                  className={classNames(
                    'p-4 border-b border-slate-200 text-md block no-underline column relative',
                    currentCustomerId === customer.id ? 'bg-blue-500 text-white' : 'bg-slate-100',
                  )}
                >
                  {customer.hasUnreadMessage && (
                    <div className="rounded-full w-2 h-2 absolute bg-blue-500 top-9 left-1" />
                  )}
                  <div className="flex w-full items-center space-x-2">
                    {!customer.resolved && (
                      <div
                        className={classNames(
                          'rounded-full h-2.5 w-2.5',
                          currentCustomerId === customer.id ? 'bg-white' : 'bg-blue-500',
                        )}
                      />
                    )}
                    <div
                      className={classNames(
                        'no-underline',
                        currentCustomerId === customer.id ? 'text-white' : 'text-black ',
                        !customer.resolved ? 'font-bold' : 'font-light',
                      )}
                    >
                      {customer.firstName || customer.lastName
                        ? `${customer.firstName} ${customer.lastName}`
                        : customer.email}
                    </div>
                    <div
                      className={classNames(
                        'text-right text-sm flex-1',
                        currentCustomerId === customer.id ? 'text-blue-300' : 'text-slate-500 ',
                      )}
                    >
                      {getDurationFromDateString(customer.lastMessageSentAt)}
                    </div>
                  </div>
                  <div
                    className={classNames(
                      'text-sm',
                      currentCustomerId === customer.id ? 'text-blue-300' : 'text-slate-500 ',
                    )}
                  >
                    {customer.lastMessage && (
                      <span>
                        {customer.lastMessage.message.substring(0, 50)}
                        {customer.lastMessage.message.length > 50 ? '...' : ''}
                      </span>
                    )}
                  </div>
                </Link>
              ))}
            </div>
          </ScreenHeightContainer>
        )}
      </div>
      <div className="w-auto flex flex-1">
        <div className="w-44 px-4 border-r border-gray-200">
          <div className="pt-5 mb-4">
            <CommonButtonDefault className="w-full" onClick={() => resolveUserMessage()}>
              {customerCurr?.resolved ? 'Unresolve' : 'Resolve'}
            </CommonButtonDefault>
          </div>

          <div className="pb-1 font-bold">Orders list:</div>
          <div className="">
            {orders.map((order, index) => (
              <div key={order.id}>#{order.id}</div>
            ))}
          </div>
        </div>
        <Outlet />
      </div>
    </div>
  );
}

export default MessagesIndexPageComponent;
