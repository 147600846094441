import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';

import { useAuth } from '../hooks/use-auth';
import { Customer } from '../entities/customer';
import * as customersService from '../services/api/customers';
import { parameters } from '../constants/parameters';
import { MessageId } from '../entities/message-id';

interface ProviderProps {
  children: React.ReactNode;
}

interface ContextValue {
  customers: Customer[];
  isLoading: boolean;
  lastMessageReceived: MessageId | null;
  setRead: (customerId: number, text: string) => void;
  resolve: (customerId: number, resolved: boolean) => void;
  updateLastMessage: (customerId: number, messageId: string, text: string, isUnread: boolean) => void;
}

const CustomersContext = createContext<ContextValue>({
  customers: [],
  isLoading: true,
  lastMessageReceived: null,
  updateLastMessage: () => null,
  setRead: () => null,
  resolve: () => null,
});

let websocketConnection: WebSocket | null;

function CustomersContextProvider({ children }: ProviderProps) {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [lastMessage, setLastMessage] = useState<MessageId | null>(null);

  const { token } = useAuth();

  const setRead = useCallback(
    (customerId: number, text: string): void => {
      const customer = customers.find((c) => c.id === customerId);

      if (customer) {
        customer.hasUnreadMessage = false;
        if (customer.lastMessage) {
          customer.lastMessage.message = text;
        }

        setCustomers([...customers]);
      }
    },
    [customers, setCustomers],
  );

  const resolve = useCallback(
    (customerId: number, resolved: boolean): void => {
      const customer = customers.find((c) => c.id === customerId);

      if (customer) {
        customer.resolved = resolved;

        setCustomers([...customers]);
      }
    },
    [customers, setCustomers],
  );

  const updateLastMessage = useCallback(
    (customerId: number, messageId: string, text: string, isUnread: boolean): void => {
      const customer = customers.find((c) => c.id === customerId);

      if (customer) {
        customer.lastMessageSentAt = new Date().toISOString();
        customer.hasUnreadMessage = isUnread;
        customer.lastMessage = {
          isCreatedByCustomer: false,
          messageId,
          message: text,
          customerId: customer.id,
          administratorId: -1,
          createdAt: new Date().getTime(),
        };

        setCustomers([
          ...customers.sort((a, b) => {
            if (!a.lastMessageSentAt) {
              return 1;
            }

            return a.lastMessageSentAt > b.lastMessageSentAt ? -1 : 1;
          }),
        ]);
      }
    },
    [customers, setCustomers],
  );
  const initializeSockets = () => {
    if (!websocketConnection) {
      websocketConnection = new WebSocket(`${parameters.api.websocketUrl}?token=${token}`);
    }

    // websocketConnection.onopen = (event) => {
    //   // console.log('connect:', event);
    //   // this._instance.send(JSON.stringify({ action: 'test'}));
    // };
    //
    websocketConnection.onerror = () => {
      // eslint-disable-next-line
      // console.log('error WS', event);
      websocketConnection = null;

      setTimeout(() => {
        initializeSockets();
      }, 5000);
    };

    websocketConnection.onclose = () => {
      // eslint-disable-next-line
      // console.log('closed WS', event);
      websocketConnection = null;

      setTimeout(() => {
        initializeSockets();
      }, 5000);
    };

    websocketConnection.onmessage = async (event) => {
      // console.log('message:', event);
      const messageDetails = JSON.parse(event.data);

      updateLastMessage(messageDetails.customerId, messageDetails.messageId, 'New message received', true);

      setLastMessage(messageDetails);
    };
  };

  initializeSockets();

  useEffect(() => {
    const loadData = async () => {
      const items = await customersService.list(token);

      setCustomers(items);
      setIsLoading(false);
    };

    loadData();
  }, [setCustomers, token]);

  const contextValue = useMemo(
    () => ({
      customers,
      isLoading,
      lastMessageReceived: lastMessage,
      updateLastMessage,
      setRead,
      resolve,
    }),
    [customers, lastMessage, setRead, isLoading, updateLastMessage],
  );

  return <CustomersContext.Provider value={contextValue}>{children}</CustomersContext.Provider>;
}

CustomersContextProvider.defaultProps = {};

export { CustomersContext, CustomersContextProvider };
