import React, { useState } from 'react';
import Select from 'react-select';
import tw, { styled } from 'twin.macro';

import { strings } from '../../localization/strings';
import CommonButtonDefault from '../common/buttons/default';
import PatientsFilterProps from '../../entities/patientsFilter';
import { TreatmentStatus } from '../../entities/treatment-status';

interface ComponentProps {
  filterState: PatientsFilterProps;
  onUpdate: (filter: PatientsFilterProps) => void;
  isClinic?: boolean;
}

const StyledBlock = styled.div`
  ${tw`items-center flex`};
`;
const StyledLabel = styled.label`
  ${tw`inline-block text-sm text-gray-500 mb-1`};
`;

export default function PatientsFilter({ filterState, onUpdate, isClinic }: ComponentProps) {
  const initialState = {
    treatmentStatus: undefined,
    page: 1,
    textSearch: filterState.textSearch || '',
    // productKey: filterState?.productKey,
    // startDate: filterState?.startDate || new Date(),
    // endDate: filterState?.endDate || new Date(),
  };

  const treatmentStatusOptions = [
    { value: '', label: 'All' },
    { value: TreatmentStatus.No, label: 'No treatment' },
    { value: TreatmentStatus.Created, label: 'Created' },
    { value: TreatmentStatus.Paid, label: 'Paid' },
    { value: TreatmentStatus.Shipped, label: 'Shipped' },
    { value: TreatmentStatus.Active, label: 'Active' },
    { value: TreatmentStatus.Completed, label: 'Completed' },
  ];

  const emptySate = {
    treatmentStatus: undefined,
    page: 1,
    textSearch: '',
  };

  const [filter, setFilter] = useState<PatientsFilterProps>(initialState);
  const [timer, setTimer] = useState<any>(null);
  // const [startDate, setStartDate] = useState(initialState.startDate);
  // const [endDate, setEndDate] = useState(initialState.endDate);

  const onTreatmentStatusChange = (event: any) => {
    setFilter({ ...filter, treatmentStatus: event.value });
    onUpdate({ ...filter, treatmentStatus: event.value });
  };
  //
  // const onStartDateChange = (date: any) => {
  //   setStartDate(date);
  //
  //   date.setHours(0, 0, 0);
  //   endDate.setHours(23, 59, 0);
  //   setFilter({ ...filter, startDate: date, endDate });
  //   onUpdate({ ...filter, startDate: date, endDate });
  // };
  //
  // const onEndDateChange = (date: any) => {
  //   const end = date;
  //
  //   setEndDate(end);
  //   end.setHours(23, 59, 0);
  //
  //   setFilter({ ...filter, endDate: end });
  //   onUpdate({ ...filter, endDate: end });
  // };
  //
  const onTextSearchChange = (e: any) => {
    setFilter({ ...filter, textSearch: e.target.value });
    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      onUpdate({ ...filter, textSearch: e.target.value });
    }, 500);

    setTimer(newTimer);
  };

  const clearFilter = () => {
    // setStartDate(new Date());
    // setEndDate(new Date());
    setFilter(emptySate);
    onUpdate(emptySate);
  };

  return (
    <div className="mb-4 bg-white w-full shadow sm:rounded-lg">
      <div className="flex flex-wrap space-y-2 lg:space-y-0 px-4 py-4 space-x-4 items items-stretch">
        <StyledBlock>
          <div>
            <StyledLabel>{strings.filter.textSearch}:</StyledLabel>
            <input
              id="search"
              name="search"
              className="block w-full md:w-80 bg-white border border-gray-300 rounded-md py-2 px-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Search"
              type="search"
              value={filter.textSearch}
              onChange={(event) => onTextSearchChange(event)}
            />
          </div>
        </StyledBlock>
        {/* <StyledBlock> */}
        {/*  <div className="w-56"> */}
        {/*    <StyledLabel>{strings.filter.startDate}:</StyledLabel> */}
        {/*    <DatePicker */}
        {/*      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md px-3" */}
        {/*      dateFormat="yyyy-MM-dd" */}
        {/*      selected={startDate} */}
        {/*      onChange={onStartDateChange} */}
        {/*      selectsStart */}
        {/*      startDate={startDate} */}
        {/*      endDate={endDate} */}
        {/*    /> */}
        {/*  </div> */}
        {/* </StyledBlock> */}
        {/* <StyledBlock> */}
        {/*  <div className="w-56"> */}
        {/*    <StyledLabel>{strings.filter.endDate}:</StyledLabel> */}
        {/*    <DatePicker */}
        {/*      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md px-3" */}
        {/*      dateFormat="yyyy-MM-dd" */}
        {/*      selected={endDate} */}
        {/*      onChange={onEndDateChange} */}
        {/*      selectsEnd */}
        {/*      startDate={startDate} */}
        {/*      endDate={endDate} */}
        {/*      minDate={startDate} */}
        {/*    /> */}
        {/*  </div> */}
        {/* </StyledBlock> */}
        {/* <StyledBlock> */}
        {/*  <div> */}
        {!isClinic && (
          <StyledBlock>
            <div>
              <StyledLabel htmlFor="orderStatus">{strings.filter.treatmentStatus}:</StyledLabel>

              <Select
                className="w-56 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                options={treatmentStatusOptions}
                value={treatmentStatusOptions.filter(function (option) {
                  return option.value === filter.treatmentStatus;
                })}
                onChange={(event) => onTreatmentStatusChange(event)}
              />
            </div>
          </StyledBlock>
        )}
        <StyledBlock className="!items-end">
          <CommonButtonDefault onClick={clearFilter} className="w-full md:w-auto inline-flex">
            {strings.filter.clearFilter}
          </CommonButtonDefault>
        </StyledBlock>
      </div>
    </div>
  );
}

PatientsFilter.defaultProps = {
  isClinic: false,
};
