import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import CommonButtonDefault from '../common/buttons/default';
import ModalContent from '../modals/content';
import { strings } from '../../localization/strings';
import * as recommendationsService from '../../services/api/recommendations';
import { useAuth } from '../../hooks/use-auth';
import { Recommendation } from '../../entities/recommendation';
import CommonAnimatedSpinner from '../common/animated/spinner';
import CKEditorInput from '../common/form/ckeditor';

interface ComponentProps {
  isEdit?: boolean;
  defaultValue?: Recommendation;
  onCreate: (recommendation: Recommendation) => void;
}

interface RecommendationForm {
  title: string;
  titleAdmin: string;
  longDescription: string;
}

function RecommendationsCreateModal({ onCreate, defaultValue, isEdit }: ComponentProps) {
  const { token } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { register, handleSubmit, setValue } = useForm<RecommendationForm>();

  const onDisplayModal = () => {
    setIsModalVisible(true);
  };

  const onSubmit: SubmitHandler<RecommendationForm> = async (data) => {
    let recommendation;

    setIsLoading(true);

    if (isEdit && defaultValue) {
      recommendation = await recommendationsService.update(token, defaultValue.id, data);
    } else {
      recommendation = await recommendationsService.create(token, data);
    }

    onCreate(recommendation);
    setValue('title', '');
    setValue('titleAdmin', '');
    setValue('longDescription', '');
    setIsModalVisible(false);
    setIsLoading(false);
  };

  useEffect(() => {
    register('longDescription');
  }, [register]);

  return (
    <div>
      <CommonButtonDefault onClick={() => onDisplayModal()}>
        {isEdit ? strings.button.edit : strings.button.createRecommendation}
      </CommonButtonDefault>
      {isModalVisible && (
        <ModalContent
          visible
          onCloseClick={() => setIsModalVisible(false)}
          title={isEdit ? strings.recommendationModal.edit : strings.recommendationModal.create}
        >
          <div>
            <div className="mt-5">
              <div className="mb-3">
                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                  Title for allergist
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    defaultValue={defaultValue ? defaultValue.titleAdmin : undefined}
                    {...register('titleAdmin')}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>{' '}
              <div className="mb-3">
                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                  Title
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    defaultValue={defaultValue ? defaultValue.title : undefined}
                    {...register('title')}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                  Description
                </label>
                <div className="mt-1">
                  <CKEditorInput
                    value={defaultValue ? defaultValue.longDescription : ''}
                    onChange={(value) => setValue(`longDescription`, value)}
                  />
                </div>
              </div>
            </div>
            <div className="pt-5">
              <div className="flex">
                <button
                  type="button"
                  onClick={handleSubmit(onSubmit)}
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Save
                </button>
                <button
                  onClick={() => setIsModalVisible(false)}
                  type="button"
                  className="ml-3 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Cancel
                </button>
                {isLoading && (
                  <div className="mt-2 pl-3">
                    <CommonAnimatedSpinner sm />
                  </div>
                )}
              </div>
            </div>
          </div>
        </ModalContent>
      )}
    </div>
  );
}

RecommendationsCreateModal.defaultProps = {
  isEdit: false,
  defaultValue: null,
};

export default RecommendationsCreateModal;
