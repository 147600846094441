import React from 'react';
import tw, { styled } from 'twin.macro';

import ResultBlock from '../result/result-block';
import SymptomTableComponent from './symptom-table';
import { OrderViewAnswer } from '../../entities/order-view';
import { SimpleObject } from '../../entities/simple-object';
import { strings } from '../../localization/strings';

interface ComponentProps {
  questionnaires: OrderViewAnswer[];
  values?: SimpleObject;
}

const Title = styled.div`
  ${tw`font-bold text-[15px]`}
`;

function QuestionnaireAnswerComponent({ questionnaires, values }: ComponentProps) {
  return (
    <div>
      {questionnaires && (
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          {values?.firstVisit === false && <div className="px-4 sm:px-6 pt-5">{strings.clinic.answers.noAnswers}</div>}
          {questionnaires.map((questionnaire, indexM) => (
            <div key={`questionnaires-${questionnaire.id}-${indexM + 1}`}>
              <div className="px-4 sm:px-6 pt-2 text-md text-gray-500">
                #{questionnaire.id}, {questionnaire.createdAt}
              </div>
              <div className="mt-5">
                {questionnaire.symptoms &&
                  questionnaire.symptoms.map((symptomsGroup, index) => (
                    <div key={`symptomsGroup-${index + 1}`}>
                      <SymptomTableComponent
                        groupId={symptomsGroup.id}
                        title={symptomsGroup.title}
                        symptoms={symptomsGroup.symptoms}
                      />
                    </div>
                  ))}

                <div className="mt-5 mb-5">
                  <div className="grid lg:grid-cols-2 gap-0.5 items items-stretch">
                    {questionnaire.mainAnswers &&
                      questionnaire.mainAnswers.map(
                        (answer, index) =>
                          answer.values.length > 0 && (
                            <div key={`main-answer-${index + 1}`}>
                              <ResultBlock
                                title={strings.questionnaireAnswer[answer.key]}
                                answerValues={answer.values}
                              />
                            </div>
                          ),
                      )}
                  </div>
                </div>
                {questionnaire.answers.length > 0 && (
                  <div className="mt-10 mb-5">
                    <div className="grid lg:grid-cols-2 gap-0.5 flex items items-stretch">
                      {questionnaire.answers &&
                        questionnaire.answers.map((answer, index) => (
                          <div key={`answer-${index + 1}`}>
                            <ResultBlock title={answer.question} answerValues={answer.values} />
                          </div>
                        ))}
                    </div>
                  </div>
                )}
                <div className="grid lg:grid-cols-2 gap-0.5 flex items items-stretch px-4 pb-5">
                  {values?.food && (
                    <div className="mb-3">
                      <Title>{strings.clinic.answers.foodsThatProvokeSymptoms}:</Title>
                      {values.food}
                    </div>
                  )}
                  {values?.drugs && (
                    <div className="mb-3">
                      <Title>{strings.clinic.answers.drugsThatProvokeSymptoms}:</Title>
                      {values.drugs}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

QuestionnaireAnswerComponent.defaultProps = {
  values: {},
};

export default QuestionnaireAnswerComponent;
