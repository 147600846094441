import React, { ReactNode } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { MenuIcon } from '@heroicons/react/outline';

interface ComponentProps {
  id: string;
  children: ReactNode;
}

export function SortableItem({ id, children }: ComponentProps) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} className="flex w-full">
      <div className="mr-2 mt-1">
        <MenuIcon className="w-4 h-4 no-outline" {...attributes} {...listeners} />
      </div>
      <div className="grow">{children}</div>
    </div>
  );
}
