import React, { useState } from 'react';
import * as uuid from 'uuid';

import { strings } from '../../localization/strings';
import CKEditorInput from '../../components/common/form/ckeditor';
import * as messagesApi from '../../services/api/messages';
import { useAuth } from '../../hooks/use-auth';
import AlertPrimary from '../../components/common/alerts/alert-primary';
import CommonButtonDefault from '../../components/common/buttons/default';
import MessagingChat from '../../components/messaging/chat';
import { useMessages } from '../../hooks/use-messages';
import { useCustomers } from '../../hooks/use-customers';

function SuccessText() {
  return (
    <div
      className="py-3 px-4 bg-green-400 items-center text-white leading-none flex lg:inline-flex text-sm mb-2 rounded transition duration-500 ease-in-out transition-all"
      role="alert"
    >
      <span className="font-semibold text-left flex-auto">Message was successfully sent!</span>
    </div>
  );
}

interface ComponentProps {
  customerId: number;
  treatmentPlan3Url: string;
  treatmentPlan6Url: string;
}

function OrdersResultsQuestionnaireMessagesComponent({
  customerId,
  treatmentPlan3Url,
  treatmentPlan6Url,
}: ComponentProps) {
  const { token } = useAuth();
  const { addMessage } = useMessages();
  const { updateLastMessage } = useCustomers();

  const messageValues = {
    intro: strings.messages.intro,

    food: strings.messages.food,

    previousResearches: strings.messages.previousResearches,

    skin: strings.messages.skin,

    bloodPressure: strings.messages.bloodPressure,

    beesWasps: strings.messages.beesWasps,

    researchResultsEmail: strings.messages.researchResultsEmail,

    researchResultsEmailAndPhotos: strings.messages.researchResultsEmailAndPhotos,

    photos: strings.messages.photos,

    thanks: strings.messages.thanks,
  };

  type MessagesKeys = keyof typeof messageValues;

  const [predefinedMessage, setPredefinedMessage] = useState<string>('');
  const [selectedConditions, setSelectedConditions] = useState<MessagesKeys[]>([]);
  const [messageSent, setMessageSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const handleSend = async () => {
    const messageId = uuid.v4();

    addMessage(messageId, predefinedMessage);
    updateLastMessage(customerId, messageId, predefinedMessage, false);
    // setPredefinedMessage('');

    try {
      await messagesApi.sendMessage(token, {
        messageId,
        customerId,
        message: predefinedMessage,
      });

      setMessageSent(true);
    } catch (e: any) {
      setErrorMessage(true);
    }
  };

  const onChangeMessage = (type: MessagesKeys, event: any) => {
    if (event.target.checked) {
      selectedConditions.push(type);
    } else {
      selectedConditions.splice(selectedConditions.indexOf(type), 1);
    }

    setSelectedConditions([...selectedConditions]);

    let message = '';

    if (selectedConditions.length > 0) {
      let questions = selectedConditions.map((condition: MessagesKeys) => messageValues[condition]).join('</li><li>');

      questions = `<ul><li>${questions}</li></ul>`;

      let messageSuffix = '';

      if (selectedConditions.indexOf('previousResearches') !== -1 && selectedConditions.indexOf('skin') === -1) {
        messageSuffix = `<span className="mt-3 !block">${messageValues.researchResultsEmail}</span>`;
      }

      if (selectedConditions.indexOf('previousResearches') !== -1 && selectedConditions.indexOf('skin') !== -1) {
        messageSuffix = `<span className="mt-3 !block">${messageValues.researchResultsEmailAndPhotos}</span>`;
      }

      if (selectedConditions.indexOf('skin') !== -1 && selectedConditions.indexOf('previousResearches') === -1) {
        messageSuffix = `<span className="mt-3 !block">${messageValues.photos}</span>`;
      }

      message = `${messageValues.intro} ${questions} ${messageSuffix}
      <div className="mt-3 !block">${messageValues.thanks}</div>`;
    }
    setPredefinedMessage(message);
  };

  return (
    <>
      {messageSent && <SuccessText />}
      {errorMessage && <AlertPrimary text="Error" type="danger" />}

      <>
        <MessagingChat customerId={customerId} showInput={false} />
        <div className="border-t border-gray-200 pt-5 mt-5">
          <div className="flex items-center mb-3">
            <input
              id="food"
              aria-describedby="food"
              name="food"
              type="checkbox"
              onChange={(event) => onChangeMessage('food', event)}
              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
            />
            <label htmlFor="food" className="ml-3">
              <span className="text-sm font-medium text-gray-900">Has a food allergy</span>
            </label>
          </div>
          <div className="flex items-center mb-3">
            <input
              id="previousResearches"
              aria-describedby="previous-researches"
              name="previousResearches"
              type="checkbox"
              onChange={(event) => onChangeMessage('previousResearches', event)}
              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
            />
            <label htmlFor="previousResearches" className="ml-3">
              <span className="text-sm font-medium text-gray-900">Has done previous researches</span>
            </label>
          </div>
          <div className="flex items-center mb-3">
            <input
              id="skin"
              aria-describedby="skin"
              name="skin"
              type="checkbox"
              onChange={(event) => onChangeMessage('skin', event)}
              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
            />
            <label htmlFor="skin" className="ml-3">
              <span className="text-sm font-medium text-gray-900">Has skin symptoms</span>
            </label>
          </div>
          <div className="flex items-center mb-3">
            <input
              id="bloodPressure"
              aria-describedby="blood-pressure"
              name="bloodPressure"
              type="checkbox"
              onChange={(event) => onChangeMessage('bloodPressure', event)}
              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
            />
            <label htmlFor="bloodPressure" className="ml-3">
              <span className="text-sm font-medium text-gray-900">Has blood pressure reactions</span>
            </label>
          </div>
          <div className="flex items-center mb-3">
            <input
              id="beesWasps"
              aria-describedby="bees-wasps"
              name="beesWasps"
              type="checkbox"
              onChange={(event) => onChangeMessage('beesWasps', event)}
              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
            />
            <label htmlFor="beesWasps" className="ml-3">
              <span className="text-sm font-medium text-gray-900">Has bee/wasp stig reaction</span>
            </label>
          </div>
          <div className="flex items-center mb-3">
            <label htmlFor="beesWasps" className="ml-3">
              <span className="text-sm font-medium text-gray-900">
                Treatment plan 3 months link: {treatmentPlan3Url}{' '}
              </span>
            </label>
          </div>
          <div className="flex items-center mb-3">
            <label htmlFor="beesWasps" className="ml-3">
              <span className="text-sm font-medium text-gray-900">
                Treatment plan 6 months link: {treatmentPlan6Url}{' '}
              </span>
            </label>
          </div>
        </div>
        <div className="mb-3 mt-5">
          <CKEditorInput value={predefinedMessage} onChange={(value) => setPredefinedMessage(value)} />
        </div>

        <CommonButtonDefault
          primary
          disabled={predefinedMessage.trim().length === 0}
          onClick={handleSend}
          type="button"
        >
          Send message
        </CommonButtonDefault>
      </>
    </>
  );
}

export default OrdersResultsQuestionnaireMessagesComponent;
