import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClose } from '@fortawesome/free-solid-svg-icons';
import tw, { styled } from 'twin.macro';

import { SimpleObject } from '../../entities/simple-object';

interface ComponentProps {
  title: string;
  answerValues: SimpleObject;
}

const Title = styled.div`
  ${tw`font-bold text-[15px]`}
`;

export default function ResultBlock({ title, answerValues }: ComponentProps) {
  return (
    <div className="px-6 py-4">
      <div className="mb-4">
        <Title>{title}</Title>
      </div>
      <div className="">
        {answerValues.length === 0 && '-'}
        {answerValues.length === 1 &&
          answerValues.map((answerValue: string, index: number) => (
            <div className="flex items-center" key={`item-${index + 1}`}>
              {answerValue === 'Taip' && (
                <FontAwesomeIcon icon={faCheck} className="inline-block mr-2 text-xl text-green-500" />
              )}
              {answerValue === 'Ne' && (
                <FontAwesomeIcon icon={faClose} className="text-gray-400 inline-block mr-3 text-xl" />
              )}
              {answerValue}
            </div>
          ))}
        {answerValues.length > 1 && (
          <ul className="list-disc ml-4 text-sm">
            {answerValues.map(
              (answerValue: string, index: number) =>
                answerValue !== 'Taip' && (
                  <li className="mb-2" key={`item-${index + 1}`}>
                    {answerValue}
                  </li>
                ),
            )}
          </ul>
        )}
      </div>
    </div>
  );
}
