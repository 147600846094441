import React from 'react';

import UsersLoginForm from '../../components/users/login-form';

function UsersLoginPageComponent() {
  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="mx-auto w-full max-w-sm lg:w-96">
        <UsersLoginForm />
      </div>
    </div>
  );
}

export default UsersLoginPageComponent;
