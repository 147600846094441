import React from 'react';
import classNames from 'classnames';

import { OrderStatus } from '../../entities/order';

export default function OrderStatusBadge({ status }: { status: string }) {
  return (
    <span
      className={classNames('inline-flex items-center px-2 py-0.5 rounded text-xs font-medium shrink-0', {
        'bg-gray-100 text-gray-800': status === OrderStatus.New,
        'bg-red-100 text-red-800': status === OrderStatus.Paid,
        'bg-green-100 text-green-800': status === OrderStatus.Completed,
      })}
    >
      {status}
    </span>
  );
}
