import React, { useState } from 'react';
import {
  ChatContainer,
  MainContainer,
  Message,
  MessageGroup,
  MessageInput,
  MessageList,
} from '@chatscope/chat-ui-kit-react';
import * as uuid from 'uuid';

import { useAuth } from '../../hooks/use-auth';
import { useMessages } from '../../hooks/use-messages';
import * as messagesApi from '../../services/api/messages';
import { useCustomers } from '../../hooks/use-customers';
import { MessageDirection } from '../../entities/message-direction';
import { Message as ChatMessage } from '../../entities/message';
import MessagingEditModal from './edit-modal';
import { strings } from '../../localization/strings';
import CommonConfirmationModal from '../modals/confirmation';
import { SimpleObject } from '../../entities/simple-object';

interface ComponentProps {
  customerId: number;
  showInput?: boolean;
}

function MessagingChat({ customerId, showInput }: ComponentProps) {
  const { token } = useAuth();
  const { messageGroups, addMessage, updateMessage, deleteMessage } = useMessages();
  const { updateLastMessage } = useCustomers();

  const [confirmDelete, setConfirmDelete] = useState<SimpleObject<boolean>>({});

  const [value, setValue] = useState('');

  const handleSend = async (messageText: string) => {
    const messageId = uuid.v4();

    addMessage(messageId, messageText);

    setValue('');

    updateLastMessage(customerId, messageId, messageText, false);

    await messagesApi.sendMessage(token, {
      messageId,
      customerId,
      message: messageText,
    });
  };

  const handleChange = (message: string) => {
    setValue(message);
  };

  const onUpdateMessage = (message: ChatMessage) => {
    updateMessage(message.messageId, message.message);
  };

  const onDeleteMessage = async (message: ChatMessage) => {
    deleteMessage(message.messageId);

    await messagesApi.deleteMessage(token, message.messageId);
  };

  const onChangeStateDeleteConfirm = (message: ChatMessage, state: boolean) => {
    setConfirmDelete({ ...confirmDelete, [message.messageId]: state });
  };

  return (
    <MainContainer className="!w-full">
      <ChatContainer>
        <MessageList>
          {messageGroups.map((g) => (
            <MessageGroup key={g.id} direction={g.direction}>
              <MessageGroup.Messages>
                {g.messages.map((m) => (
                  <Message
                    key={m.messageId}
                    model={{
                      type: 'html',
                      payload: m.message,
                    }}
                  >
                    {g.direction === MessageDirection.Incoming && (
                      <Message.Footer>
                        <MessagingEditModal onUpdate={(message) => onUpdateMessage(message)} message={m} />

                        <button
                          className="ml-4 text-red-300"
                          type="button"
                          onClick={() => onChangeStateDeleteConfirm(m, true)}
                        >
                          {strings.button.delete}
                        </button>
                        <CommonConfirmationModal
                          open={confirmDelete[m.messageId] || false}
                          confirmed={() => onDeleteMessage(m)}
                          cancelled={() => onChangeStateDeleteConfirm(m, false)}
                          title="Do you really want to delete this message?"
                          description="You will not be able to restore it so be really careful"
                        />
                      </Message.Footer>
                    )}
                  </Message>
                ))}
              </MessageGroup.Messages>
            </MessageGroup>
          ))}
        </MessageList>
        {showInput && (
          <MessageInput
            attachButton={false}
            value={value}
            onChange={handleChange}
            onSend={handleSend}
            placeholder="Type here..."
          />
        )}
      </ChatContainer>
    </MainContainer>
  );
}

MessagingChat.defaultProps = {
  showInput: true,
};

export default MessagingChat;
