import axios from 'axios';

import { parameters } from '../../constants/parameters';
import { Symptom } from '../../entities/symptom';

export const list = async (token: string): Promise<Symptom[]> => {
  const url = `${parameters.api.baseUrl}/symptoms/list`;

  const res = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });

  return res.data;
};
