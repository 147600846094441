import axios from 'axios';

import { parameters } from '../../constants/parameters';
import { Source } from '../../entities/source';
import { SourceGroup } from '../../entities/source-group';
import { StatusResponse } from '../../entities/status-response';

export const list = async (token: string): Promise<Source[]> => {
  const url = `${parameters.api.baseUrl}/sources/list`;

  const res = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });

  return res.data;
};

export const groups = async (token: string): Promise<SourceGroup[]> => {
  const url = `${parameters.api.baseUrl}/sources/groups`;

  const res = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });

  return res.data;
};

export const updatePeriod = async (token: string, sourceId: number, months: number[]): Promise<StatusResponse> => {
  const url = `${parameters.api.baseUrl}/sources/update-period`;

  const res = await axios.post(
    url,
    {
      id: sourceId,
      months,
    },
    { headers: { Authorization: `Bearer ${token}` } },
  );

  return res.data;
};
