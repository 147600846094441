import { Month } from '../../entities/month';

const monthFormatter = new Intl.DateTimeFormat('lt', { month: 'long' });
const dateFormatter = new Intl.DateTimeFormat('lt', { day: '2-digit', month: '2-digit', year: 'numeric' });
const durationFormatter = new Intl.RelativeTimeFormat('lt', { style: 'narrow' });

export const getLongMonthName = (month: number): string => {
  return monthFormatter.format(new Date(2000, month - 1, 1));
};

export const fromJsDate = (date: Date): string => {
  return dateFormatter.format(date);
};

export const fromTimestamp = (timestamp: string): string => {
  return fromJsDate(new Date(timestamp));
};

export const getDurationFromDateString = (date: string): string => {
  if (!date) {
    return '';
  }

  const seconds = Math.abs((new Date(date).getTime() - new Date().getTime()) / 1000);

  if (seconds > 3600 * 24) {
    return durationFormatter.format(-1 * Math.floor(seconds / 86400), 'day');
  }
  if (seconds > 3600) {
    return durationFormatter.format(-1 * Math.floor(seconds / 3600), 'hour');
  }
  if (seconds > 60) {
    return durationFormatter.format(-1 * Math.floor(seconds / 60), 'minute');
  }

  return durationFormatter.format(-1 * Math.floor(seconds), 'second');
};

export const createMonths = (months: number[]): Month[] => {
  if (!months) {
    return months;
  }

  return months.map((n) => {
    return {
      id: n,
      title: getLongMonthName(n),
    };
  });
};

export const getMonthsList = (): Month[] => {
  return createMonths(Array.from(Array(12)).map((n, i) => i + 1));
};
