import React from 'react';

import CommonAnimatedLoader from '../../components/common/animated/loader';
import { useOrder } from '../../hooks/use-order';
import { MessagesContextProvider } from '../../context/messages';
import OrdersResultsQuestionnaireMessagesComponent from './results-questionnaire-messages';
import QuestionnaireAnswerComponent from '../../components/order/questionnaire-answer';

function OrdersResultsQuestionnairePageComponent() {
  const { order } = useOrder();

  return (
    <div>
      {!order && <CommonAnimatedLoader />}
      {order && (
        <div className="xl:flex xl:space-x-3 mb-5 pb-5 full-width">
          <div className="w-full xl:w-3/5">
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {order.order.patientIsChild ? (
                    <>
                      For kid: {order.order.patientName}
                      {order.order.patientLastName && (
                        <span className="pl-3 inline-flex">{order.order.patientLastName}</span>
                      )}
                    </>
                  ) : (
                    <>
                      {order.order.patientName}{' '}
                      {order.order.patientLastName ? order.order.patientLastName : order.order.lastName}
                    </>
                  )}
                  {order.order.patientAge && <>, age: {order.order.patientAge}</>}
                  {order.order.patientIsChild && order.order.personCode && (
                    <>, child person code: {order.order.personCode}</>
                  )}
                  {!order.order.patientIsChild && order.order.personCode && (
                    <>, person code: {order.order.personCode}</>
                  )}
                </h3>
              </div>
              {order.questionnaires && order.order.productKey !== 'TREATMENT_PLAN' && (
                <QuestionnaireAnswerComponent questionnaires={order.questionnaires} />
              )}
            </div>
          </div>
          <div className="w-full xl:w-2/5 mt-5 xl:mt-0">
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">Create follow-up questions</div>

              <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                {order?.order.customerId ? (
                  <MessagesContextProvider customerId={order?.order.customerId}>
                    <OrdersResultsQuestionnaireMessagesComponent
                      customerId={order?.order.customerId}
                      treatmentPlan3Url={order?.treatmentPlan3Url}
                      treatmentPlan6Url={order?.treatmentPlan6Url}
                    />
                  </MessagesContextProvider>
                ) : (
                  <div>The customer is not registered</div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default OrdersResultsQuestionnairePageComponent;
