import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import ModalContent from '../modals/content';
import { strings } from '../../localization/strings';
import * as messagesService from '../../services/api/messages';
import { useAuth } from '../../hooks/use-auth';
import { Message } from '../../entities/message';
import CommonAnimatedSpinner from '../common/animated/spinner';
import CKEditorInput from '../common/form/ckeditor';

interface ComponentProps {
  message: Message;
  onUpdate: (item: Message) => void;
}

interface MessageForm {
  message: string;
}

function MessagingEditModal({ onUpdate, message }: ComponentProps) {
  const { token } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { register, handleSubmit, setValue } = useForm<MessageForm>();

  const onDisplayModal = () => {
    setIsModalVisible(true);
  };

  const onSubmit: SubmitHandler<MessageForm> = async (data) => {
    setIsLoading(true);

    await messagesService.updateMessage(token, message.messageId, data.message);

    onUpdate({ ...message, message: data.message });
    setValue('message', '');
    setIsModalVisible(false);
    setIsLoading(false);
  };

  useEffect(() => {
    register('message');
  }, [register]);

  return (
    <div>
      <button type="button" onClick={() => onDisplayModal()}>
        {strings.button.edit}
      </button>
      {isModalVisible && (
        <ModalContent visible onCloseClick={() => setIsModalVisible(false)} title={strings.messageEditModal.title}>
          <div>
            <CKEditorInput value={message ? message.message : ''} onChange={(value) => setValue(`message`, value)} />
            <div className="pt-5">
              <div className="flex">
                <button
                  type="button"
                  onClick={handleSubmit(onSubmit)}
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Save
                </button>
                <button
                  onClick={() => setIsModalVisible(false)}
                  type="button"
                  className="ml-3 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Cancel
                </button>
                {isLoading && (
                  <div className="mt-2 pl-3">
                    <CommonAnimatedSpinner sm />
                  </div>
                )}
              </div>
            </div>
          </div>
        </ModalContent>
      )}
    </div>
  );
}

export default MessagingEditModal;
