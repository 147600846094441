import React from 'react';
import tw, { styled } from 'twin.macro';

interface ComponentProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  primary?: boolean;
  danger?: boolean;
  link?: boolean;
  disabled?: boolean;
}

const Button = styled.button<ComponentProps>`
  ${tw`block text-base rounded py-2 px-4 font-medium`}
  ${tw`bg-gray-300 text-gray-900 hover:bg-gray-400`}
  ${(props) => props.primary && tw`bg-blue-800 text-white hover:bg-blue-900`}
  ${(props) => props.danger && tw`bg-red-500 text-white hover:bg-red-800`}
  ${(props) => props.disabled && tw`opacity-25`}
   ${(props) =>
    props.link &&
    tw`!p-0 text-gray-900 inline-block leading-6 bg-transparent hover:bg-transparent underline hover:text-blue-500 font-normal text-base rounded-none`}
`;

function CommonButtonDefault({ children, ...rest }: ComponentProps) {
  return <Button {...rest}>{children}</Button>;
}

CommonButtonDefault.defaultProps = {
  primary: false,
  danger: false,
  disabled: false,
  link: false,
};

export default CommonButtonDefault;
