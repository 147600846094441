import axios from 'axios';

import { parameters } from '../../constants/parameters';
import { Coupon } from '../../entities/coupon';
import CouponForm from '../../entities/form/coupon-form';

export const list = async (token: string): Promise<Coupon[]> => {
  const url = `${parameters.api.baseUrl}/coupons`;

  const res = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });

  return res.data;
};

export const one = async (token: string, id: number): Promise<Coupon> => {
  const url = `${parameters.api.baseUrl}/coupons/${id}`;

  const res = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });

  return res.data;
};

export const update = async (token: string, id: number, data: Partial<CouponForm>): Promise<Coupon> => {
  const url = `${parameters.api.baseUrl}/coupons/${id}`;

  const res = await axios.put(url, data, { headers: { Authorization: `Bearer ${token}` } });

  return res.data;
};

export const remove = async (token: string, id: number): Promise<{ status: string }> => {
  const url = `${parameters.api.baseUrl}/coupons/${id}`;

  const res = await axios.delete(url, { headers: { Authorization: `Bearer ${token}` } });

  return res.data;
};

export const create = async (token: string, data: Partial<CouponForm>): Promise<Coupon> => {
  const url = `${parameters.api.baseUrl}/coupons`;

  const res = await axios.post(url, data, { headers: { Authorization: `Bearer ${token}` } });

  return res.data;
};
