import axios from 'axios';

import { parameters } from '../../constants/parameters';
import { OrderSourceGroup } from '../../entities/order-source-group';
import { OrderViewSourceGroup } from '../../entities/order-view';
import { StatusResponse } from '../../entities/status-response';

export const update = async (token: string, id: number, data: Partial<OrderSourceGroup>): Promise<OrderSourceGroup> => {
  const url = `${parameters.api.baseUrl}/orders/source-groups/update`;

  const res = await axios.post(
    url,
    {
      id,
      ...data,
    },
    { headers: { Authorization: `Bearer ${token}` } },
  );

  return res.data;
};

export const updatePositions = async (
  token: string,
  orderId: number,
  sourceGroupIds: number[],
): Promise<StatusResponse> => {
  const url = `${parameters.api.baseUrl}/orders/source-groups/update-positions`;

  const res = await axios.post(
    url,
    {
      orderId,
      sourceGroupIds,
    },
    { headers: { Authorization: `Bearer ${token}` } },
  );

  return res.data;
};

export const remove = async (token: string, id: number): Promise<{ status: string }> => {
  const url = `${parameters.api.baseUrl}/orders/source-groups/${id}`;

  const res = await axios.delete(url, { headers: { Authorization: `Bearer ${token}` } });

  return res.data;
};

export const create = async (token: string, orderId: number, id: number): Promise<OrderViewSourceGroup> => {
  const url = `${parameters.api.baseUrl}/orders/source-groups`;

  const res = await axios.put(url, { orderId, id }, { headers: { Authorization: `Bearer ${token}` } });

  return res.data;
};
