import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { useAuth } from '../../hooks/use-auth';
import CommonAnimatedLoader from '../../components/common/animated/loader';
import CKEditorInput from '../../components/common/form/ckeditor';
import CommonAnimatedSpinner from '../../components/common/animated/spinner';
import { Recommendation } from '../../entities/recommendation';
import * as recommendationsService from '../../services/api/recommendations';

interface ComponentProps {
  isEdit?: boolean;
}

interface RecommendationForm {
  title: string;
  titleAdmin: string;
  longDescription: string;
}

function RecommendationsFormPageComponent({ isEdit }: ComponentProps) {
  const { token } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>();
  const { register, watch, handleSubmit, setValue } = useForm<RecommendationForm>();
  let recommendation;

  useEffect(() => {
    recommendation = {
      title: '',
      titleAdmin: '',
      longDescription: '',
    };
  }, []);

  const onCancel = async () => {
    navigate('/recommendations');
  };

  const onSubmit = async (data: RecommendationForm) => {
    setIsLoading(true);

    if (isEdit) {
      if (id) {
        await recommendationsService.update(token, parseInt(id, 10), data);
      }
    } else {
      await recommendationsService.create(token, data);
    }

    navigate(`/recommendations`);
  };

  useEffect(() => {
    register('longDescription');
  }, [register]);

  useEffect(() => {
    const fetchRecommendation = async () => {
      if (id) {
        recommendation = await recommendationsService.one(token, parseInt(id, 10));

        setValue('title', recommendation.title);
        setValue('titleAdmin', recommendation.titleAdmin);
        setValue('longDescription', recommendation.longDescription);
      }
    };

    fetchRecommendation();
  }, [setValue, token, id]);

  const description = watch('longDescription');

  return (
    <div>
      {isLoading && <CommonAnimatedLoader />}
      <header className="mb-3 mt-3">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold leading-tight text-gray-900">
            {isEdit ? 'Edit recommendation' : 'Create new recommendation'}
          </h1>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <form onSubmit={handleSubmit(onSubmit)} className="shadow border-b border-gray-200 sm:rounded-lg bg-white">
            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 p-4">
              <div className="col-span-6 mt-5">
                <div className="mb-3">
                  <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                    Title for allergist
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      {...register('titleAdmin')}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>{' '}
                <div className="mb-3">
                  <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                    Title
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      {...register('title')}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                    Description
                  </label>
                  <div className="mt-1">
                    <CKEditorInput value={description || ''} onChange={(value) => setValue(`longDescription`, value)} />
                  </div>
                </div>
              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 rounded-b-lg flex">
              <div className="pt-5">
                <div className="flex">
                  <button
                    type="button"
                    onClick={handleSubmit(onSubmit)}
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Save
                  </button>
                  <button
                    onClick={() => onCancel()}
                    type="button"
                    className="ml-3 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Cancel
                  </button>
                  {isLoading && (
                    <div className="mt-2 pl-3">
                      <CommonAnimatedSpinner sm />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </main>
    </div>
  );
}

RecommendationsFormPageComponent.defaultProps = {
  isEdit: false,
};

export default RecommendationsFormPageComponent;
