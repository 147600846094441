import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import classNames from 'classnames';
import { XCircleIcon } from '@heroicons/react/solid';

import CommonAnimatedLoader from '../../components/common/animated/loader';
import { useOrder } from '../../hooks/use-order';
import * as ordersService from '../../services/api/orders';
import { useAuth } from '../../hooks/use-auth';
import { AllergensAnswer } from '../../entities/allergens-answer';
import { OrderImportStatus } from '../../entities/order';
import CommonAnimatedSpinner from '../../components/common/animated/spinner';
import CommonButtonDefault from '../../components/common/buttons/default';
import * as ordersAllergensService from '../../services/api/orders-allergens';

interface PatientImportForm {
  remoteId: number;
}

function OrdersResultsBloodPageComponent() {
  const { token } = useAuth();
  const { order } = useOrder();
  const [answer, setAnswer] = useState<AllergensAnswer>();
  const [importStatus, setImportStatus] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { register, handleSubmit } = useForm<PatientImportForm>();

  const onSubmit: SubmitHandler<PatientImportForm> = async (data) => {
    if (order) {
      setImportStatus('initiated');
      await ordersService.manualImport(token, order?.order.id, data.remoteId);

      const validateImportState = async () => {
        const importState = await ordersService.importStatus(token, order?.order.id);

        setImportStatus(importState.importStatus);

        if (importState.importStatus === OrderImportStatus.Completed) {
          document.location.reload();
        } else if (importState.importStatus.indexOf('failed') === -1) {
          setTimeout(validateImportState, 1000);
        }
      };

      validateImportState();
    }
  };

  useEffect(() => {
    const fetchData = async (hash: string) => {
      const item = await ordersService.getAllergens(token, hash);

      setAnswer(item);
      setIsLoading(false);
    };

    if (order) {
      fetchData(order.order.hash);
    }
  }, [order, setAnswer, setIsLoading, token]);

  if (order?.order.productKey === 'CONSULTATION') {
    return (
      <div>
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">Import is unavailable for consultation orders</h3>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const onDelete = async (id: number, groupIndex: number, sourceIndex: number, allergenIndex: number) => {
    setIsLoading(true);
    await ordersAllergensService.remove(token, id);
    if (answer) {
      answer.groups[groupIndex].sources[sourceIndex].allergens.splice(allergenIndex, 1);
    }
    setIsLoading(false);
  };

  return (
    <div>
      {isLoading && <CommonAnimatedLoader />}

      <div className="bg-white shadow sm:rounded-lg mb-4">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Enter Imunodiagnostika Order ID</h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <p>Enter order ID from Imunodiagnostika Laboratory system to import blood results data</p>
          </div>
          <form className="mt-5 sm:flex sm:items-center" onSubmit={handleSubmit(onSubmit)}>
            <div className="w-full sm:max-w-xs">
              <label htmlFor="patientId" className="sr-only">
                Order ID
              </label>
              <input
                type="number"
                {...register('remoteId')}
                id="patientId"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder="e.g. 11527"
                defaultValue={order?.order.remoteId}
              />
            </div>

            <button
              type="submit"
              className="mt-3 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Import
            </button>
            {importStatus && (
              <>
                {importStatus.indexOf('failed') === -1 && (
                  <div className="mx-3">
                    <CommonAnimatedSpinner sm />
                  </div>
                )}
                <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800 shrink-0">
                  {importStatus}
                </span>
              </>
            )}
          </form>
        </div>
      </div>

      {answer && order?.order.remoteId && (
        <div className="bg-white shadow overflow-hidden rounded mb-4">
          <table className="w-full border-collapse">
            <thead className="bg-content-300">
              <tr className="">
                <th className="text-left p-3 md:p-5">Allergen</th>
                <th className="text-left p-3 md:p-5">Analyzer</th>
                <th className="text-left p-3 md:p-5">Component</th>
                <th className="text-left p-3 md:p-5">Biochemical designation</th>
                <th className="text-left p-3 md:p-5">Value</th>
                <th className="text-left p-3 md:p-5">Action</th>
              </tr>
            </thead>
            {answer.groups.map((sourceGroup, groupIndex) => (
              <tbody key={`answerGroups-${sourceGroup.id}-${groupIndex + 1}`}>
                <tr className="border-t border-content-500">
                  <td className="p-3 md:px-5 pt-6" colSpan={5}>
                    <b>{sourceGroup.title}</b>
                  </td>
                </tr>
                {sourceGroup.sources.map((source, sourceIndex) => {
                  return source.allergens.map((allergen, allergenIndex) => (
                    <tr
                      className={classNames('border-t border-content-500', {
                        'bg-red-50': allergen.strength >= 3,
                        'bg-amber-50': allergen.strength === 2,
                        'bg-green-50': allergen.strength === 1,
                      })}
                      key={`s-${sourceGroup.id}-${source.id}-${allergen.id}-${allergen.analyzerId}`}
                    >
                      <td className="p-3 md:px-5">{source.title}</td>
                      <td className="p-3 md:px-5">{allergen.analyzerTitle}</td>
                      <td className="p-3 md:px-5">{allergen.componentCode}</td>
                      <td className="p-3 md:px-5">{allergen.biochemicalDesignation}</td>
                      <td className="p-3 md:px-5">{allergen.valueText}</td>
                      <td className="p-3 md:px-5">
                        <CommonButtonDefault
                          onClick={() => onDelete(allergen.orderAllergenId, groupIndex, sourceIndex, allergenIndex)}
                          type="button"
                          danger
                          className="ml-auto"
                        >
                          Delete
                        </CommonButtonDefault>
                      </td>
                    </tr>
                  ));
                })}
              </tbody>
            ))}
          </table>
          {!!answer.airwayGroups?.length && (
            <>
              <h3 className="text-lg leading-6 font-bold text-gray-900 mt-7 mb-4 px-5">
                Inhaled allergens (for screener-asit)
              </h3>
              <table className="w-full border-collapse">
                <thead className="bg-content-300">
                  <tr className="">
                    <th className="text-left p-3 md:p-5">Allergen</th>
                    <th className="text-left p-3 md:p-5">Analyzer</th>
                    <th className="text-left p-3 md:p-5">Component</th>
                    <th className="text-left p-3 md:p-5">Biochemical designation</th>
                    <th className="text-left p-3 md:p-5">Value</th>
                    <th className="text-left p-3 md:p-5">Action</th>
                  </tr>
                </thead>

                {answer.airwayGroups?.map((sourceGroup, groupIndex) => (
                  <tbody key={`airwayGroups-${sourceGroup.id}-${groupIndex + 1}`}>
                    <tr className="border-t border-content-500">
                      <td className="p-3 md:px-5 pt-6" colSpan={5}>
                        <b>{sourceGroup.title}</b>
                      </td>
                    </tr>
                    {sourceGroup.sources.map((source, sourceIndex) => {
                      return source.allergens.map((allergen, allergenIndex) => (
                        <tr
                          className={classNames('border-t border-content-500', {
                            'bg-red-50': allergen.strength >= 3,
                            'bg-amber-50': allergen.strength === 2,
                            'bg-green-50': allergen.strength === 1,
                          })}
                          key={`s-a-${sourceGroup.id}-${source.id}-${allergen.id}-${allergen.analyzerId}`}
                        >
                          <td className="p-3 md:px-5">{source.title}</td>
                          <td className="p-3 md:px-5">{allergen.analyzerTitle}</td>
                          <td className="p-3 md:px-5">{allergen.componentCode}</td>
                          <td className="p-3 md:px-5">{allergen.biochemicalDesignation}</td>
                          <td className="p-3 md:px-5">{allergen.valueText}</td>
                          <td className="p-3 md:px-5">
                            <CommonButtonDefault
                              onClick={() => onDelete(allergen.orderAllergenId, groupIndex, sourceIndex, allergenIndex)}
                              type="button"
                              danger
                              className="ml-auto"
                            >
                              Delete
                            </CommonButtonDefault>
                          </td>
                        </tr>
                      ));
                    })}
                  </tbody>
                ))}
              </table>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default OrdersResultsBloodPageComponent;
