import React from 'react';
import classNames from 'classnames';

import { OrderImportStatus } from '../../entities/order';

export default function OrderImportStatusBadge({ status }: { status: string }) {
  return (
    <span
      className={classNames('inline-flex items-center px-2 py-0.5 rounded text-xs font-medium', {
        'bg-blue-100 text-blue-800': status === OrderImportStatus.Completed,
      })}
    >
      {status}
    </span>
  );
}
