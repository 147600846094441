import React from 'react';
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';

import { strings } from '../../localization/strings';
import PatientProps from '../../entities/patient';
import Badge from '../common/badge';

interface ComponentProps {
  patients: PatientProps[];
  pages: number;
  onUpdate: (pageNo: number) => void;
  currPageNo: number;
}

export default function ClinicPatientsTableComponent({ patients, pages, onUpdate, currPageNo }: ComponentProps) {
  const onNextPage = () => {
    if (currPageNo < pages) {
      onUpdate(currPageNo + 1);
    }
  };

  const onPrevPage = () => {
    if (currPageNo > 0) {
      onUpdate(currPageNo - 1);
    }
  };

  const onPage = (page: number) => {
    onUpdate(page);
  };

  return (
    <>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-4 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="relative px-4 py-3">
                      {' '}
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {strings.clinic.patientsList.patient}
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {strings.clinic.patientsList.status}
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {strings.clinic.patientsList.phone}
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {strings.clinic.patientsList.updatedAt}
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {strings.clinic.patientsList.createdAt}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {patients.map((patient, index) => (
                    <tr key={`patient-${patient.id}`} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                      <td className="px-4 py-4 whitespace-nowrap text-left text-sm font-medium">
                        <Link
                          to={`/clinic-patients/preview/${patient.hash}`}
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          Edit
                        </Link>
                      </td>
                      <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div>
                          {patient.name ? patient.name : '-'} {patient.lastName}
                        </div>
                      </td>
                      <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                        {patient.answerId ? (
                          <Badge status="success" text={strings.clinic.patientsList.questionnaireStatus.filled} />
                        ) : (
                          <Badge status="secondary" text={strings.clinic.patientsList.questionnaireStatus.invited} />
                        )}
                      </td>
                      <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div>
                          <div className="text-xs">{patient.phone}</div>
                        </div>
                      </td>
                      <td className="px-4 py-4 whitespace-nowrap text-xs text-gray-500">{patient.answeredAt}</td>
                      <td className="px-4 py-4 whitespace-nowrap text-xs text-gray-500">{patient.createdAt}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0 pb-4">
        <div className="-mt-px flex w-0 flex-1">
          <button
            onClick={onPrevPage}
            type="button"
            id="first-page"
            disabled={currPageNo < 1 || currPageNo === 1}
            className="inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium text-gray-500 enabled:hover:border-gray-300 enabled:hover:text-gray-700 disabled:opacity-50"
          >
            <ArrowNarrowLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
            Previous
          </button>
        </div>
        <div className="hidden md:-mt-px md:flex">
          {Array.from(Array(pages), (e, i) => {
            return (
              <button
                type="button"
                key={`page-${i + 1}`}
                onClick={() => onPage(i + 1)}
                className={`inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 ${
                  i + 1 === currPageNo && 'border-indigo-500 text-indigo-600'
                } `}
              >
                {i + 1}
              </button>
            );
          })}
        </div>
        <div className="-mt-px flex w-0 flex-1 justify-end">
          <button
            disabled={currPageNo === pages || currPageNo > pages}
            type="button"
            id="last-page"
            onClick={onNextPage}
            className="inline-flex items-center border-t-2 border-transparent pt-4 pl-1 text-sm font-medium text-gray-500 enabled:hover:border-gray-300 enabled:hover:text-gray-700 disabled:opacity-50"
          >
            Next
            <ArrowNarrowRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
          </button>
        </div>
      </nav>
    </>
  );
}
