export const strings = {
  error: {
    fieldIsRequired: 'Field is required',
    pageNotFound: {
      title: 'Puslapis nerastas',
      description: 'Patikrinkite puslapio adresą ir pamėginkite dar kartą.',
      goHome: 'Grįžti į pradžią',
    },
  },
  button: {
    login: 'Login',
    close: 'Close',
    edit: 'Edit',
    delete: 'Delete',
    createRecommendation: 'Create recommendation',
  },
  navigation: {
    orders: 'Orders',
    patients: 'Patients',
    messages: 'Messages',
    sources: 'Sources',
    sourceGroups: 'Source groups',
    recommendations: 'Recommendations',
    coupons: 'Coupons',
    signOut: 'Logout',
    feedback: 'Feedback',
  },
  form: {
    email: 'Email address',
    password: 'Password',
    selectSites: 'Select site',
    selectProducts: 'Select product',
  },
  sources: {
    selectPeriod: 'Select period',
    selectSymptom: 'Select symptom',
  },
  orderResultsView: {
    selectSymptoms: 'Select symptoms',
    selectSourceGroup: 'Select source group',
    selectSource: 'Select source',
  },
  recommendations: {
    assignHeading: 'Assign new recommendation:',
    assignButton: 'Assign recommendations',
    assignSelectPlaceholder: 'You can select multiple recommendations at once',
    assignSourceGroup: 'Assign source group',
    status: {
      new: 'New',
      good: 'Works',
      bad: "Doesn't work",
    },
  },
  recommendationModal: {
    create: 'Create recommendation',
    edit: 'Edit recommendation',
  },
  messageEditModal: {
    title: 'Edit message',
  },
  strengths: [
    'Nevargina (1/5)',
    'Lengvai vargina (2/5)',
    'Vidutiniškai vargina (3/5)',
    'Stipriai vargina (4/5)',
    'Ypatingai stipriai vargina (5/5)',
  ],
  messages: {
    intro:
      'Laba diena, <br/>' +
      'Gal galėtumėte suteikti papildomos informacijos apie patiriamus simptomus, kad galėtume detaliau įvertinti jūsų situaciją?',

    food: 'Minite, kad maistas provokuoja kai kuriuos patiriamus simptomus, gal galėtumėte plačiau apibūdinti šiuos ryšius (kokie tai maisto produktai, kiek kartų yra buvę tokie epizodai).',

    previousResearches:
      'Anketoje pažymėjote, kad jau buvo atlikti alergijos tyrimai, gal galėtumėte pasidalinti tyrimo rezultatais, kad kuo informatyviau galėtume įvertinti simptomų ir rezultatų ryšį.',

    skin: 'Anketoje minite, kad patiriate odos simptomus. Gal turite jų nuotraukų ir galėtumėte atsiųsti?',

    bloodPressure:
      'Galite apibūdinti situaciją, kai jums pasireiškė staigus kraujospūdžio kritimas/šokas? Ar buvote pilnai praradę sąmonę? Taip pat ar buvo suteikta medicinė pagalba?',

    beesWasps: 'Galite plačiau papasakoti, kaip reaguojate į vapsvos ar bitės įkandimą?',

    researchResultsEmail: 'Tyrimų rezultatus galite atsiųsti į info@allergomedica.lt paštą.',

    researchResultsEmailAndPhotos: 'Tyrimų rezultatus ir nuotraukas galite atsiųsti į info@allergomedica.lt paštą.',

    photos: 'Nuotraukas galite atsiųsti į info@allergomedica.lt paštą.',

    thanks: 'Ačiū',
  },
  filter: {
    importStatus: 'Blood Import status',
    product: 'Product',
    startDate: 'Start date',
    endDate: 'End date',
    clearFilter: 'Clear Filter',
    all: '--all--',
    orderStatus: 'Order Status',
    textSearch: 'Text search',
    treatmentStatus: 'Treatment status',
    site: 'Site',
  },
  treatment: {
    selectDrugs: 'Select Drugs',
  },
  clinic: {
    invite: {
      title: '"At the moment, you don\'t have any patients."',
      text: 'Invite your first patient',
      button: 'Invite',
      modal: {
        title: 'Invite the patient"',
        text: 'The patient will receive an invitation to fill out the questionnaire via SMS.',
        subtext:
          'You can invite more than one patient by separating their numbers with a comma. For example: tel, tel2.',
        placeholder: 'Phone number',
        success: 'The survey has been successfully sent.',
        incorrectPhoneNumber: 'Wrong phone number',
      },
    },
    descriptionTitle: 'The description of medical history',
    patient: 'Patient',
    age: 'Age',
    filesUploaded: 'Uploaded files',

    answers: {
      foodsThatProvokeSymptoms: 'Food products that provoke symptoms',
      drugsThatProvokeSymptoms: 'Medications that provoke symptoms',
      noAnswers: '"Have been before, so didn\'t fill out the questionnaire.',
    },

    patientsList: {
      title: 'Patients',
      patient: 'Patient',
      status: 'Status',
      edit: 'Edit',
      phone: 'Phone',
      updatedAt: 'Date of status change',
      createdAt: 'Created at',
      questionnaireStatus: {
        invited: 'Invitation',
        filled: 'Filled',
      },
    },

    button: {
      invite: 'Invite',
      cancel: 'Cancel',
      add: 'Invite',
    },
    search: {
      noResults: 'There are no results matching your search request.',
    },
  },
  pageNotFound: {
    title: 'Page not found',
    description: 'Check the page address and try again.',
    goHome: 'Return to the Home page',
  },
  questionnaireAnswer: {
    goal: 'Goal',
    tests: 'Tests',
    relatives: 'Allergic relatives',
    animals: 'Animals',
  },
  search: {
    noResults: 'No data matching your search.',
  },
  order: {
    table: {
      symptoms: 'Symptoms',
      duration: 'Duration',
      circumstances: 'Circumstances',
      strength: 'Strength',
      periodicity: 'Periodicity',
      appearedTimes: 'Appeared x times',
    },
  },
};
