import React, { useRef } from 'react';
import { Dialog } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';

interface ComponentProps {
  title: string;
  description?: string;
  buttonConfirm?: string;
  buttonCancel?: string;
  open: boolean;
  cancelled: () => void;
  confirmed: () => void;
}

function CommonConfirmationModal({
  title,
  buttonConfirm,
  buttonCancel,
  description,
  confirmed,
  cancelled,
  open,
}: ComponentProps) {
  const cancelButtonRef = useRef(null);

  const onConfirm = () => {
    confirmed();
  };

  const onCancel = () => {
    cancelled();
  };

  return (
    <Dialog
      as="div"
      className="fixed z-999 inset-0 overflow-y-auto"
      open={open}
      initialFocus={cancelButtonRef}
      onClose={onCancel}
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <div>
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
              <ExclamationIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                {title}
              </Dialog.Title>
              {description && (
                <div className="mt-2">
                  <p className="text-sm text-gray-500">{description}</p>
                </div>
              )}
            </div>
          </div>
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => onConfirm()}
            >
              {buttonConfirm}
            </button>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
              onClick={() => onCancel()}
              ref={cancelButtonRef}
            >
              {buttonCancel}
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

CommonConfirmationModal.defaultProps = {
  description: '',
  buttonConfirm: 'Yes',
  buttonCancel: 'No',
};

export default CommonConfirmationModal;
