import React, { useEffect, useState } from 'react';
import Select, { OnChangeValue } from 'react-select';

import { strings } from '../../localization/strings';
import { useAuth } from '../../hooks/use-auth';
import { SourceGroup } from '../../entities/source-group';
import * as symptomsService from '../../services/api/symptoms';
import * as sourceGroupsService from '../../services/api/source-groups';
import { Symptom } from '../../entities/symptom';
import CommonAnimatedSpinner from '../common/animated/spinner';

interface ComponentProps {
  items: SourceGroup[];
}

export default function SourcesGroupsTableComponent({ items }: ComponentProps) {
  const { token } = useAuth();
  const [symptoms, setSymptoms] = useState<Symptom[]>([]);

  useEffect(() => {
    const fetchSymptoms = async () => {
      const fetchedSymptoms = await symptomsService.list(token);

      setSymptoms(fetchedSymptoms);
    };

    fetchSymptoms();
  }, [token]);

  const onChangeSymptoms = async (sourceGroup: SourceGroup, event: OnChangeValue<Symptom, true>) => {
    const ids = event.map((s) => s.id);

    await sourceGroupsService.updateSymptoms(token, sourceGroup.id, ids);
  };

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    ID
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Title
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Symptoms
                  </th>
                </tr>
              </thead>
              <tbody>
                {items.map((source, index) => (
                  <tr key={source.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{source.id}</td>
                    <td className="px-6 py-1 whitespace-nowrap text-sm">{source.title}</td>
                    <td className="px-6 py-2 whitespace-nowrap text-sm">
                      {!symptoms && <CommonAnimatedSpinner sm />}
                      {symptoms && symptoms.length && (
                        <Select
                          options={symptoms}
                          isClearable
                          isMulti
                          menuPlacement={index > items.length / 2 ? 'top' : 'bottom'}
                          placeholder={strings.sources.selectSymptom}
                          defaultValue={[...symptoms.filter((s) => source.symptomIds.indexOf(s.id) !== -1)]}
                          getOptionValue={(option: Symptom) => `${option.id}`}
                          getOptionLabel={(option: Symptom) => `${option.title}`}
                          onChange={(event) => onChangeSymptoms(source, event)}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
