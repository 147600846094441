import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactDOM from 'react-dom';

import CommonAnimatedLoader from '../../../components/common/animated/loader';
import { useAuth } from '../../../hooks/use-auth';
import { getPatient, getPatientFiles } from '../../../services/api/patients';
import PatientProps from '../../../entities/patient';
import QuestionnaireAnswerComponent from '../../../components/order/questionnaire-answer';
import { OrderView } from '../../../entities/order-view';
import AlertPrimary from '../../../components/common/alerts/alert-primary';
import { viewAnswer } from '../../../services/api/answers';
import { strings } from '../../../localization/strings';
import CKEditorInput from '../../../components/common/form/ckeditor';
import PatientFile from '../../../entities/patientFile';
import ModalContent from '../../../components/modals/content';
import CommonButtonDefault from '../../../components/common/buttons/default';
import { useModal } from '../../../hooks/use-modal';

function PatientClinicPageComponent() {
  const { hash } = useParams();
  const { token } = useAuth();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();

  const [patient, setPatient] = useState<PatientProps>();
  const [patientFiles, setPatientFiles] = useState<PatientFile[]>();
  const [answer, setAnswer] = useState<OrderView>();
  const { setVisible } = useModal();

  // const [screenHeight, setScreenHeight] = useState<string>(`${(window.innerHeight - 260).toString()}px`);
  // const updateDimensions = () => {
  //   setScreenHeight(`${(window.innerHeight - 260).toString()}px`);
  // };
  //
  // useEffect(() => {
  //   window.addEventListener('resize', updateDimensions);
  //
  //   return () => window.removeEventListener('resize', updateDimensions);
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (hash) {
        try {
          const res = await getPatient(token, hash);

          const pFiles = await getPatientFiles(token, hash);

          let questionnaireAnswer: OrderView;

          if (res && res.patient.answerHash) {
            questionnaireAnswer = await viewAnswer(token, res.patient.answerHash);
          }

          ReactDOM.unstable_batchedUpdates(() => {
            if (pFiles) {
              setPatientFiles(pFiles);
            }

            if (res) {
              setPatient(res.patient);
            }

            setAnswer(questionnaireAnswer);

            setIsLoading(false);
          });
        } catch (e) {
          setIsLoading(false);
          setError((e as Error).message);
        }
      }
    };

    fetchData();
  }, [token, hash]);

  // if (window.performance) {
  //   if (performance.navigation.type === 1) {
  //     console.log('This page is reloaded');
  //   } else {
  //     console.log('This page is not reloaded');
  //   }
  // }

  const onOpenModal = (key: string) => {
    setVisible(key, true);
  };

  return (
    <div>
      {isLoading && <CommonAnimatedLoader />}
      {error && (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 my-5">
          <AlertPrimary text={error} />
        </div>
      )}
      {answer && (
        <div>
          <div className="py-5 relative bg-white border-b border-gray-200 px-4 sm:px-6 lg:px-8">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {strings.clinic.patient}:{' '}
              <b>
                {patient?.name} {answer.values.lastNameChild} {answer.values.lastName}{' '}
              </b>
              {strings.clinic.age}: <b>{answer.values.age ? answer.values.age : '-'}</b>
            </h3>
          </div>

          <div className="xl:flex xl:space-x-3 mb-5 pb-5 full-width px-4 sm:px-6 lg:px-8 pt-5">
            <div className="w-full xl:w-3/5">
              <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                {answer?.questionnaires && (
                  <QuestionnaireAnswerComponent questionnaires={answer.questionnaires} values={answer.values} />
                )}
              </div>
            </div>

            <div className="w-full xl:w-2/5 xl:mt-0">
              {answer?.questionnaires && (
                <div className="bg-white shadow overflow-hidden sm:rounded-lg p-10">
                  <CKEditorInput value={answer.anamnesisText} onChange={(value) => value} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {patientFiles && patientFiles.length > 0 && (
        <div className="w-full xxl:w-3/5 mb-5 px-4 sm:px-6 lg:px-8 mt-4">
          <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">{strings.clinic.filesUploaded}:</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4">
            {patientFiles.map((file) => (
              <div key={file.id}>
                <CommonButtonDefault
                  onClick={() => {
                    onOpenModal(`modal-${file.id}`);
                  }}
                  link
                  data-modal={`modal-${file.id}`}
                >
                  <div className="text-sm">{file.fileName}</div>
                </CommonButtonDefault>

                {file.fileExtension !== 'pdf' && (
                  <ModalContent
                    onCloseClick={() => setVisible(`modal-${file.id}`, false)}
                    type={`modal-${file.id}`}
                    title={file.fileName}
                  >
                    <div className="pb-4 max-h-full relative">
                      <img
                        style={{ maxHeight: 'calc(100vh - 150px)' }}
                        className="max-w-full"
                        src={file.viewUrl}
                        alt={file.fileName}
                      />
                    </div>
                  </ModalContent>
                )}
                {file.fileExtension === 'pdf' && (
                  <ModalContent
                    fullHeight
                    onCloseClick={() => setVisible(`modal-${file.id}`, false)}
                    type={`modal-${file.id}`}
                    title={file.fileName}
                  >
                    <div className="h-full w-full">
                      <iframe title={file.fileName} src={file.viewUrl} height="100%" width="100%" />
                    </div>
                  </ModalContent>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default PatientClinicPageComponent;
