import axios from 'axios';

import { parameters } from '../../constants/parameters';
import { StatusResponse } from '../../entities/status-response';
import { Message } from '../../entities/message';

export const sendMessage = async (token: string, message: Partial<Message>): Promise<StatusResponse> => {
  const url = `${parameters.api.baseUrl}/messages/send-admin`;

  const result = await axios.post(url, message, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return result.data;
};

export const updateMessage = async (token: string, messageId: string, message: string): Promise<StatusResponse> => {
  const url = `${parameters.api.baseUrl}/messages/update`;

  const result = await axios.post(
    url,
    {
      messageId,
      message,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return result.data;
};

export const deleteMessage = async (token: string, messageId: string): Promise<StatusResponse> => {
  const url = `${parameters.api.baseUrl}/messages/delete/${messageId}`;

  const result = await axios.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return result.data;
};

export const getByCustomer = async (token: string, customerId: number, lastMessageId?: string): Promise<Message[]> => {
  const url = `${parameters.api.baseUrl}/messages/by-customer/${customerId}`;

  const result = await axios.get(url, {
    params: {
      lastMessageId,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return result.data;
};

export const getMessage = async (token: string, messageId: string): Promise<Message> => {
  const url = `${parameters.api.baseUrl}/messages/get/${messageId}`;

  const result = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return result.data;
};
