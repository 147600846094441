import React, { createContext, useState, useEffect, useCallback, useMemo, PropsWithChildren } from 'react';

import { NoExtraProps } from '../entities/no-extra-props';
import * as ordersService from '../services/api/orders';
import { OrderView, OrderViewSource, OrderViewSourceGroup } from '../entities/order-view';
import { useAuth } from '../hooks/use-auth';
import { Order } from '../entities/order';

interface ContextValue {
  order?: OrderView;
  setOrderId: (id: number) => void;
  updateOrder: (order: Order) => void;
  updateSourceGroups: (sourceGroups: OrderViewSourceGroup[]) => void;
  updateSourceGroup: (sourceGroup: OrderViewSourceGroup, data: Partial<OrderViewSourceGroup>) => void;
  updateSource: (sourceGroup: OrderViewSourceGroup, source: OrderViewSource, data: Partial<OrderViewSource>) => void;
  removeSourceGroup: (sourceGroup: OrderViewSourceGroup) => void;
  removeSource: (sourceGroup: OrderViewSourceGroup, source: OrderViewSource) => void;
  addSourceGroup: (sourceGroup: OrderViewSourceGroup) => void;
  addSource: (sourceGroup: OrderViewSourceGroup, source: OrderViewSource) => void;
}

const OrderContext = createContext<ContextValue>({
  order: undefined,
  setOrderId: () => null,
  updateOrder: () => null,
  updateSourceGroups: () => null,
  updateSourceGroup: () => null,
  updateSource: () => null,
  removeSourceGroup: () => null,
  removeSource: () => null,
  addSourceGroup: () => null,
  addSource: () => null,
});

function OrderContextProvider({ children }: PropsWithChildren<NoExtraProps>) {
  const [orderId, setOrderId] = useState<number>();
  const [order, setOrder] = useState<OrderView>();
  const { token } = useAuth();

  useEffect(() => {
    const fetchData = async (id: number) => {
      const item = await ordersService.view(token, id);

      setOrder(item);
    };

    if (orderId && !order) {
      fetchData(orderId);
    }
  }, [token, order, orderId, setOrder]);

  const updateOrder = useCallback(
    (newOrder: Order) => {
      if (order) {
        setOrder({ ...order, order: newOrder });
      }
    },
    [order],
  );

  const updateSourceGroups = useCallback(
    (sourceGroups: OrderViewSourceGroup[]) => {
      if (order) {
        setOrder({ ...order, sourceGroups });
      }
    },
    [order],
  );

  const updateSourceGroup = useCallback(
    (sourceGroup: OrderViewSourceGroup, data: Partial<OrderViewSourceGroup>) => {
      if (order) {
        const idx = order.sourceGroups.indexOf(sourceGroup);

        order.sourceGroups[idx] = { ...order.sourceGroups[idx], ...data };

        if (idx !== -1) {
          setOrder({
            ...order,
          });
        }
      }
    },
    [setOrder, order],
  );

  const updateSource = useCallback(
    (sourceGroup: OrderViewSourceGroup, source: OrderViewSource, data: Partial<OrderViewSource>) => {
      if (order) {
        const groupIndex = order.sourceGroups.indexOf(sourceGroup);
        const sourceIndex = order.sourceGroups[groupIndex].sources.indexOf(source);

        order.sourceGroups[groupIndex].sources[sourceIndex] = {
          ...order.sourceGroups[groupIndex].sources[sourceIndex],
          ...data,
        };

        setOrder({
          ...order,
        });
      }
    },
    [setOrder, order],
  );

  const removeSource = useCallback(
    (sourceGroup: OrderViewSourceGroup, source: OrderViewSource) => {
      if (order) {
        const groupIndex = order.sourceGroups.indexOf(sourceGroup);
        const sourceIndex = order.sourceGroups[groupIndex].sources.indexOf(source);

        order.sourceGroups[groupIndex].sources.splice(sourceIndex, 1);

        setOrder({ ...order });
      }
    },
    [order, setOrder],
  );

  const removeSourceGroup = useCallback(
    (sourceGroup: OrderViewSourceGroup) => {
      if (order) {
        const groupIndex = order.sourceGroups.indexOf(sourceGroup);

        order.sourceGroups.splice(groupIndex, 1);

        setOrder({ ...order });
      }
    },
    [order, setOrder],
  );

  const addSourceGroup = useCallback(
    (sourceGroup: OrderViewSourceGroup) => {
      if (order) {
        order.sourceGroups.push(sourceGroup);

        setOrder({ ...order });
      }
    },
    [order, setOrder],
  );

  const addSource = useCallback(
    (sourceGroup: OrderViewSourceGroup, source: OrderViewSource) => {
      if (order) {
        const groupIndex = order.sourceGroups.indexOf(sourceGroup);

        order.sourceGroups[groupIndex].sources.push(source);

        setOrder({ ...order });
      }
    },
    [order, setOrder],
  );

  const contextValue = useMemo(
    () => ({
      order,
      setOrderId,
      updateOrder,
      updateSourceGroup,
      updateSourceGroups,
      updateSource,
      removeSourceGroup,
      removeSource,
      addSourceGroup,
      addSource,
    }),
    [
      order,
      updateSource,
      updateOrder,
      updateSourceGroup,
      updateSourceGroups,
      removeSource,
      removeSourceGroup,
      addSourceGroup,
      addSource,
      setOrderId,
    ],
  );

  return <OrderContext.Provider value={contextValue}>{children}</OrderContext.Provider>;
}

export { OrderContext, OrderContextProvider };
