import React from 'react';
import { useRoutes, Outlet, Navigate } from 'react-router-dom';

import { useAuth } from '../../hooks/use-auth';
import UsersLoginPageComponent from '../users/login';
import OrdersListPageComponent from '../orders/list';
import OrdersResultsQuestionnairePageComponent from '../orders/results-questionnaire';
import LayoutHeadingComponent from '../../components/layout/heading';
import CommonAnimatedLoader from '../../components/common/animated/loader';
import OrdersResultsAnswerPageComponent from '../orders/results-answer';
import OrdersResultsPageComponent from '../orders/results';
import { OrderContextProvider } from '../../context/order';
import OrdersResultsCommentsPageComponent from '../orders/results-comments';
import OrdersResultsBloodPageComponent from '../orders/results-blood';
import OrdersResultsRecommendationsPageComponent from '../orders/results-recommendations';
import SourcesListPageComponent from '../sources/list';
import RecommendationsListPageComponent from '../recommendations/list';
import SourcesGroupsListPageComponent from '../source-groups/list';
import CouponsListPageComponent from '../coupons/list';
import CouponsFormPageComponent from '../coupons/form';
import MessagesCustomerPageComponent from '../messages/customer';
import MessagesIndexPageComponent from '../messages/index';
import MessagesNoCustomerPageComponent from '../messages/no-customer';
import FeedbackAnswersPageComponent from '../feedback/answers';
import { CustomersContextProvider } from '../../context/customers';
import RecommendationsFormPageComponent from '../recommendations/form';
import PatientsListPageComponent from '../patients/list';
import PatientsPreviewPageComponent from '../patients/preview';
import TreatmentFormPageComponent from '../treatment/form';
import ClinicPatientsPageComponent from '../clinic/patients/list';
import PatientClinicPageComponent from '../clinic/patients/preview';
import NotFoundPageComponent from '../text/not-found';

export default function Root() {
  const { user, loading } = useAuth();

  const isLoggedIn = !loading && user;
  const isClinic = !loading && user && user.isClinicUser;

  const element = useRoutes([
    {
      path: '/',
      element: !isLoggedIn ? <Navigate to="/login" /> : <Outlet />,
      children: [
        {
          path: '/',
          element: !isClinic ? <Navigate to="/orders" /> : <Navigate to="/clinic-patients" />,
        },
        {
          path: 'orders',
          element: !isClinic && <OrdersListPageComponent />,
        },
        {
          path: 'patients',
          element: !isClinic && <Outlet />,
          children: [
            {
              path: '',
              element: <PatientsListPageComponent />,
            },
            {
              path: 'preview/:hash',
              element: <PatientsPreviewPageComponent />,
            },
          ],
        },
        {
          path: 'clinic-patients',
          element: isClinic && <Outlet />,
          children: [
            {
              path: '',
              element: <ClinicPatientsPageComponent />,
            },
            {
              path: 'preview/:hash',
              element: <PatientClinicPageComponent />,
            },
          ],
        },
        {
          path: 'treatment',
          element: !isClinic && <Outlet />,
          children: [
            {
              path: 'create/:patientId/:patientHash',
              element: <TreatmentFormPageComponent />,
            },
            {
              path: 'edit/:treatmentId/:patientHash/',
              element: <TreatmentFormPageComponent isEdit />,
            },
          ],
        },
        {
          path: 'sources',
          element: !isClinic && <SourcesListPageComponent />,
        },
        {
          path: 'source-groups',
          element: !isClinic && <SourcesGroupsListPageComponent />,
        },
        {
          path: 'recommendations',
          element: !isClinic && <Outlet />,
          children: [
            {
              path: '',
              element: <RecommendationsListPageComponent />,
            },
            {
              path: 'create',
              element: <RecommendationsFormPageComponent />,
            },
            {
              path: 'edit/:id',
              element: <RecommendationsFormPageComponent isEdit />,
            },
          ],
        },
        {
          path: 'feedback',
          element: !isClinic && <FeedbackAnswersPageComponent />,
        },
        {
          path: 'coupons',
          element: !isClinic && <Outlet />,
          children: [
            {
              path: '',
              element: <CouponsListPageComponent />,
            },
            {
              path: 'create',
              element: <CouponsFormPageComponent />,
            },
            {
              path: 'edit/:id',
              element: <CouponsFormPageComponent isEdit />,
            },
          ],
        },
        {
          path: 'messages',
          element: !isClinic && (
            <CustomersContextProvider>
              <MessagesIndexPageComponent />
            </CustomersContextProvider>
          ),
          children: [
            {
              path: '',
              element: !isClinic && <MessagesNoCustomerPageComponent />,
            },
            {
              path: ':id',
              element: !isClinic && <MessagesCustomerPageComponent />,
            },
          ],
        },
        {
          path: 'orders/results/:id',
          element: !isClinic && (
            <OrderContextProvider>
              <OrdersResultsPageComponent />
            </OrderContextProvider>
          ),
          children: [
            {
              path: '',
              element: <OrdersResultsQuestionnairePageComponent />,
            },
            {
              path: 'answer',
              element: <OrdersResultsAnswerPageComponent />,
            },
            {
              path: 'comments',
              element: <OrdersResultsCommentsPageComponent />,
            },
            {
              path: 'recommendations',
              element: <OrdersResultsRecommendationsPageComponent />,
            },
            {
              path: 'blood',
              element: <OrdersResultsBloodPageComponent />,
            },
          ],
        },
      ],
    },
    {
      path: '/login',
      element: isLoggedIn ? <Navigate to="/" /> : <UsersLoginPageComponent />,
    },
  ]);

  return (
    <div>
      {loading && <CommonAnimatedLoader />}
      {!loading && user && <LayoutHeadingComponent />}
      {!loading && (element || <NotFoundPageComponent />)}
    </div>
  );
}
