import React from 'react';
import tw, { styled } from 'twin.macro';

import { Symptom } from '../../entities/order-view';
import { strings } from '../../localization/strings';

interface ComponentProps {
  title: string;
  groupId: number;
  symptoms: Array<Symptom>;
}

const StyledTh = styled.div`
  ${tw`py-2 lg:px-3 lg:border-b lg:border-gray-200 text-left text-sm font-semibold text-gray-900 `}
`;

const StyledTd = styled.div`
  ${tw`px-3 py-2 xl:py-4 text-sm`}
`;

const Strength = styled.div<{ duration: string }>`
  ${tw`bg-success-50 absolute left-0 top-0 h-full border-r-4`}
  ${(props) => props.duration === '3' && tw`w-full border-r-8`}
  ${(props) => props.duration === '2' && tw`w-2/3`}
  ${(props) => props.duration === '1' && tw`w-1/3`}
    ${(props) => props.duration === '0.5' && tw`w-1/5`}
`;

const StrengthIntensity = styled.div<{ intensity: number }>`
  ${tw`absolute left-0 top-0 h-full`}
  ${(props) => props.intensity === 5 && tw`w-full bg-intensityVeryHigh`}
  ${(props) => props.intensity === 4 && tw`w-4/5 bg-intensityHigh`}
  ${(props) => props.intensity === 3 && tw`w-3/5 bg-intensityMedium`}
  ${(props) => props.intensity === 2 && tw`w-2/5 bg-intensityLow`}
  ${(props) => props.intensity === 1 && tw`w-1/5 bg-success-50`}
`;

const StrengthCol = styled.div`
  ${tw`h-full w-1/3 z-10 border-r-2 border-white`}
`;

function SymptomTableComponent({ title, groupId, symptoms }: ComponentProps) {
  return (
    <div className="px-4 md:px-6">
      <h3 className="text-lg leading-6 font-medium text-gray-900 mb-3">
        {groupId === 1 && <span className="inline-flex mr-2">&#x1F443;</span>}
        {groupId === 2 && <span className="inline-flex mr-2">&#x1F444;</span>}
        {/* oda/ leather 🧑 */}
        {groupId === 3 && <span className="inline-flex mr-2">&#x1F9D1;</span>}
        {groupId === 4 && <span className="inline-flex mr-2">&#x1F441;</span>}
        {/* šokas / shock ⚡ */}
        {groupId === 5 && <span className="inline-flex mr-2">&#x26A1;</span>}
        {groupId === 7 && <span className="inline-flex mr-2">&#x26A1;</span>}
        {title}
        {/* {groupId} */}
      </h3>
      <div className="overflow-hidden lg:overflow-visible shadow ring-1 ring-black ring-opacity-5 md:rounded-lg mb-12">
        <div className="hidden md:flex md:flex-1 min-w-full w-full ">
          <StyledTh className="md:w-4/12">{strings.order.table.symptoms}</StyledTh>
          <StyledTh className="md:w-2/12">{strings.order.table.duration}</StyledTh>
          <StyledTh className="md:w-2/12">
            {groupId !== 5 ? (
              <div>{strings.order.table.periodicity}</div>
            ) : (
              <div>{strings.order.table.appearedTimes}</div>
            )}
          </StyledTh>
          <StyledTh className="md:w-2/12">{strings.order.table.circumstances}</StyledTh>
          <StyledTh className="md:w-2/12">{strings.order.table.strength}</StyledTh>
        </div>
        <div className="divide-y divide-gray-200">
          {symptoms.map((symptom, i) => (
            <div className="md:flex md:flex-1 w-full" key={`symptom-${symptom.id}-${i + 1}`}>
              <StyledTd className="md:w-4/12">
                <StyledTh className="md:hidden">{strings.order.table.symptoms}</StyledTh>
                <div className="">{symptom.title}</div>
              </StyledTd>
              <StyledTd className="md:w-2/12">
                <StyledTh className="md:hidden">{strings.order.table.duration}</StyledTh>
                {symptom.duration ? (
                  symptom.duration.map((symptomDuration) => (
                    <div key={`symptomDuration-${symptom.id}`}>
                      <div className="w-[100px] h-3.5 rounded-full overflow-hidden mb-1 bg-gray-200 relative">
                        <div className="w-full flex space-x-[2px] h-full relative z-10">
                          <StrengthCol />
                          <StrengthCol />
                          <StrengthCol className="!border-0" />
                        </div>
                        {symptomDuration.title === 'Kelis mėnesius' && <Strength duration="0.5" />}
                        {symptomDuration.factorId === 1 && <Strength duration="1" />}
                        {symptomDuration.factorId === 2 && <Strength duration="2" />}
                        {symptomDuration.factorId === 3 && <Strength duration="3" />}
                      </div>
                      <div className="text-center w-[100px] text-xs">{symptomDuration.title}</div>
                    </div>
                  ))
                ) : (
                  <div>-</div>
                )}
              </StyledTd>
              <StyledTd className="md:w-2/12">
                <StyledTh className="md:hidden">
                  {symptom.id !== 7 ? (
                    <div>
                      {symptom.code} {strings.order.table.periodicity}
                    </div>
                  ) : (
                    <div>{strings.order.table.appearedTimes}</div>
                  )}
                </StyledTh>

                {symptom.quantity
                  ? symptom.quantity.map((quantity, t) => (
                      <div key={`quantity-${symptom.id}-${t + 1}`}>{quantity.title}</div>
                    ))
                  : symptom.id === 7 && <div>-</div>}
                {symptom.time
                  ? symptom.time.map((time, t) => <div key={`time-${symptom.id}-${t + 1}`}>{time.title}</div>)
                  : symptom.id !== 7 && <div>-</div>}
              </StyledTd>
              <StyledTd className="md:w-2/12">
                <StyledTh className="md:hidden">{strings.order.table.circumstances}</StyledTh>
                {symptom.circumstances ? (
                  symptom.circumstances.map((circumstance, c) => (
                    <div
                      className="flex items-center space-x-2 mb-1"
                      key={`circumstance-${symptom.id}-${circumstance.factorId}-${c + 1}`}
                    >
                      <div className="text-lg">
                        {circumstance.factorId === 17 && <>&#x2753;</>}
                        {circumstance.factorId === 16 && <>&#x1F41D;</>}
                        {circumstance.factorId === 15 && <>&#x1F48A;</>}
                        {/* kosmetika 💄 */}
                        {circumstance.factorId === 14 && <>&#x1F484;</>}
                        {circumstance.factorId === 13 && <>&#x1F34F;</>}
                        {/* Dulkėtoje patalpoje 💨 */}
                        {circumstance.factorId === 12 && <>&#x1F4A8;</>}
                        {/* animals  🐹 */}
                        {circumstance.factorId === 11 && <>&#x1F439;</>}
                        {/* pelesis ⚫ */}
                        {!circumstance.factorId && <>&#x26AB;</>}
                      </div>
                      <div className="">{circumstance.title}</div>
                    </div>
                  ))
                ) : (
                  <div>-</div>
                )}
              </StyledTd>
              <StyledTd className="md:w-2/12">
                <StyledTh className="md:hidden">{strings.order.table.strength}</StyledTh>
                {symptom.strength ? (
                  <div>
                    <div className="w-[70px] h-3.5 rounded-full overflow-hidden mb-1 bg-gray-200 relative">
                      <div className="w-full flex h-full relative z-10">
                        <StrengthCol />
                        <StrengthCol />
                        <StrengthCol />
                        <StrengthCol />
                        <StrengthCol className="!border-none" />
                      </div>
                      <StrengthIntensity intensity={parseInt(symptom.strength, 10)} />
                    </div>
                    <div className="text-center w-[70px] text-xs">
                      <b>{symptom.strength}</b> / 5
                    </div>
                  </div>
                ) : (
                  <span>-</span>
                )}
              </StyledTd>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SymptomTableComponent;
