import axios from 'axios';

import { parameters } from '../../constants/parameters';
import { FeedbackAnswer } from '../../entities/feedback-answer';

export const feedbackAnswers = async (token: string, page: number): Promise<FeedbackAnswer> => {
  const url = `${parameters.api.baseUrl}/questionnaire/feedback-answers`;

  const res = await axios.get(url, { headers: { Authorization: `Bearer ${token}` }, params: { page } });

  return res.data;
};
