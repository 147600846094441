import React, { useEffect, useState } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';

import { useAuth } from '../../hooks/use-auth';
import CommonAnimatedLoader from '../../components/common/animated/loader';
import PatientsFilter from '../../components/patients/filter';
import PatientsFilterProps from '../../entities/patientsFilter';
import PatientsTableComponent from '../../components/patients/table';
import { getPatients } from '../../services/api/patients';
import PatientProps from '../../entities/patient';

function PatientsListPageComponent() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = query.get('page');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { token } = useAuth();

  const initialSate: any = { importStatus: undefined };
  const [params, setParams] = useState<PatientsFilterProps>(initialSate);
  const [pages, setPages] = useState<number>(1);
  const [currPage, setCurrPage] = useState<number>(1);
  const [searchParams, setSearchParams] = useSearchParams();

  searchParams.forEach((value, key) => {
    initialSate[key] = value;
    if (key === 'page') {
      initialSate[key] = parseInt(value, 10);
    }
    if (key === 'startDate' || key === 'endDate') {
      initialSate[key] = new Date(value);
    }
  });

  const [patients, setPatients] = useState<PatientProps[]>([]);

  const setFilterData = (filterData: any) => {
    setParams({ ...filterData, page: currPage });
    // cleanQueryString(filterData);
    setSearchParams({ ...filterData });
  };

  const setPageNo = (currentPage: number) => {
    setCurrPage(currentPage);
    setParams({ ...params, page: currentPage });
    setSearchParams({ page: currentPage.toString() });
  };

  useEffect(() => {
    if (page) {
      setCurrPage(Number(page));
    }
  }, [page]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const items = await getPatients(token, params);

      setPatients(items.patients);
      setPages(items.pages);
      setIsLoading(false);
    };

    fetchData();
  }, [token, params, currPage]);

  return (
    <div>
      <header className="mb-3 mt-3">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold leading-tight text-gray-900">Patients</h1>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          {isLoading && <CommonAnimatedLoader />}

          <PatientsFilter onUpdate={setFilterData} filterState={params} />
          {!isLoading && patients.length === 0 && <div>There is no patients</div>}
          {!!patients.length && (
            <PatientsTableComponent patients={patients} pages={pages} onUpdate={setPageNo} currPageNo={currPage} />
          )}
        </div>
      </main>
    </div>
  );
}

export default PatientsListPageComponent;
