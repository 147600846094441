import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { CKEditor } from '@ckeditor/ckeditor5-react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

interface ComponentProps {
  value: string;
  onChange: (text: string) => void;
}

function CKEditorInput({ value, onChange }: ComponentProps) {
  // return (
  //   <textarea
  //     className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
  //     defaultValue={value}
  //     rows={4}
  //     onChange={(event) => onChange(event.target.value)}
  //   />
  // );
  //
  return (
    <CKEditor
      editor={ClassicEditor}
      data={value || ''}
      config={{
        toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList'],
      }}
      onChange={(event: any, editor: any) => {
        const data = editor.getData();

        onChange(data);
      }}
    />
  );
}

export default CKEditorInput;
