import axios from 'axios';

import { parameters } from '../../constants/parameters';
import DrugForm from '../../entities/form/drug-form';

export const drugsList = async (token: string): Promise<DrugForm[]> => {
  const url = `${parameters.api.baseUrl}/drugs/list`;

  const res = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });

  return res.data;
};

export const createDrug = async (token: string, data: { title: string }): Promise<DrugForm> => {
  const url = `${parameters.api.baseUrl}/drugs/create`;

  const res = await axios.post(
    url,
    {
      ...data,
    },
    { headers: { Authorization: `Bearer ${token}` } },
  );

  return res.data;
};
