import axios from 'axios';

import { parameters } from '../../constants/parameters';
import { StatusResponse } from '../../entities/status-response';

export const updateSymptoms = async (
  token: string,
  sourceGroupId: number,
  symptomIds: number[],
): Promise<StatusResponse> => {
  const url = `${parameters.api.baseUrl}/source-groups/update-symptoms`;

  const res = await axios.post(
    url,
    {
      id: sourceGroupId,
      symptomIds,
    },
    { headers: { Authorization: `Bearer ${token}` } },
  );

  return res.data;
};
