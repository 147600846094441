import React, { useEffect, useState } from 'react';

import { useAuth } from '../../hooks/use-auth';
import * as sourcesService from '../../services/api/sources';
import CommonAnimatedLoader from '../../components/common/animated/loader';
import { Source } from '../../entities/source';
import SourcesTableComponent from '../../components/sources/table';

function SourcesListPageComponent() {
  const { token } = useAuth();
  const [items, setItems] = useState<Source[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const sources = await sourcesService.list(token);

      setItems(sources);
    };

    fetchData();
  }, [token]);

  return (
    <div>
      <header className="mb-3 mt-3">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold leading-tight text-gray-900">Sources</h1>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          {!items.length && <CommonAnimatedLoader />}
          {!!items.length && <SourcesTableComponent items={items} />}
        </div>
      </main>
    </div>
  );
}

export default SourcesListPageComponent;
