import React from 'react';
import { useParams } from 'react-router-dom';

import { MessagesContextProvider } from '../../context/messages';
import MessagingChat from '../../components/messaging/chat';
import ScreenHeightContainer from '../../components/common/container/screen-height';

function MessagesCustomerPageComponent() {
  const { id } = useParams<{ id: string }>();

  if (!id) {
    return null;
  }

  const customerId = parseInt(id, 10);

  return (
    <MessagesContextProvider customerId={customerId}>
      <ScreenHeightContainer>
        <MessagingChat customerId={customerId} />
      </ScreenHeightContainer>
    </MessagesContextProvider>
  );
}

export default MessagesCustomerPageComponent;
