export const currency = (input: number, currencyCode: string): string => {
  let locale = 'lt';

  if (currencyCode === 'PLN') {
    locale = 'pl';
  }

  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
  });

  return formatter.format(input); /* $2,500.00 */
};
