import axios from 'axios';

import { parameters } from '../../constants/parameters';
import { Product } from '../../entities/product';

export const list = async (siteId: number): Promise<Product[]> => {
  const url = `${parameters.api.baseUrl}/products/list`;

  const res = await axios.get(url, { params: { siteId } });

  return res.data;
};
