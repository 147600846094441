import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useAuth } from '../../hooks/use-auth';
import * as recommendationsService from '../../services/api/recommendations';
import CommonAnimatedLoader from '../../components/common/animated/loader';
import { Recommendation } from '../../entities/recommendation';
import RecommendationsTableComponent from '../../components/recommendations/table';
import RecommendationsCreateModal from '../../components/recommendations/create-modal';
import CommonButtonDefault from '../../components/common/buttons/default';

function RecommendationsListPageComponent() {
  const { token } = useAuth();
  const [items, setItems] = useState<Recommendation[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const recommendations = await recommendationsService.list(token);

      setItems(recommendations);
    };

    fetchData();
  }, [token]);

  const onCreateRecommendation = (recommendation: Recommendation) => {
    setItems([recommendation, ...items]);
  };

  const onUpdateRecommendation = (prevValue: Recommendation, newValue: Recommendation) => {
    const recommendationIdx = items.indexOf(prevValue);

    items[recommendationIdx].title = newValue.title;
    items[recommendationIdx].titleAdmin = newValue.titleAdmin;
    items[recommendationIdx].longDescription = newValue.longDescription;

    setItems([...items]);
  };

  return (
    <div>
      <header className="mb-3 mt-3">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex">
            <h1 className="text-3xl font-bold leading-tight text-gray-900">Recommendations</h1>
            <Link className="ml-auto" to="/recommendations/create">
              <CommonButtonDefault primary>Create new</CommonButtonDefault>
            </Link>
            {/* <div className="ml-auto"> */}
            {/*  <RecommendationsCreateModal onCreate={(recommendation) => onCreateRecommendation(recommendation)} /> */}
            {/* </div> */}
          </div>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          {!items.length && <CommonAnimatedLoader />}
          {!!items.length && (
            <RecommendationsTableComponent onUpdateRecommendation={onUpdateRecommendation} items={items} />
          )}
        </div>
      </main>
    </div>
  );
}

export default RecommendationsListPageComponent;
