import React from 'react';
import classNames from 'classnames';

export default function OrderReviewBadge({ status }: { status: string }) {
  return (
    <span
      className={classNames(
        `inline-flex items-center px-2 py-0.5 rounded text-xs font-medium shrink-0 ${
          status !== 'voucher' && 'bg-yellow-100 text-gray-800'
        } ${status === 'voucher' && 'bg-blue-700 text-white'} `,
      )}
    >
      {status}
    </span>
  );
}
