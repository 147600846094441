import React, { useEffect, useState } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { PlusCircleIcon } from '@heroicons/react/outline';

import { useAuth } from '../../../hooks/use-auth';
import CommonAnimatedLoader from '../../../components/common/animated/loader';
import PatientsFilter from '../../../components/patients/filter';
import PatientsFilterProps from '../../../entities/patientsFilter';
import { getPatients, patientsInvite } from '../../../services/api/patients';
import ClinicPatientsTableComponent from '../../../components/patients/clinic-table';
import PatientProps from '../../../entities/patient';
import CommonButtonDefault from '../../../components/common/buttons/default';
import { strings } from '../../../localization/strings';
import ModalContent from '../../../components/modals/content';
import CommonAnimatedSpinner from '../../../components/common/animated/spinner';
import AlertPrimary from '../../../components/common/alerts/alert-primary';
import IMAGES from '../../../assets/images';

function ClinicPatientsPageComponent() {
  interface MessageForm {
    phones: string;
  }
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = query.get('page');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { token } = useAuth();

  const initialSate: any = { importStatus: undefined };
  const [params, setParams] = useState<PatientsFilterProps>(initialSate);
  const [pages, setPages] = useState<number>(1);
  const [currPage, setCurrPage] = useState<number>(1);
  const [invalidNumbers, setInvalidNumbers] = useState<string[]>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { register, handleSubmit, reset, formState } = useForm<MessageForm>({ mode: 'onChange' });

  const [patients, setPatients] = useState<PatientProps[]>([]);
  const [newPatients, setNewPatients] = useState<PatientProps[]>([]);

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  searchParams.forEach((value, key) => {
    initialSate[key] = value;
    if (key === 'page') {
      initialSate[key] = parseInt(value, 10);
    }
    if (key === 'startDate' || key === 'endDate') {
      initialSate[key] = new Date(value);
    }
  });

  const setFilterData = (filterData: any) => {
    setParams({ ...filterData, page: currPage });
    // cleanQueryString(filterData);
    setSearchParams({ ...filterData });
  };

  const setPageNo = (currentPage: number) => {
    setCurrPage(currentPage);
    setParams({ ...params, page: currentPage });
    setSearchParams({ page: currentPage.toString() });
  };

  useEffect(() => {
    if (page) {
      setCurrPage(Number(page));
    }
  }, [page]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const items = await getPatients(token, params);

      setPatients(items.patients);
      setPages(items.pages);
      setIsLoading(false);
    };

    fetchData();
  }, [token, params, currPage, newPatients]);
  const onDisplayModal = () => {
    setIsModalVisible(true);
  };

  const onSubmit: SubmitHandler<MessageForm> = async (data) => {
    try {
      setIsLoading(true);

      const phonesForm = data.phones.replace(/\s/g, '');

      const res = await patientsInvite(token, phonesForm.split(','));

      if (res) {
        if (res.patients) {
          setNewPatients(res.patients);

          reset({
            phones: '',
          });
        }
        if (res.invalidNumbers) {
          setInvalidNumbers(res.invalidNumbers);
        }
        setIsLoading(false);
      }
    } catch (e) {
      setError((e as Error).message);
    }
  };

  return (
    <main>
      {isLoading && <CommonAnimatedLoader />}
      {!isLoading && patients.length <= 0 && !searchParams ? (
        <div className="mx-auto sm:px-3 my-5 w-72 text-center">
          <div className="relative">
            <img className="w-auto" src={IMAGES.CLINIC.EMPTYLIST} alt="" />
            <h1 className="text-xl absolute bottom-7 font-bold">{strings.clinic.invite.title}</h1>
          </div>
          <CommonButtonDefault
            primary
            onClick={() => onDisplayModal()}
            className="w-full md:w-auto mx-auto !inline-flex items-center"
          >
            <PlusCircleIcon className="mr-1 h-5 w-5 text-white" aria-hidden="true" />

            <span>{strings.clinic.invite.button}</span>
          </CommonButtonDefault>
          <div className="mt-3">{strings.clinic.invite.text}</div>
        </div>
      ) : (
        <>
          <header className="mb-3 mt-3">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between space-x-3 items-center">
              <h1 className="text-3xl font-bold leading-tight text-gray-900">{strings.clinic.patientsList.title}</h1>
              <div>
                <CommonButtonDefault
                  primary
                  onClick={() => onDisplayModal()}
                  className="w-full md:w-auto mx-auto !inline-flex items-center"
                >
                  <PlusCircleIcon className="mr-1 h-5 w-5 text-white" aria-hidden="true" />

                  <span>{strings.clinic.button.add}</span>
                </CommonButtonDefault>
              </div>
            </div>
          </header>

          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <PatientsFilter isClinic onUpdate={setFilterData} filterState={params} />
            {patients.length > 0 ? (
              <ClinicPatientsTableComponent
                patients={patients}
                pages={pages}
                onUpdate={setPageNo}
                currPageNo={currPage}
              />
            ) : (
              searchParams && <div className="mx-auto sm:px-3 my-5 text-center">{strings.clinic.search.noResults}</div>
            )}
          </div>
        </>
      )}

      {isModalVisible && (
        <ModalContent
          visible
          onCloseClick={() => setIsModalVisible(false)}
          title={newPatients.length > 0 ? '' : strings.clinic.invite.modal.title}
        >
          <div>
            {newPatients.length > 0 ? (
              <div className="mb-4">
                <img className="w-auto mb-3" src={IMAGES.CLINIC.SUCCESS} alt="" />
                <h3 className="font-bold text-2xl xl:w-1/5">{strings.clinic.invite.modal.success}</h3>
              </div>
            ) : (
              <div className="mb-4">{strings.clinic.invite.modal.text}</div>
            )}

            {invalidNumbers && invalidNumbers.length > 0 && (
              <div className="mb-4">
                <AlertPrimary
                  type="danger"
                  title={strings.clinic.invite.modal.incorrectPhoneNumber}
                  text={invalidNumbers.join(', ')}
                />
              </div>
            )}

            {newPatients.length < 1 && (
              <>
                <textarea
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  {...register('phones')}
                  placeholder={strings.clinic.invite.modal.placeholder}
                  id="phones"
                />

                <div className="mt-2 text-gray-500 text-sm">{strings.clinic.invite.modal.subtext}</div>
              </>
            )}
            <div className="pt-5">
              <div className="flex">
                {newPatients.length < 1 ? (
                  <button
                    type="button"
                    disabled={!formState.isDirty}
                    onClick={handleSubmit(onSubmit)}
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
                  >
                    {strings.clinic.button.invite}
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => setNewPatients([])}
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {strings.clinic.button.invite}
                  </button>
                )}
                <button
                  onClick={() => setIsModalVisible(false)}
                  type="button"
                  className="ml-3 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {strings.clinic.button.cancel}
                </button>
                {isLoading && (
                  <div className="mt-2 pl-3">
                    <CommonAnimatedSpinner sm />
                  </div>
                )}
              </div>
            </div>
          </div>
        </ModalContent>
      )}
    </main>
  );
}

export default ClinicPatientsPageComponent;
