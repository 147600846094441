import React, { useState } from 'react';
import Select from 'react-select';
import tw, { styled } from 'twin.macro';

import { strings } from '../../localization/strings';
import CommonButtonDefault from '../common/buttons/default';
import OrdersFilterProps from '../../entities/ordersFilter';
import { OrderImportStatus, OrderStatus } from '../../entities/order';

interface ComponentProps {
  filterState: OrdersFilterProps;
  onUpdate: (filter: OrdersFilterProps) => void;
}

const StyledBlock = styled.div`
  ${tw`items-center flex`};
`;
const StyledLabel = styled.label`
  ${tw`inline-block text-sm text-gray-500 mb-1`};
`;

export default function OrdersFilter({ filterState, onUpdate }: ComponentProps) {
  const initialState = {
    orderStatus: filterState.orderStatus && filterState ? filterState.orderStatus : 'all',
    importStatus: filterState.importStatus ? filterState.importStatus : OrderImportStatus.NoImport,
    textSearch: filterState.textSearch || '',
    siteId: filterState.siteId || '',
    // startDate: filterState?.startDate || new Date(),
    // endDate: filterState?.endDate || new Date(),
  };

  const emptyState = {
    orderStatus: 'all',
    importStatus: OrderImportStatus.NoImport,
    page: 1,
    textSearch: '',
    siteId: '',
  };

  const importStatusOptions = [
    { value: OrderImportStatus.NoImport, label: 'All' },
    { value: 'empty', label: 'No import' },
    { value: OrderImportStatus.Completed, label: 'Completed' },
  ];

  const orderStatusOptions = [
    { value: 'all', label: 'All' },
    { value: OrderStatus.NotCompleted, label: 'Not completed' },
    { value: OrderStatus.Completed, label: 'Completed' },
  ];

  const productOptions = [
    { value: 'all', label: 'All' },
    { value: 'SCREENER', label: 'Screener' },
    { value: 'FREE_SCREENER', label: 'Free Screener' },
    { value: 'FULL', label: 'Full' },
  ];

  const siteOptions = [
    { value: '', label: 'All' },
    { value: '1', label: 'LT - allergomedica.lt' },
    { value: '2', label: 'PL - allergomedica.pl' },
    { value: '3', label: 'LT - foodid.lt' },
    { value: '4', label: 'PL - foodid.pl' },
  ];

  const [filter, setFilter] = useState<OrdersFilterProps>(initialState);
  // const [startDate, setStartDate] = useState(initialState.startDate);
  // const [endDate, setEndDate] = useState(initialState.endDate);

  const onImportStatusChange = (event: any) => {
    setFilter({ ...filter, importStatus: event.value });
    onUpdate({ ...filter, importStatus: event.value });
  };

  const onOrderStatusChange = (event: any) => {
    setFilter({ ...filter, orderStatus: event.value });
    onUpdate({ ...filter, orderStatus: event.value });
  };

  const onSiteChange = (event: any) => {
    setFilter({ ...filter, siteId: event.value });
    onUpdate({ ...filter, siteId: event.value });
  };
  //
  // const onStartDateChange = (date: any) => {
  //   setStartDate(date);
  //
  //   date.setHours(0, 0, 0);
  //   endDate.setHours(23, 59, 0);
  //   setFilter({ ...filter, startDate: date, endDate });
  //   onUpdate({ ...filter, startDate: date, endDate });
  // };
  //
  // const onEndDateChange = (date: any) => {
  //   const end = date;
  //
  //   setEndDate(end);
  //   end.setHours(23, 59, 0);
  //
  //   setFilter({ ...filter, endDate: end });
  //   onUpdate({ ...filter, endDate: end });
  // };
  //
  const onTextSearchChange = (e: any) => {
    setFilter({ ...filter, textSearch: e.target.value });
    onUpdate({ ...filter, textSearch: e.target.value });
  };

  const clearFilter = () => {
    // setStartDate(new Date());
    // setEndDate(new Date());
    setFilter(emptyState);
    onUpdate(emptyState);
  };

  return (
    <div className="mb-4 bg-white w-full shadow sm:rounded-lg">
      <div className="flex flex-wrap space-y-2 lg:space-y-0 px-4 py-4 space-x-4 items items-stretch">
        <StyledBlock className="w-72">
          <div className="w-full">
            <StyledLabel>{strings.filter.textSearch}:</StyledLabel>
            <input
              id="search"
              name="search"
              className="block w-full bg-white border border-gray-300 rounded-md py-2 px-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-rose-500 focus:border-rose-500 sm:text-sm"
              placeholder="Search"
              type="search"
              onChange={(event) => onTextSearchChange(event)}
              value={filter.textSearch}
            />
          </div>
        </StyledBlock>
        <StyledBlock>
          <div>
            <StyledLabel htmlFor="orderStatus">{strings.filter.orderStatus}:</StyledLabel>

            <Select
              className="w-56 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              options={orderStatusOptions}
              value={orderStatusOptions.filter(function (option) {
                return option.value === filter.orderStatus;
              })}
              onChange={(event) => onOrderStatusChange(event)}
            />
          </div>
        </StyledBlock>

        <StyledBlock>
          <div>
            <StyledLabel htmlFor="importStatus">{strings.filter.importStatus}:</StyledLabel>

            <Select
              className="w-56 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              options={importStatusOptions}
              value={importStatusOptions.filter(function (option) {
                return option.value === filter.importStatus;
              })}
              onChange={(event) => onImportStatusChange(event)}
            />
          </div>
        </StyledBlock>
        <StyledBlock>
          <div>
            <StyledLabel htmlFor="importStatus">{strings.filter.site}:</StyledLabel>

            <Select
              className="w-56 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              options={siteOptions}
              value={siteOptions.filter(function (option) {
                return option.value === filter.siteId;
              })}
              onChange={(event) => onSiteChange(event)}
            />
          </div>
        </StyledBlock>
        <StyledBlock className="!items-end">
          <CommonButtonDefault onClick={clearFilter} className="w-full md:w-auto inline-flex">
            {strings.filter.clearFilter}
          </CommonButtonDefault>
        </StyledBlock>
      </div>
    </div>
  );
}
