import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { useAuth } from '../../hooks/use-auth';
import * as questionnaireService from '../../services/api/questionnaire';
import CommonAnimatedLoader from '../../components/common/animated/loader';
import QuestionnaireFeedbackTableComponent from '../../components/questionnaire/feedback-table';
import { FeedbackValues } from '../../entities/feedback-answer';

function FeedbackAnswersPageComponent() {
  const { token } = useAuth();
  const [values, setValues] = useState<FeedbackValues[]>([]);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = query.get('page');
  const [pages, setPages] = useState<number>(1);
  const [currPage, setCurrPage] = useState<number>(Number(page) || 1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (page) {
      setCurrPage(Number(page));
    }
  }, [page]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const items = await questionnaireService.feedbackAnswers(token, currPage);

      setPages(items.pages);
      setValues(items.feedbackValues);
      setIsLoading(false);
    };

    fetchData();
  }, [token, currPage]);

  const setPageNo = (currentPage: number) => {
    setCurrPage(currentPage);
    setSearchParams({ page: currentPage.toString() });
  };

  useEffect(() => {
    if (page) {
      setCurrPage(Number(page));
    }
  }, [page]);

  return (
    <div>
      <header className="mb-3 mt-3">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold leading-tight text-gray-900">Feedback answers</h1>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          {isLoading && <CommonAnimatedLoader />}
          {!values.length && <CommonAnimatedLoader />}
          {!!values.length && (
            <QuestionnaireFeedbackTableComponent
              values={values}
              pages={pages}
              onUpdate={setPageNo}
              currPageNo={currPage}
            />
          )}
        </div>
      </main>
    </div>
  );
}

export default FeedbackAnswersPageComponent;
