import axios from 'axios';

import { parameters } from '../../constants/parameters';
import { Order } from '../../entities/order';
import { OrderView } from '../../entities/order-view';
import { FullAnswer } from '../../entities/full-answer';
import { OrderCommentsForm } from '../../entities/form/order-comments';
import { AllergensAnswer } from '../../entities/allergens-answer';
import { Recommendation } from '../../entities/recommendation';
import OrdersListProps from '../../entities/ordersList';

export const list = async (token: string, paramString?: object): Promise<OrdersListProps> => {
  const url = `${parameters.api.baseUrl}/orders/list`;

  try {
    const res = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
      params: paramString,
    });

    return res.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.data.error) {
        throw new Error(e.response?.data.error);
      }
    }

    throw e;
  }
};

export const view = async (token: string, id: number): Promise<OrderView> => {
  const url = `${parameters.api.baseUrl}/orders/view/${id}`;

  const res = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });

  return res.data;
};

export const result = async (token: string, hash: string): Promise<FullAnswer> => {
  const url = `${parameters.api.baseUrl}/orders/result/${hash}`;

  const res = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });

  return res.data;
};

export const getAllergens = async (token: string, hash: string): Promise<AllergensAnswer> => {
  const url = `${parameters.api.baseUrl}/orders/allergens/${hash}`;

  const res = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data;
};

export const updateComment = async (
  token: string,
  id: number,
  data: OrderCommentsForm,
): Promise<{ status: string }> => {
  const url = `${parameters.api.baseUrl}/orders/update-comments`;

  const res = await axios.post(
    url,
    {
      id,
      ...data,
    },
    { headers: { Authorization: `Bearer ${token}` } },
  );

  return res.data;
};

export const updateRecommendations = async (
  token: string,
  orderId: number,
  recommendations: Recommendation[],
): Promise<{ status: string }> => {
  const url = `${parameters.api.baseUrl}/orders/update-recommendations`;

  const res = await axios.post(
    url,
    {
      orderId,
      recommendations,
    },
    { headers: { Authorization: `Bearer ${token}` } },
  );

  return res.data;
};

export const manualImport = async (token: string, id: number, remoteId: number): Promise<{ status: string }> => {
  const url = `${parameters.api.baseUrl}/orders/imunodiagnostika/manual-import`;

  const res = await axios.post(
    url,
    {
      id,
      remoteId,
    },
    { headers: { Authorization: `Bearer ${token}` } },
  );

  return res.data;
};

export const importStatus = async (token: string, id: number): Promise<Order> => {
  const url = `${parameters.api.baseUrl}/orders/imunodiagnostika/import-status/${id}`;

  const res = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });

  return res.data;
};

export const complete = async (token: string, id: number): Promise<{ status: string }> => {
  const url = `${parameters.api.baseUrl}/orders/complete`;

  const res = await axios.post(
    url,
    {
      id,
    },
    { headers: { Authorization: `Bearer ${token}` } },
  );

  return res.data;
};

export const sendAccountCreationReminder = async (token: string, orderId: number): Promise<{ status: string }> => {
  const url = `${parameters.api.baseUrl}/orders/send-reminder`;

  const res = await axios.post(
    url,
    {
      orderId,
    },
    { headers: { Authorization: `Bearer ${token}` } },
  );

  return res.data;
};

export const update = async (token: string, id: number, administratorId: number): Promise<{ status: string }> => {
  const url = `${parameters.api.baseUrl}/orders/update`;

  const res = await axios.post(
    url,
    {
      id,
      administratorId,
    },
    { headers: { Authorization: `Bearer ${token}` } },
  );

  return res.data;
};

export const markAsit = async (token: string, id: number): Promise<{ order: Order }> => {
  const url = `${parameters.api.baseUrl}/orders/mark-asit`;

  const res = await axios.post(
    url,
    {
      id,
    },
    { headers: { Authorization: `Bearer ${token}` } },
  );

  return res.data;
};

export const getPdf = async (token: string, pdfId: string): Promise<string> => {
  const url = `${parameters.api.baseUrl}/orders/download-pdf/${pdfId}`;

  const res = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data;
};
