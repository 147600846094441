import React, { useEffect, useState } from 'react';

import { useAuth } from '../../hooks/use-auth';
import * as sourcesService from '../../services/api/sources';
import CommonAnimatedLoader from '../../components/common/animated/loader';
import { SourceGroup } from '../../entities/source-group';
import SourcesGroupsTableComponent from '../../components/source-groups/table';

function SourcesGroupsListPageComponent() {
  const { token } = useAuth();
  const [items, setItems] = useState<SourceGroup[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const sourceGroups = await sourcesService.groups(token);

      setItems(sourceGroups);
    };

    fetchData();
  }, [token]);

  return (
    <div>
      <header className="mb-3 mt-3">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold leading-tight text-gray-900">Source Groups</h1>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          {!items.length && <CommonAnimatedLoader />}
          {!!items.length && <SourcesGroupsTableComponent items={items} />}
        </div>
      </main>
    </div>
  );
}

export default SourcesGroupsListPageComponent;
