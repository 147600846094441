import React from 'react';
import { Link } from 'react-router-dom';

import { Coupon } from '../../entities/coupon';
import { fromTimestamp } from '../../services/formatter/date';

interface ComponentProps {
  items: Coupon[];
}

export default function CouponsTableComponent({ items }: ComponentProps) {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Code
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Discount
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Times used / all
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Expiration date
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    &nbsp;
                  </th>
                </tr>
              </thead>
              <tbody>
                {items.map((coupon, index) => (
                  <tr key={coupon.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="px-6 py-1 whitespace-nowrap text-sm">{coupon.code}</td>
                    <td className="px-6 py-1 whitespace-nowrap text-sm">
                      {coupon.type === 'exact' ? `${coupon.amountExact}€` : `${coupon.amountPercentage}%`}
                    </td>
                    <td className="px-6 py-1 whitespace-nowrap text-sm">
                      {coupon.timesUsed} / {coupon.timesTotal > 0 ? coupon.timesTotal : 'Unlimited'}
                    </td>
                    <td className="px-6 py-1 whitespace-nowrap text-sm">
                      {coupon.expiredAt ? fromTimestamp(coupon.expiredAt) : 'Never'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <Link to={`/coupons/edit/${coupon.id}`} className="text-indigo-600 hover:text-indigo-900">
                        Edit
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
