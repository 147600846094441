import axios from 'axios';

import { parameters } from '../../constants/parameters';
import { Recommendation } from '../../entities/recommendation';

export const create = async (token: string, data: Partial<Recommendation>): Promise<Recommendation> => {
  const url = `${parameters.api.baseUrl}/recommendations`;

  const res = await axios.put(url, data, { headers: { Authorization: `Bearer ${token}` } });

  return res.data;
};

export const update = async (token: string, id: number, data: Partial<Recommendation>): Promise<Recommendation> => {
  const url = `${parameters.api.baseUrl}/recommendations/${id}`;

  const res = await axios.post(url, data, { headers: { Authorization: `Bearer ${token}` } });

  return res.data;
};

export const list = async (token: string): Promise<Recommendation[]> => {
  const url = `${parameters.api.baseUrl}/recommendations/list`;

  const res = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });

  return res.data;
};

export const one = async (token: string, id: number): Promise<Recommendation> => {
  const url = `${parameters.api.baseUrl}/recommendations/${id}`;

  const res = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });

  return res.data;
};
