export const OrderStatus = {
  New: 'new',
  Paid: 'paid',
  BloodPending: 'blood-pending',
  BloodReceived: 'blood-received',
  Completed: 'completed',
  NotCompleted: 'not-completed',
};

export const OrderImportStatus = {
  Initiated: 'initiated',
  Started: 'started',
  Completed: 'completed',
  NoImport: 'undefined',
};

export const OrderReview = {
  auto: 'auto',
  written: 'written',
  consultation: 'consultation',
};

export interface Order {
  id: number;
  sessionId?: number;
  customerId?: number;
  patientId?: number;
  remoteId?: number;
  email: string;
  firstName: string;
  lastName: string;
  hash: string;
  phone: string;
  isBloodRegistered: boolean;
  status: string;
  importStatus: keyof typeof OrderImportStatus;
  createdAt: string;
  amount: number;
  researchMemberId: string;
  paymentMethod?: string;
  paidAt?: string;
  resultsUrl: string;
  commentResult: string;
  commentUnidentified: string;
  subtotal: number;
  discount: number;
  couponCode: string;
  productKey: string;
  productId: number;
  personCode: string;
  childCode: string;
  patientIsChild: boolean;
  patientName: string;
  patientLastName: string;
  patientAge: string;
  isConsultation: boolean;
  administratorId: number;
  review: keyof typeof OrderReview;
  isAsit: boolean;
  daysToDeadline: number;
  currencyCode: string;
  siteId: number;
  siteTitle: string;
  pdfId?: string;
  isGift?: boolean;
}
