import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useAuth } from '../../hooks/use-auth';
import * as couponsService from '../../services/api/coupons';
import CommonAnimatedLoader from '../../components/common/animated/loader';
import { Coupon } from '../../entities/coupon';
import CouponsTableComponent from '../../components/coupons/table';
import CommonButtonDefault from '../../components/common/buttons/default';

function CouponsListPageComponent() {
  const { token } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [coupons, setCoupons] = useState<Coupon[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const items = await couponsService.list(token);

      setCoupons(items);
      setIsLoading(false);
    };

    fetchData();
  }, [token]);

  return (
    <div>
      <header className="mb-3 mt-3">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex">
            <h1 className="text-3xl font-bold leading-tight text-gray-900">Coupons</h1>
            <Link className="ml-auto" to="/coupons/create">
              <CommonButtonDefault primary>Create new</CommonButtonDefault>
            </Link>
          </div>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          {isLoading && <CommonAnimatedLoader />}
          {!isLoading && <CouponsTableComponent items={coupons} />}
        </div>
      </main>
    </div>
  );
}

export default CouponsListPageComponent;
