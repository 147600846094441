import axios from 'axios';

import { parameters } from '../../constants/parameters';
import { OrderView } from '../../entities/order-view';

export const viewAnswer = async (token: string, hash: string): Promise<OrderView> => {
  const url = `${parameters.api.baseUrl}/answers/view/${hash}`;

  const res = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });

  return res.data;
};
