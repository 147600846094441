import axios, { AxiosError } from 'axios';

import { parameters } from '../../constants/parameters';
import PatientsListProps from '../../entities/patientsList';
import PatientPreviewProps from '../../entities/patient-preview';
import PatientsListClinicProps from '../../entities/patientsListClinic';
import PatientFile from '../../entities/patientFile';

export const getPatients = async (token?: string, paramString?: object): Promise<PatientsListProps> => {
  const url = `${parameters.api.baseUrl}/patients/list`;

  const result = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: paramString,
  });

  return result.data;
};

export const getPatient = async (token: string, hash: string): Promise<PatientPreviewProps> => {
  const url = `${parameters.api.baseUrl}/patients/view/${hash}`;

  try {
    const result = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return result.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.data.error) {
        throw new Error(e.response?.data.error);
      }
    }

    throw e;
  }
};

export const patientsInvite = async (token: string, phones: Array<string>): Promise<PatientsListClinicProps> => {
  const url = `${parameters.api.baseUrl}/patients/invite`;

  try {
    const result = await axios.post(
      url,
      {
        phones,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return result.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.data.error) {
        throw new Error(e.response?.data.error);
      }
    }

    throw e;
  }
};

export const getPatientFiles = async (token: string, patientHash: string): Promise<PatientFile[]> => {
  const url = `${parameters.api.baseUrl}/patients/view-files/${patientHash}`;

  try {
    const result = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return result.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.data.error) {
        throw new Error(e.response?.data.error);
      }
    }

    throw e;
  }
};
