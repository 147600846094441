import React from 'react';
import classNames from 'classnames';

export default function IsAsitStatusBadge({ status }: { status: boolean }) {
  return (
    <span
      className={classNames('inline-flex items-center px-2 py-0.5 rounded text-xs font-medium', {
        'bg-blue-100 text-blue-800 mr-3': status,
        'bg-gray-100 text-gray-800': !status,
      })}
    >
      {status ? 'Is for ASIT' : 'Not for ASIT'}
    </span>
  );
}
