import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import tw, { styled } from 'twin.macro';

import { useAuth } from '../../hooks/use-auth';
import CommonAnimatedLoader from '../../components/common/animated/loader';
import { useOrder } from '../../hooks/use-order';
import CommonButtonDefault from '../../components/common/buttons/default';
import * as ordersService from '../../services/api/orders';
import { FullAnswer, FullAnswerSourceGroup } from '../../entities/full-answer';
import { OrderCommentsForm } from '../../entities/form/order-comments';
import CKEditorInput from '../../components/common/form/ckeditor';

const ColumnHeading = styled.div`
  ${tw`w-full md:w-2/5 lg:w-1/5`}
`;

const ColumnContent = styled.div`
  ${tw`w-full md:w-3/5 lg:w-4/5`}
`;

const monthFormatter = new Intl.DateTimeFormat('lt', { month: 'long' });

function OrdersResultsCommentsPageComponent() {
  const { token } = useAuth();
  const { order, updateOrder } = useOrder();
  const [fullAnswer, setFullAnswer] = useState<FullAnswer>();

  const { register, handleSubmit, setValue } = useForm<OrderCommentsForm>();
  const onSubmit: SubmitHandler<OrderCommentsForm> = async (data) => {
    if (order) {
      updateOrder({
        ...order.order,
        resultsUrl: data.resultsUrl,
        commentUnidentified: data.unidentifiedSymptomIntro,
        commentResult: data.finalComment,
      });

      await ordersService.updateComment(token, order.order.id, data);
    }
  };

  useEffect(() => {
    const fetchData = async (hash: string) => {
      const item = await ordersService.result(token, hash);

      setFullAnswer(item);
    };

    if (order) {
      fetchData(order.order.hash);
    }
  }, [order, setFullAnswer, token]);

  useEffect(() => {
    register('finalComment', { value: order?.order.commentResult });
    register('unidentifiedSymptomIntro', { value: order?.order.commentUnidentified });
    fullAnswer?.sourceGroups.map((sourceGroup, sourceGroupIndex) => {
      register(`sourceGroups.${sourceGroupIndex}.commentMainSource`, { value: sourceGroup.commentMainSource });
      register(`sourceGroups.${sourceGroupIndex}.commentSecondarySource`, {
        value: sourceGroup.commentSecondarySource,
      });
      register(`sourceGroups.${sourceGroupIndex}.commentSymptoms`, { value: sourceGroup.commentSymptoms });
      register(`sourceGroups.${sourceGroupIndex}.commentPeriod`, { value: sourceGroup.commentPeriod });

      return sourceGroup;
    });
  }, [order, fullAnswer, register]);

  const getGroupTitle = (sourceGroup: FullAnswerSourceGroup): string => {
    const orderSourceGroup = order?.sourceGroups.find((s) => s.id === sourceGroup.id);

    if (orderSourceGroup && orderSourceGroup.customTitle) {
      return orderSourceGroup.customTitle;
    }

    return sourceGroup.sourceGroupTitle;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {!order && <CommonAnimatedLoader />}
      {order && (
        <div className="pb-10">
          <div className="fixed z-999 left-0 bottom-0 drop-shadow-xl p-2 flex content-end border-t border-gray-200 bg-white w-full">
            <CommonButtonDefault className="ml-auto" primary type="submit">
              Save
            </CommonButtonDefault>
          </div>

          {order.order.productKey !== 'CONSULTATION' && (
            <div className="md:flex md:space-x-3 mb-5 pb-5 border-b border-gray-200 full-width">
              <ColumnHeading>
                <h3 className="text-lg leading-6 mb-5 font-medium text-gray-900">Results URL</h3>
              </ColumnHeading>
              <ColumnContent>
                <div className="-my-2 sm:-mx-6 lg:-mx-8">
                  <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow border-b border-gray-200 sm:rounded-lg bg-white p-4">
                      <input
                        type="text"
                        {...register('resultsUrl')}
                        id="resultsUrl"
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        defaultValue={order.order.resultsUrl}
                      />
                    </div>
                  </div>
                </div>
              </ColumnContent>
            </div>
          )}

          <div className="md:flex md:space-x-3 mb-5 pb-5 border-b border-gray-200 full-width">
            <ColumnHeading>
              <h3 className="text-lg leading-6 mb-5 font-medium text-gray-900">Results summary</h3>
            </ColumnHeading>
            <ColumnContent>
              <div className="-my-2 sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow border-b border-gray-200 sm:rounded-lg bg-white p-4">
                    <CKEditorInput
                      value={order.order.commentResult}
                      onChange={(value) => setValue('finalComment', value)}
                    />
                  </div>
                </div>
              </div>
            </ColumnContent>
          </div>

          {!fullAnswer && <CommonAnimatedLoader />}

          {fullAnswer?.unidentifiedSymptoms && (
            <div className="md:flex md:space-x-3 mb-5 pb-5 border-b border-gray-200 full-width">
              <ColumnHeading>
                <h3 className="text-lg leading-6 mb-5 font-medium text-gray-900">Unidentified symptoms</h3>
                {fullAnswer.unidentifiedSymptoms.map((symptom, index) => (
                  <div
                    className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-300 text-gray-800"
                    key={`unidentifiedSymptoms-${symptom.id}-${index + 1}`}
                  >
                    {symptom.title}
                  </div>
                ))}
              </ColumnHeading>
              <ColumnContent>
                <div className="-my-2 sm:-mx-6 lg:-mx-8">
                  <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow border-b border-gray-200 sm:rounded-lg bg-white p-4">
                      <CKEditorInput
                        value={order.order.commentUnidentified}
                        onChange={(value) => setValue('unidentifiedSymptomIntro', value)}
                      />
                    </div>
                  </div>
                </div>
              </ColumnContent>
            </div>
          )}

          {fullAnswer?.sourceGroups.map((sourceGroup, sourceGroupIndex) => (
            <div
              className="md:flex md:space-x-3 mb-5 pb-5 border-b border-gray-200 full-width"
              key={`sg-${sourceGroup.id}`}
            >
              <ColumnHeading>
                <input type="hidden" {...register(`sourceGroups.${sourceGroupIndex}.id`)} value={sourceGroup.id} />
                <h3 className="text-lg leading-6 mb-5 font-medium text-gray-900">{getGroupTitle(sourceGroup)}</h3>
              </ColumnHeading>
              <ColumnContent>
                <div className="-my-2 sm:-mx-6 lg:-mx-8">
                  <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow border-b border-gray-200 sm:rounded-lg bg-white p-4">
                      <div className="mb-4">
                        <label htmlFor="comment" className="block text-sm font-medium text-gray-700">
                          Primary allergens comment
                        </label>
                        <p className="rounded-md bg-yellow-50 p-2 px-4 text-sm text-yellow-700">
                          Explain why these allergens are selected as primary
                        </p>
                        <div className="mt-1">
                          {sourceGroup.primarySources.map((source, index) => (
                            <div
                              className="inline-flex items-center px-2 py-0.5 mr-2 rounded text-xs font-medium bg-gray-300 text-gray-800"
                              key={`sourceId-${source.id}-${index + 1}`}
                            >
                              {source.sourceTitle}
                            </div>
                          ))}
                          <CKEditorInput
                            value={sourceGroup.commentMainSource}
                            onChange={(value) => setValue(`sourceGroups.${sourceGroupIndex}.commentMainSource`, value)}
                          />
                        </div>
                      </div>
                      <div className="mb-4">
                        <label htmlFor="comment" className="block text-sm font-medium text-gray-700">
                          Secondary allergens comment
                        </label>
                        <p className="rounded-md bg-yellow-50 p-2 px-4 text-sm text-yellow-700">
                          Explain why these allergens are selected as secondary. What does that mean to patient?
                        </p>
                        <div className="mt-1">
                          {sourceGroup.secondarySources.map((source, index) => (
                            <div
                              className="inline-flex items-center px-2 py-0.5 mr-2 rounded text-xs font-medium bg-gray-300 text-gray-800"
                              key={`secondary-source-${source.id}-${index + 1}`}
                            >
                              {source.sourceTitle}
                            </div>
                          ))}
                          <CKEditorInput
                            value={sourceGroup.commentSecondarySource}
                            onChange={(value) =>
                              setValue(`sourceGroups.${sourceGroupIndex}.commentSecondarySource`, value)
                            }
                          />
                        </div>
                      </div>
                      <div className="mb-4">
                        <label htmlFor="comment" className="block text-sm font-medium text-gray-700">
                          Symptoms comment
                        </label>
                        <p className="rounded-md bg-yellow-50 p-2 px-4 text-sm text-yellow-700">
                          If necessary explain how these allergens are related to patient&quot;s symptoms
                        </p>
                        <div className="mt-1">
                          {sourceGroup.symptoms
                            .filter((s) => s.active)
                            .map((symptom, index) => (
                              <div
                                className="inline-flex items-center px-2 py-0.5 mr-2 rounded text-xs font-medium bg-gray-300 text-gray-800"
                                key={`fullAnswer-symptom-${symptom.id}-${index + 1}`}
                              >
                                {symptom.title}
                              </div>
                            ))}
                          <CKEditorInput
                            value={sourceGroup.commentSymptoms}
                            onChange={(value) => setValue(`sourceGroups.${sourceGroupIndex}.commentSymptoms`, value)}
                          />
                        </div>
                      </div>
                      <div className="mb-4">
                        <label htmlFor="comment" className="block text-sm font-medium text-gray-700">
                          Period comment
                        </label>
                        <p className="rounded-md bg-yellow-50 p-2 px-4 text-sm text-yellow-700">
                          If necessary explain how these allergens are related to selected period
                        </p>
                        <div className="mt-1">
                          {sourceGroup.months.map((month, index) => (
                            <div
                              className="inline-flex items-center px-2 py-0.5 mr-2 rounded text-xs font-medium bg-gray-300 text-gray-800"
                              key={`${month}-${index + 1}`}
                            >
                              {monthFormatter.format(new Date(2000, month - 1, 1))}
                            </div>
                          ))}
                          <CKEditorInput
                            value={sourceGroup.commentPeriod}
                            onChange={(value) => setValue(`sourceGroups.${sourceGroupIndex}.commentPeriod`, value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ColumnContent>
            </div>
          ))}
        </div>
      )}
    </form>
  );
}

export default OrdersResultsCommentsPageComponent;
