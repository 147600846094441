import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import { useNavigate, useParams } from 'react-router-dom';
import Select, { OnChangeValue } from 'react-select';

import { useAuth } from '../../hooks/use-auth';
import CouponForm from '../../entities/form/coupon-form';
import CommonButtonDefault from '../../components/common/buttons/default';
import { fromJsDate, fromTimestamp } from '../../services/formatter/date';
import * as couponsService from '../../services/api/coupons';
import * as sitesService from '../../services/api/sites';
import * as productsService from '../../services/api/products';
import CommonAnimatedLoader from '../../components/common/animated/loader';
import { Site } from '../../entities/site';
import CommonAnimatedSpinner from '../../components/common/animated/spinner';
import { strings } from '../../localization/strings';
import { Product } from '../../entities/product';

interface ComponentProps {
  isEdit?: boolean;
}

function CouponsFormPageComponent({ isEdit }: ComponentProps) {
  const { token } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [expiredAt, setExpiredAt] = useState<Date>();
  const { register, watch, setValue, handleSubmit } = useForm<CouponForm>();
  const [sites, setSites] = useState<Site[]>([]);
  const [products, setProducts] = useState<Product[]>([]);

  const onDelete = async () => {
    if (id) {
      setIsLoading(true);
      await couponsService.remove(token, parseInt(id, 10));

      navigate('/coupons');
    }
  };

  const onSubmit = async (data: CouponForm) => {
    setIsLoading(true);

    const formattedData = {
      ...data,
      timesTotal: data.timesTotal ? parseInt(`${data.timesTotal}`, 10) : 0,
      amountExact: data.amountExact ? parseFloat(`${data.amountExact}`) : undefined,
      amountPercentage: data.amountPercentage ? parseFloat(`${data.amountPercentage}`) : undefined,
      expiredAt: data.expiredAt ? data.expiredAt : null,
    };

    if (isEdit) {
      if (id) {
        await couponsService.update(token, parseInt(id, 10), formattedData);
      }
    } else {
      await couponsService.create(token, formattedData);
    }

    navigate(`/coupons`);
  };

  useEffect(() => {
    setValue('type', 'exact');
  }, [setValue]);

  const fetchProducts = async (siteId: number) => {
    const items = await productsService.list(siteId);

    setProducts(items);
  };

  useEffect(() => {
    const fetchCoupon = async () => {
      if (id) {
        const coupon = await couponsService.one(token, parseInt(id, 10));

        setValue('code', coupon.code);
        setValue('type', coupon.type);
        setValue('amountExact', coupon.amountExact);
        setValue('amountPercentage', coupon.amountPercentage);
        setValue('timesTotal', coupon.timesTotal);
        setValue('siteId', coupon.siteId);
        setValue('productId', coupon.productId);

        if (coupon.expiredAt) {
          setExpiredAt(new Date(coupon.expiredAt));
          setValue('expiredAt', fromTimestamp(coupon.expiredAt));
        }

        if (coupon.siteId) {
          await fetchProducts(coupon.siteId);
        }
      }
    };

    fetchCoupon();
  }, [setValue, token, id]);

  useEffect(() => {
    const fetchData = async () => {
      const items = await sitesService.list(token);

      if (items) {
        setSites(items);
      }
    };

    fetchData();
  }, [token]);

  const onChangeSite = async (event: OnChangeValue<Site, false>) => {
    setValue('siteId', event ? event.id : undefined);

    if (event && event.id) {
      await fetchProducts(event.id);
    }
  };

  const onChangeProduct = async (event: OnChangeValue<Product, false>) => {
    setValue('productId', event ? event.id : undefined);
  };

  register('type');
  register('expiredAt');

  const onChangeDate = (date: Date) => {
    setExpiredAt(date);
    setValue('expiredAt', date ? fromJsDate(date) : undefined);
  };

  const couponType = watch('type');

  return (
    <div>
      {isLoading && <CommonAnimatedLoader />}
      <header className="mb-3 mt-3">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold leading-tight text-gray-900">
            {isEdit ? 'Edit coupon' : 'Create new coupon'}
          </h1>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <form onSubmit={handleSubmit(onSubmit)} className="shadow border-b border-gray-200 sm:rounded-lg bg-white">
            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 p-4">
              <div className="sm:col-span-3">
                <div className="mb-3">
                  <label htmlFor="symptoms" className="block text-sm font-medium text-gray-700">
                    Select site
                  </label>
                  <div className="mt-1">
                    {!sites && <CommonAnimatedSpinner />}
                    {sites && sites.length && (
                      <Select
                        isLoading={sites.length < 1}
                        options={sites}
                        isClearable
                        placeholder={strings.form.selectSites}
                        getOptionValue={(option: Site) => `${option.id}`}
                        getOptionLabel={(option: Site) => `${option.domain}`}
                        onChange={(event) => onChangeSite(event)}
                        value={sites.find((site) => site.id === watch('siteId'))}
                      />
                    )}
                  </div>
                </div>
              </div>
              {products && products.length > 0 && (
                <div className="sm:col-span-3">
                  <div className="mb-3">
                    <label htmlFor="symptoms" className="block text-sm font-medium text-gray-700">
                      Select product
                    </label>
                    <div className="mt-1">
                      <Select
                        isLoading={products.length < 1}
                        options={products}
                        isClearable
                        placeholder={strings.form.selectProducts}
                        getOptionValue={(option: Product) => `${option.id}`}
                        getOptionLabel={(option: Product) => `${option.title}`}
                        onChange={(event) => onChangeProduct(event)}
                        value={products.find((product) => product.id === watch('productId'))}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                  Code
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    {...register('code')}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                  Type
                </label>
                <div className="mt-1">
                  <span className="relative z-0 inline-flex shadow-sm rounded-md">
                    <button
                      type="button"
                      onClick={() => setValue('type', 'exact')}
                      className={classNames(
                        'relative inline-flex items-center px-4 py-2 rounded-l-md border text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500',
                        couponType === 'exact'
                          ? 'border-blue-800 bg-blue-800 text-white hover:bg-blue-900'
                          : 'border-gray-300 bg-white text-gray-700 hover:bg-gray-50 ',
                      )}
                    >
                      Exact discount
                    </button>
                    <button
                      type="button"
                      onClick={() => setValue('type', 'percentage')}
                      className={classNames(
                        'relative inline-flex items-center px-4 py-2 rounded-r-md border text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500',
                        couponType === 'percentage'
                          ? 'border-blue-800 bg-blue-800 text-white hover:bg-blue-900'
                          : 'border-gray-300 bg-white text-gray-700 hover:bg-gray-50 ',
                      )}
                    >
                      Percentage discount
                    </button>
                  </span>
                </div>
              </div>
              {couponType === 'percentage' && (
                <div className="sm:col-span-1">
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Amount percentage
                  </label>
                  <div className="mt-1 relative">
                    <input
                      type="text"
                      {...register('amountPercentage')}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <span className="text-gray-500 sm:text-sm" id="price-currency">
                        %
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {couponType === 'exact' && (
                <div className="sm:col-span-1">
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Amount exact
                  </label>
                  <div className="mt-1 relative">
                    <input
                      type="text"
                      {...register('amountExact')}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <span className="text-gray-500 sm:text-sm" id="price-currency">
                        €
                      </span>
                    </div>
                  </div>
                </div>
              )}
              <div className="sm:col-span-2">
                <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                  Times allowed
                </label>
                <div className="mt-1">
                  <input
                    type="number"
                    {...register('timesTotal')}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <p className="mt-1 text-sm text-gray-500" id="email-description">
                  Keep empty or 0 for unlimited
                </p>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                  Expired at
                </label>
                <div className="mt-1">
                  <DatePicker
                    selected={expiredAt}
                    dateFormat="yyyy-MM-dd"
                    isClearable
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    onChange={(date: Date) => onChangeDate(date)}
                  />
                </div>
                <p className="mt-1 text-sm text-gray-500" id="email-description">
                  Keep empty for unlimited expiration
                </p>
              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 rounded-b-lg flex">
              <CommonButtonDefault type="submit" primary>
                Save
              </CommonButtonDefault>
              <CommonButtonDefault onClick={() => onDelete()} type="button" danger className="ml-auto">
                Delete
              </CommonButtonDefault>
            </div>
          </form>
        </div>
      </main>
    </div>
  );
}

CouponsFormPageComponent.defaultProps = {
  isEdit: false,
};

export default CouponsFormPageComponent;
