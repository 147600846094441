import axios from 'axios';

import { parameters } from '../../constants/parameters';
import { OrderSourceGroupSource } from '../../entities/order-source-group-source';
import { OrderViewSource } from '../../entities/order-view';

export const update = async (
  token: string,
  id: number,
  data: Partial<OrderSourceGroupSource>,
): Promise<OrderSourceGroupSource> => {
  const url = `${parameters.api.baseUrl}/orders/source-groups/sources/update`;

  const res = await axios.post(
    url,
    {
      id,
      ...data,
    },
    { headers: { Authorization: `Bearer ${token}` } },
  );

  return res.data;
};

export const remove = async (token: string, id: number): Promise<{ status: string }> => {
  const url = `${parameters.api.baseUrl}/orders/source-groups/sources/${id}`;

  const res = await axios.delete(url, { headers: { Authorization: `Bearer ${token}` } });

  return res.data;
};

export const create = async (token: string, orderSourceGroupId: number, sourceId: number): Promise<OrderViewSource> => {
  const url = `${parameters.api.baseUrl}/orders/source-groups/sources`;

  const res = await axios.put(url, { orderSourceGroupId, sourceId }, { headers: { Authorization: `Bearer ${token}` } });

  return res.data;
};
