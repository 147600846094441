import axios from 'axios';

import { parameters } from '../../constants/parameters';

export const remove = async (token: string, id: number): Promise<{ status: string }> => {
  const url = `${parameters.api.baseUrl}/orders/remove-allergen/${id}`;

  const res = await axios.delete(url, { headers: { Authorization: `Bearer ${token}` } });

  return res.data;
};
