import React from 'react';
import Select, { OnChangeValue } from 'react-select';

import { Source } from '../../entities/source';
import { strings } from '../../localization/strings';
import { Month } from '../../entities/month';
import { createMonths, getMonthsList } from '../../services/formatter/date';
import * as sourcesService from '../../services/api/sources';
import { useAuth } from '../../hooks/use-auth';

interface ComponentProps {
  items: Source[];
}

export default function SourcesTableComponent({ items }: ComponentProps) {
  const months = getMonthsList();
  const { token } = useAuth();

  const onChangeMonths = async (source: Source, event: OnChangeValue<Month, true>) => {
    const monthIds = event.map((m) => m.id);

    await sourcesService.updatePeriod(token, source.id, monthIds);
  };

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    ID
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Title
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Period
                  </th>
                </tr>
              </thead>
              <tbody>
                {items.map((source, index) => (
                  <tr key={source.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{source.id}</td>
                    <td className="px-6 py-1 whitespace-nowrap text-sm">
                      {source.title}
                      <div className="text-gray-500">{source.scientificTitle}</div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap text-sm">
                      <Select
                        options={months}
                        isClearable
                        isMulti
                        placeholder={strings.sources.selectPeriod}
                        defaultValue={createMonths(source.months)}
                        getOptionValue={(option: Month) => `${option.id}`}
                        getOptionLabel={(option: Month) => `${option.title}`}
                        onChange={(event) => onChangeMonths(source, event)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
