import React, { ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';

import { useModal } from '../../hooks/use-modal';
import { strings } from '../../localization/strings';

interface ComponentProps {
  children: ReactNode;
  title?: string;
  type?: string;
  visible?: boolean;
  onCloseClick?: () => void;
  fullHeight?: boolean;
}

function ModalContent({ type, title, children, visible, onCloseClick, fullHeight }: ComponentProps) {
  const { isVisible, setVisible } = useModal();

  const isModalVisible = (type && isVisible(type)) || visible;

  const onClose = () => {
    if (onCloseClick) {
      onCloseClick();
    } else if (type) {
      setVisible(type, false);
    }
  };

  return (
    <Transition.Root show={isModalVisible}>
      <Dialog
        as="div"
        className="text-dialog fixed z-10 inset-0 py-4 overflow-y-auto !max-h-full z-[9999]"
        onClose={() => onClose()}
      >
        <div
          className={`flex items-center content-center justify-center min-h-full px-4 text-center sm:p-0 !max-h-full ${
            fullHeight && 'h-full items-stretch'
          }`}
        >
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div
            className={`flex align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle md:w-10/12 2xl:max-w-6xl mx-5 md:mx-auto sm:p-6 !max-h-full ${
              fullHeight && '!h-full items-stretch'
            }`}
          >
            <div className="block absolute top-0 right-0 pt-4 pr-4">
              <button
                type="button"
                className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => onClose()}
              >
                <span className="sr-only">{strings.button.close}</span>
                <XIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="w-full">
              {title && (
                <Dialog.Title as="h1" className="text-xl leading-6 font-medium text-gray-900">
                  {title}
                </Dialog.Title>
              )}
              <div className="mt-2 h-full">{children}</div>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

ModalContent.defaultProps = {
  title: '',
  visible: false,
  type: '',
  onCloseClick: () => null,
  fullHeight: false,
};

export default ModalContent;
