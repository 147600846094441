import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { useAuth } from '../../hooks/use-auth';
import * as ordersService from '../../services/api/orders';
import { Order, OrderImportStatus } from '../../entities/order';
import OrdersTableComponent from '../../components/orders/table';
import CommonAnimatedLoader from '../../components/common/animated/loader';
import OrdersFilter from '../../components/orders/filter';
import OrdersFilterProps from '../../entities/ordersFilter';
import AlertPrimary from '../../components/common/alerts/alert-primary';
import { strings } from '../../localization/strings';

function OrdersListPageComponent() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = query.get('page');

  const initialSate: any = {};
  const { token } = useAuth();
  const [params, setParams] = useState<OrdersFilterProps>(initialSate);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pages, setPages] = useState<number>(1);
  const [currPage, setCurrPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();

  searchParams.forEach((value, key) => {
    initialSate[key] = value;
    if (key === 'page') {
      initialSate[key] = parseInt(value, 10);
    }
    if (key === 'startDate' || key === 'endDate') {
      initialSate[key] = new Date(value);
    }
  });

  const [orders, setOrders] = useState<Order[]>([]);

  useEffect(() => {
    try {
      const fetchData = async () => {
        const items = await ordersService.list(token, params);

        setOrders(items.orders);
        setPages(items.pages);
      };

      fetchData();
      setIsLoading(false);
    } catch (e) {
      // handle your error
      setIsLoading(false);
      setError((e as Error).message);
    }
  }, [token, params, currPage]);

  const setFilterData = (filterData: any) => {
    setParams({ ...filterData, page: currPage });
    // cleanQueryString(filterData);
    setSearchParams({ ...filterData });
  };

  const setPageNo = (currentPage: number) => {
    setCurrPage(currentPage);
    setParams({ ...params, page: currentPage });
    setSearchParams({ ...params, page: currentPage.toString() });
  };

  useEffect(() => {
    if (page) {
      setCurrPage(Number(page));
    }
  }, [page]);

  return (
    <div>
      <header className="mb-3 mt-3">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold leading-tight text-gray-900">Orders</h1>
        </div>
      </header>
      <main>
        <div className="w-full px-3">
          {error && (
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 my-5">
              <AlertPrimary text={error} />
            </div>
          )}
          {isLoading ? (
            <CommonAnimatedLoader />
          ) : (
            <>
              <OrdersFilter onUpdate={setFilterData} filterState={params} />
              {orders.length > 0 && (
                <OrdersTableComponent orders={orders} pages={pages} onUpdate={setPageNo} currPageNo={currPage} />
              )}
              {orders.length === 0 && (
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">{strings.search.noResults}</div>
              )}
            </>
          )}
        </div>
      </main>
    </div>
  );
}

export default OrdersListPageComponent;
