import axios from 'axios';

import { parameters } from '../../constants/parameters';
import { Customer } from '../../entities/customer';
import { Order } from '../../entities/order';
import OrdersListProps from '../../entities/ordersList';

export const list = async (token: string): Promise<Customer[]> => {
  const url = `${parameters.api.baseUrl}/customers/list`;

  const res = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });

  return res.data;
};

export const orders = async (token: string, customerId: number): Promise<OrdersListProps> => {
  const url = `${parameters.api.baseUrl}/orders/list?customerId=${customerId}`;

  const res = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });

  return res.data;
};

export const resolveMessage = async (token: string, customerId: number, status: number): Promise<Customer[]> => {
  const url = `${parameters.api.baseUrl}/customers/resolve/${customerId}/${status}`;

  const res = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });

  return res.data;
};
