import React, { ChangeEvent, useEffect, useState } from 'react';
import Select, { OnChangeValue } from 'react-select';
import { useForm } from 'react-hook-form';
import { TrashIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { useAuth } from '../../hooks/use-auth';
import CommonAnimatedLoader from '../../components/common/animated/loader';
import { useOrder } from '../../hooks/use-order';
import * as ordersService from '../../services/api/orders';
import * as recommendationsService from '../../services/api/recommendations';
import { FullAnswer } from '../../entities/full-answer';
import { Recommendation } from '../../entities/recommendation';
import { strings } from '../../localization/strings';
import RecommendationsCreateModal from '../../components/recommendations/create-modal';
import CommonButtonDefault from '../../components/common/buttons/default';
import { OrderRecommendationsForm } from '../../entities/form/order-recommendations';
import { SortableItem } from '../../components/common/utilities/sortable-item';
import { OrderViewSourceGroup } from '../../entities/order-view';
import CommonAnimatedSpinner from '../../components/common/animated/spinner';
import CKEditorInput from '../../components/common/form/ckeditor';

function OrdersResultsRecommendationsPageComponent() {
  const { token } = useAuth();
  const { order } = useOrder();
  const [selectedRecommendations, setSelectedRecommendations] = useState<Recommendation[]>([]);
  const [recommendations, setRecommendations] = useState<Recommendation[]>([]);
  const [fullAnswer, setFullAnswer] = useState<FullAnswer>();
  const { register, handleSubmit, setValue } = useForm<OrderRecommendationsForm>();

  useEffect(() => {
    const fetchData = async (hash: string) => {
      const item = await ordersService.result(token, hash);

      setFullAnswer(item);
    };

    if (order) {
      fetchData(order.order.hash);
    }
  }, [order, setFullAnswer, token]);

  useEffect(() => {
    const fetchData = async () => {
      const items = await recommendationsService.list(token);

      setRecommendations(items);
    };

    fetchData();
  }, [token, setRecommendations]);

  useEffect(() => {
    fullAnswer?.recommendations.map((rec) => {
      register(`recommendations.${rec.id}.comment`);

      return rec;
    });
  }, [register, fullAnswer]);

  const updateStoredRecommendations = async (activeRecommendations: Recommendation[]) => {
    if (fullAnswer) {
      setFullAnswer({
        ...fullAnswer,
        recommendations: activeRecommendations,
      });
    }

    if (order) {
      await ordersService.updateRecommendations(token, order.order.id, activeRecommendations);
    }
  };

  const onRemoveRecommendation = async (recommendation: Recommendation) => {
    if (fullAnswer) {
      const idx = fullAnswer?.recommendations.indexOf(recommendation);

      fullAnswer?.recommendations.splice(idx, 1);

      await updateStoredRecommendations(fullAnswer?.recommendations);
    }
  };

  const onCreateRecommendation = (recommendation: Recommendation) => {
    setRecommendations([...recommendations, recommendation]);
  };

  const onSelectRecommendation = (recs: OnChangeValue<Recommendation, true>) => {
    setSelectedRecommendations([...recs]);
  };

  const onMarkModified = (
    recommendation: Recommendation,
    state = true,
    field: 'isModified' | 'isSaving' = 'isModified',
  ) => {
    if (fullAnswer) {
      const rec = fullAnswer.recommendations.find((r) => r.id === recommendation.id);

      if (rec) {
        rec[field] = state;

        setFullAnswer({
          ...fullAnswer,
          recommendations: fullAnswer.recommendations,
        });
      }
    }
  };

  const onAssignRecommendation = async () => {
    if (fullAnswer && selectedRecommendations) {
      const newRecommendations = [...selectedRecommendations, ...fullAnswer.recommendations];

      setSelectedRecommendations([]);
      await updateStoredRecommendations(newRecommendations);
    }
  };

  const onChangeIsAction = async (recommendation: Recommendation, event: ChangeEvent<HTMLInputElement>) => {
    if (fullAnswer) {
      const rec = fullAnswer.recommendations.find((r) => r.id === recommendation.id);

      if (rec) {
        rec.isAction = event.target.checked;
        await updateStoredRecommendations(fullAnswer.recommendations);
      }
    }
  };

  const onSubmit = async (data: OrderRecommendationsForm, recommendation: Recommendation) => {
    if (fullAnswer) {
      const rec = fullAnswer.recommendations.find((r) => r.id === recommendation.id);

      if (rec) {
        onMarkModified(recommendation, true, 'isSaving');
        rec.title = data.recommendations[recommendation.id].title;
        rec.comment = data.recommendations[recommendation.id].comment;
        await updateStoredRecommendations(fullAnswer.recommendations);
        onMarkModified(recommendation, false, 'isSaving');
        onMarkModified(recommendation, false, 'isModified');
      }
    }
  };

  const onSelectSourceGroup = async (
    recommendation: Recommendation,
    sourceGroup: OnChangeValue<OrderViewSourceGroup, false>,
  ) => {
    if (fullAnswer) {
      const rec = fullAnswer.recommendations.find((r) => r.id === recommendation.id);

      if (rec) {
        rec.orderSourceGroupId = sourceGroup ? sourceGroup.id : null;
        await updateStoredRecommendations(fullAnswer.recommendations);
      }
    }
  };

  const getRecommendationStatus = (status: string): string => {
    switch (status) {
      case 'good':
        return strings.recommendations.status.good;
      case 'bad':
        return strings.recommendations.status.bad;
      default:
        return strings.recommendations.status.new;
    }
  };

  const findRecommendationByStringId = (list: Recommendation[], id: string) => {
    const item = list.find((r) => r.id === parseInt(id, 10));

    if (!item) {
      return -1;
    }

    return list.indexOf(item);
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      if (fullAnswer) {
        const oldIndex = findRecommendationByStringId(fullAnswer.recommendations, active.id);
        const newIndex = findRecommendationByStringId(fullAnswer.recommendations, over.id);

        const sortedRecommendations = arrayMove(fullAnswer.recommendations, oldIndex, newIndex);

        await updateStoredRecommendations(sortedRecommendations);
      }
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  return (
    <>
      {!order && <CommonAnimatedLoader />}
      {order && fullAnswer && (
        <>
          <label htmlFor="comment" className="block text-sm font-medium text-gray-700 mb-2">
            {strings.recommendations.assignHeading}
          </label>
          <div className="flex mb-3">
            <div className="grow mr-4">
              <Select
                options={recommendations}
                isClearable
                isMulti
                placeholder={strings.recommendations.assignSelectPlaceholder}
                value={selectedRecommendations}
                getOptionValue={(option: Recommendation) => `${option.id}`}
                getOptionLabel={(option: Recommendation) => `${option.id}: ${option.titleAdmin}`}
                onChange={(event) => onSelectRecommendation(event)}
              />
            </div>
            <div className="mr-4">
              <CommonButtonDefault onClick={() => onAssignRecommendation()}>
                {strings.recommendations.assignButton}
              </CommonButtonDefault>
            </div>
            <div>
              <RecommendationsCreateModal onCreate={(recommendation) => onCreateRecommendation(recommendation)} />
            </div>
          </div>
          <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext
              items={fullAnswer.recommendations.map((r) => {
                return {
                  ...r,
                  id: `${r.id}`,
                };
              })}
              strategy={verticalListSortingStrategy}
            >
              {fullAnswer.recommendations.map((recommendation) => (
                <SortableItem id={`${recommendation.id}`} key={`fullAnswer-${recommendation.id}`}>
                  <form onSubmit={handleSubmit((data) => onSubmit(data, recommendation))}>
                    <div className="mb-2 flex">
                      <div>
                        {recommendation.titleAdmin}
                        <span
                          className={classNames(
                            'inline-flex items-center px-2.5 py-0.5 ml-2 rounded-full text-xs font-medium',
                            {
                              'bg-gray-200 text-gray-800':
                                recommendation.status !== 'good' && recommendation.status !== 'bad',
                              'bg-red-100 text-red-800': recommendation.status === 'bad',
                              'bg-green-100 text-green-800': recommendation.status === 'good',
                            },
                          )}
                        >
                          {getRecommendationStatus(recommendation.status)}
                        </span>
                      </div>
                      <div className="shrink-0 ml-auto">
                        <CommonButtonDefault link onClick={() => onRemoveRecommendation(recommendation)}>
                          <TrashIcon className="h-5 w-5 text-gray-500 hover:text-gray-900" />
                        </CommonButtonDefault>
                      </div>
                    </div>
                    <div className="shadow border-b border-gray-200 sm:rounded-lg bg-white p-3 mb-3">
                      <div className="flex w-full">
                        <div className="w-full md:w-2/5 xl:w-1/5 md:mr-3">
                          <Select
                            options={order.sourceGroups}
                            isClearable
                            placeholder={strings.recommendations.assignSourceGroup}
                            defaultValue={order.sourceGroups.find((s) => s.id === recommendation.orderSourceGroupId)}
                            getOptionValue={(option: OrderViewSourceGroup) => `${option.id}`}
                            getOptionLabel={(option: OrderViewSourceGroup) =>
                              `${option.customTitle || option.sourceGroupTitle}`
                            }
                            onChange={(event) => onSelectSourceGroup(recommendation, event)}
                          />
                          <div className="flex items-center mt-2">
                            <input
                              id={`isAction${recommendation.id}`}
                              name="isAction"
                              type="checkbox"
                              defaultChecked={recommendation.isAction}
                              onChange={(event) => onChangeIsAction(recommendation, event)}
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                            />
                            <label htmlFor={`isImportant${recommendation.id}`} className="ml-3">
                              <span className="text-sm font-medium text-gray-900">Action</span>
                            </label>
                          </div>
                          {recommendation.isModified && (
                            <div className="mt-3">
                              <CommonButtonDefault type="submit" primary>
                                {recommendation.isSaving ? <CommonAnimatedSpinner sm light /> : 'Save'}
                              </CommonButtonDefault>
                            </div>
                          )}
                        </div>
                        <div className="w-full md:w-3/5 xl:w-4/5">
                          <input
                            type="text"
                            {...register(`recommendations.${recommendation.id}.title`)}
                            id={`recommendation_${recommendation.id}_comment`}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md mb-2"
                            defaultValue={recommendation.title}
                            onChange={() => onMarkModified(recommendation)}
                          />
                          <CKEditorInput
                            value={recommendation.comment || recommendation.longDescription}
                            onChange={(value) => {
                              setValue(`recommendations.${recommendation.id}.comment`, value);
                              onMarkModified(recommendation);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </SortableItem>
              ))}
            </SortableContext>
          </DndContext>
        </>
      )}
    </>
  );
}

export default OrdersResultsRecommendationsPageComponent;
